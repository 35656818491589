import React, { Component } from 'react';
import V_Marque_List from './vues/V_Marque_List';
import V_Marque_Add from './vues/V_Marque_Add';
import {Button, Icon} from 'antd';

class P_Marque_List extends Component {

    constructor(props) {
        super(props);
         //console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));
        this.state={
          visible:false,
          currentItem:null
        };
      }

      showDrawer = (item) => {
        this.setState({
          visible: true,
          currentItem:item
        });
      };


    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">

                     <h5 className="card-title titre_lib" ><strong>Liste des Marques</strong>
                        <Button type="primary" className="pull-right" onClick={() => this.showDrawer(null)}>
                          <Icon type="plus" /> Ajouter
                        </Button>
                      </h5>
                      
                       <div className="row">
                         <div className="col-md-12">
                           <V_Marque_List onEditing={this.showDrawer}/>
                           <V_Marque_Add visible={this.state.visible} currentItem={this.state.currentItem} />
                         </div>
                      </div>
                     </div>
                  </div>              
            </div>    
        );
    }
}

export default P_Marque_List;




