import React, { Component } from 'react';
import MainLayout from '../layout/MainLayout';
//import AntLayout from '../layout/AntLayout';
import V_Liste from './vues/V_Liste';

class PList extends Component {

    constructor(props) {
        super(props);
        //console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));

      }


    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                     <h5 className="card-title titre_lib" ><strong>Liste des clients</strong></h5>
                       <div className="row">
                         <div className="col-md-12">
                           <V_Liste />
                         </div>
                      </div>
                     </div>
                  </div>      

               { /*<div className="container-fluid fixed-top bg-dark py-3">
                  <div className="row collapse show no-gutters d-flex h-100 position-relative">
                      <div className="col-3 px-0 w-sidebar navbar-collapse collapse d-none d-md-flex">
                         
                      </div>
                      <div className="col px-3 px-md-0">
                         
                          <a data-toggle="collapse" href="#" data-target=".collapse" role="button" className="p-1">
                              <i className="fa fa-bars fa-lg"></i>
                          </a>
                      </div>
                  </div>
        </div> */ }             
            </div>    
        );
    }
}

export default PList;




