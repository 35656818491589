import React, { Component } from 'react'
import { getClient, exeRequest } from '../util/APIUtils'
import LoadingIndicator  from '../common/LoadingIndicator'
import { Button, Icon, notification } from 'antd'
import History from './vues/V_History'
import { withRouter } from 'react-router-dom'
import Map  from '../module/Map'
import MainLayout from '../layout/MainLayout';
import './PProfile.css';
import  V_Portefolio from './vues/V_Portefolio'
import V_Posm from './vues/V_Posm'
import V_Editing from './vues/V_Editing'

class PProfile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            client: null,
            isLoading: false,
            currentPosition : null,
            operationId : null,
            visibleEditing : false
        };

        this.loadClient = this.loadClient.bind(this);
        
    }
    
    handleHistorique(e){
        console.log(e.target)
    }


    

    loadClient() {

        this.setState({
            isLoading: true
            });

            exeRequest("/clients/" +  this.props.match.params.id, "GET", null, function(response){

            this.setState({
                client: response || null,
                isLoading: false
            });

            
            }, this);

        
        
    }

    componentDidMount() {
        this.loadClient();
    }

    componentDidUpdate(nextProps) {

        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                client: null,
                isLoading: false
            });   

            this.loadClient();
        }
    }


    showDrawer = () => {
        this.setState({
            visibleEditing: true,
        });
      };

  
    
    render() {

        if(this.state.isLoading || !this.state.client) {
            return <LoadingIndicator />
        }

        return (

    <div title="Liste des clients">

           <div className="card">
                <div className="card-body">
                    <h5 className="card-title titre_lib"><strong> Détails du client : {this.state.client ? this.state.client.etablissement :  ""}  </strong>  <a className={"pull-right"} type={"button"}  onClick={this.showDrawer} ><i className="fa fa-edit" ></i></a> </h5>
                    <div className="row">

                      <div className="col-md-8 p-main-content" >

                        <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Etablissement :&nbsp;</span> {this.state.client ? this.state.client.etablissement :  ""}
                            </div>

                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Interlocuteur :&nbsp;</span> {this.state.client ? this.state.client.interlocuteur : ""} 
                            </div>
                            {/*<div className="col-lg-6 col-sm-6 col-xs-12">
                              <span className='libcomd'>Route :&nbsp;</span> {this.state.client ? this.state.client.routeLib : "" }
                             </div>*/}
                            
                        </div>
                        <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Contact :&nbsp;</span> {this.state.client ? this.state.client.telephone : "" }
                            </div>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Adresse :&nbsp;</span> {this.state.client ? this.state.client.indication : ""}
                            </div>
                            
                        </div>
                            <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Sous-quartier :&nbsp;</span> {this.state.client ? this.state.client.segmentLib : ""} 
                            </div>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Commercial :&nbsp;</span> {this.state.client ? this.state.client.createdByName : "" }
                            </div>
                            
                        </div>
                        
                        <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Catégorie :&nbsp;</span> {this.state.client ? this.state.client.categorieLib : "" }
                            </div>
                            
                             <div className="col-lg-6 col-sm-6 col-xs-12">
                            <span className='libcomd'>Dernière operation :&nbsp;</span><span className='badge badge-warning' style={{fontWeight: "bolder", color: "white"}} > {this.state.client.lastOperation ? new Date(this.state.client.lastOperation).toLocaleString("fr-FR")  : "Jamais" } </span> 
                            </div>
                        </div>

                        <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-6 col-sm-6 col-xs-12">
                            <input type="checkbox"  disabled="disabled" checked={this.state.client.autourEcole ? "checked" : "" } />&nbsp;&nbsp;<span className='libcomd'>Est Autour d'une école</span> 
                            </div>
                            
                        </div>
             
                        <div className="row" style={{marginBottom:25}}>

                        <div className="col-lg-12 col-sm-12 col-xs-12">
                               <V_Portefolio clientId={this.props.match.params.id ? this.props.match.params.id :  null} />
                        </div>
                        
                        </div>

                        <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-12 col-sm-12 col-xs-12">
                               <V_Posm clientId={this.props.match.params.id ? this.props.match.params.id :  null} />
                            </div>
                        </div>

                        </div>  

                        <div className="col-md-4">
                            <Map hideLegende={true} hideInfoWindow={true} mapHeight={300} data={this.state.client ? [this.state.client] : []}  position={this.state.client ? {lat : this.state.client.coord_lat_reel, lng: this.state.client.coord_lng_reel} : null} onError={() => notification.error({
                                message: 'GeoCRM App',
                                description:'Error ! Impossible de charger la carte google map ! Verifiez votre connexion internet.'
                            })} />
                        </div>
                    </div>
                </div>
            </div>

            <V_Editing currentItem={this.state.client}  visible={this.state.visibleEditing}  />

            <History clientId={this.props.match.params.id ? this.props.match.params.id :  null}  ></History>

        </div>   
         
        );
    }
}

export default withRouter(PProfile);
