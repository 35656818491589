import React, { Component } from 'react';
import { exeRequest } from '../util/APIUtils';
import { Pagination, Table, Divider, Input, Switch } from 'antd';
import $ from "jquery";
//$.DataTable = require("datatables.net");

class DDataTable extends Component {

  constructor(props) {
        super(props);
        this.state = {
          lists: [],
          isLoading: false,
          page: this.props.defaultPage,
          pageSize: this.props.pageSize,
          searchWord : this.props.searchWord,
          totalElements: 0,
          totalPages: 0,
          last: true,
          iSearching : this.props.iSearching,
      };       
    }

  componentDidMount() {
      this.loadClientList();
  }

  loadClientList = () =>{

        this.setState({
          isLoading: true
      });

      exeRequest(this.props.url + "?word=" + (this.state.iSearching && this.state.searchWord ? this.state.searchWord  : null) + "&page=" + (this.state.page - 1) + "&size=" + this.state.pageSize, "GET", null, function(response){
          //const lists = this.state.lists.slice();  
          
          if(this.props.getData){  
             this.props.getData(response.content ? (response.content[0] ?  response.content : []) : []);
          }

          this.setState({
              lists: response.content ? (response.content[0] ?  response.content : []) : [],
              totalElements: response.totalElements,
              totalPages: response.totalPages,
              last: response.last,
              isLoading: false
          });
        
        }, this);

  }

  onPageChange = page =>{
      console.log("Page changed ", page);
      this.setState({
        page : page
      });
  }

  onShowSizeChange = (current, pageSize) => {

    console.log("Page size ", pageSize)

      this.setState({
        page: current,
        pageSize: pageSize 
    });

  }
  
  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page || 
      prevState.pageSize !== this.state.pageSize || 
      prevState.iSearching !== this.state.iSearching || 
      prevState.searchWord !== this.state.searchWord) {
        this.loadClientList();
    }
}
  
  componentWillUnmount() {
  
  }
  

  handleSearch = (value) => {
    if(value){
      //const obgRequest = Object.assign({}, values);
      this.setState({searchWord : value });
    }
  }

  onIseachChange = (checked)  => {
    this.setState({
      iSearching : checked,
      page : this.props.defaultPage 
    });
    //console.log(`switch to ${checked}`);
  }

  
  render() {
   
    return (
      <div>
        {(<center><Switch size={"small"} defaultChecked={this.state.iSearching} onChange={this.onIseachChange} /><Input.Search style={{width : "80%", marginLeft : "7px"}} disabled={!this.state.iSearching} defaultValue={this.state.searchWord}  placeholder="Tapez : Etablissement, Interlocuteur, Téléphone, Indication ..." onSearch={value => this.handleSearch(value)} enterButton /> <Divider style={{marginBottom:10}} /></center>)}  
        <div className="table-responsive">
          <Table size={this.props.size} rowKey={record => record.id} pagination={this.props.pagination} columns={this.props.columns} dataSource={this.state.lists}  loading={this.state.isLoading}  />
        </div>
        <center style={{marginTop:30}} ><Pagination  pageSize={this.state.pageSize}  pageSizeOptions={this.props.pageSizeOptions}  showSizeChanger  onShowSizeChange={this.onShowSizeChange} onChange={this.onPageChange} defaultCurrent={this.state.page} total={this.state.totalElements} /></center> 
      </div>
    );
    
  }

}


export default DDataTable;


DDataTable.defaultProps = {
  pageSizeOptions: ['10', '20', '30', '40', '50', '100'],
  pageSize : 10,
  defaultPage : 1,
  iSearching : false,
  searchWord : "",
  pagination : {
    pageSize : 10,
    position : "top"
  }
}

 