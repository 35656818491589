//export const API_BASE_URL = 'http://192.168.43.116:8080/v1';
//export const API_BASE_URL = 'http://localhost:8080/v1';
export const API_BASE_URL = 'https://galileo-cashport.ci/api/v1';

export const ACCESS_TOKEN = 'accessToken';

export const CURRENT_USER = 'currentUser';

export const GET_CLIENT_FILES_CTR = API_BASE_URL + "/files/get/clients/";

export const GET_PRODUCTS_FILES_CTR = API_BASE_URL + "/files/get/products/";

export const GET_USERS_FILES_CTR = API_BASE_URL + "/files/get/users/";

export const GET_REPORTS_FILES_CTR = API_BASE_URL + "/files/get/reports/";

export const GET_PDF_VIEWER = API_BASE_URL.replace("v1", "") + "viewer/pdf/viewer.html";

export const CLIENT_LIST_SIZE = 100;
export const POLL_LIST_SIZE = 200;
export const MAX_CHOICES = 6;
export const POLL_QUESTION_MAX_LENGTH = 140;
export const POLL_CHOICE_MAX_LENGTH = 40;

export const NAME_MIN_LENGTH = 4;

export const NAME_MAX_LENGTH = 40;

export const USERNAME_MIN_LENGTH = 3;

export const USERNAME_MAX_LENGTH = 15;

export const EMAIL_MAX_LENGTH = 40;

export const PASSWORD_MIN_LENGTH = 6;

export const PASSWORD_MAX_LENGTH = 20;

export const COMM_ROLE = "ROLE_COMM";

//export const GOOGLE_API_KEY = "AIzaSyCUqdsx889D2SIcf_9RN5yNLNicDFY2p34"; AIzaSyAMoSym9gE67_JrZNOuxZMtamtKb00VJhM

export const GOOGLE_API_KEY = "AIzaSyAMoSym9gE67_JrZNOuxZMtamtKb00VJhM";
//"AIzaSyAFYHmNIwY1kAwnOiUvCnYQUYzLfLtVga0";

export const TYPE_OPERATION = {
    "1": { Lib: "Visite", Id: 1 },
    "2": { Lib: "Vente", Id: 2 }
}

export const STATUS_CODE_REST_API = {
    1: { code: 10, message: "" },
    2: { code: 20, message: "" },
    3: { code: 22, message: "" }
}

export const STATUS = {
    0: { value: 0, message: "" },
    1: { value: 1, message: "" }
}
