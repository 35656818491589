import React, { Component } from 'react';
import V_Zone_List from './vues/V_Zone_List';
import V_Zone_Add from './vues/V_Zone_Add';
import {Button, Icon} from 'antd';

class P_Zone_List extends Component {
    constructor(props) {
        super(props);
         //console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));
      this.state={
        visible:false,
        currentItem : null 
      };
      }
      showDrawer = (item) => {
        this.setState({
          visible: true,
          currentItem : item
        });
      };


    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                     <h5 className="card-title titre_lib" >
                              <strong>Liste des zones</strong>
                              <Button type="primary" className="pull-right" onClick={() => this.showDrawer(null)}>
                                    <Icon type="plus" /> Ajouter
                             </Button>
                    </h5>
                       <div className="row">
                         <div className="col-md-12">
                           <V_Zone_List onEditing={this.showDrawer}/>
                           <V_Zone_Add visible={this.state.visible} currentItem={this.state.currentItem} />
                         </div>
                      </div>
                     </div>
                  </div>              
            </div>    
        );
    }
}

export default P_Zone_List;




