import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Popconfirm, Select, DatePicker, Icon, InputNumber } from 'antd';
import LoadingIndicator  from '../common/LoadingIndicator';
import { exeRequest } from '../util/APIUtils';
import {GET_REPORTS_FILES_CTR, GET_PDF_VIEWER, STATUS_CODE_REST_API} from '../constants'
import {jours} from '../util/Colors';
import {sMillier} from '../util/Helpers'
import locale from 'antd/es/date-picker/locale/fr_BE';
import DataTable from '../module/DataTable';
import moment from 'moment';

const { MonthPicker, WeekPicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

class PObjectifs extends Component {

    constructor(props) {
        super(props);
        this.state = {
           segments : [],
           produits: [],
           zones:[],
           response : [],
           isLoading : false,
           btnLoading : false,
           objSaver : {}
        }
      }


    componentDidMount(){
      this.loadProduits();
      this.loadZones();
    }

      
    loadProduits = () => {

        exeRequest("/produits/enable", "GET", null, function(response){
            this.setState({
                produits: response ? response : []
            })

          }, this)
    }

   loadZones = () => {

        exeRequest("/zones", "GET", null, function(response){
            this.setState({
                zones: response ? response : []
            });

          }, this)
    }

    loadSegments = (zoneId) => {

      if(zoneId){

        exeRequest("/zones/" + zoneId + "/segments", "GET", null, function(response){
            this.setState({
                segments: response ? response : []
            })
          }, this)

      }else{
          this.setState({
            segments: []
        })
    }

  }

  getEnDate = (date) =>{
      return new Date(date).toISOString().split("T")[0];
    }

 onConfirm=(index, userId, objectif)=>{

      console.log("index ====>> ", index);
      console.log("userId ====>>", userId); 
      
      let {objSaver, response} = this.state;

      console.log("objSaver ====>>", objSaver);

      objSaver.id = objectif != null ? objectif.id : null;
      objSaver.userId = userId;
      objSaver.value = parseInt(document.getElementById("input_number_" + index).value);
      objSaver.startDate = new Date().toISOString();
      objSaver.endDate = new Date().toISOString();

      let produit = this.state.produits.filter(x => x.id == objSaver.produitId)[0];
      objSaver.unitPrice = (produit.prixQuantite / produit.cartonQuantite);
     
      exeRequest("/objectif/?date=" + objSaver.date, "POST", JSON.stringify(objSaver), function(res){

        if(res.statusCode == STATUS_CODE_REST_API[1].code) {

         if(!response[index].objectif)  response[index].objectif = {};

          response[index].objectif.value = parseInt(document.getElementById("input_number_" + index).value);
          response[index].objectif.id = parseInt(res.message);

            this.setState({
              response : response
            });

         }

      }, this);

    }

  loadObjectifs = (event) =>{
  
      event.preventDefault();
  
      this.props.form.validateFields((err, values) => {

        if (!err) { 
         // this.setState({ btnLoading: true });
  
          let objRequest = Object.assign({}, values);
  
          console.log(objRequest);

          let {objSaver} = this.state;
          objSaver.produitId = objRequest.produitId;
          objSaver.date = this.getEnDate(objRequest.month._d);
  
          this.setState({
            btnLoading: true
          });

        //return false;
        exeRequest("/objectif/byproduit/" + objSaver.produitId + "/?date=" + objSaver.date, "GET", null, function(response){
            
            this.setState({
                response: response ? response : [],
                btnLoading: false,
                objSaver : objSaver
            })
  
          }, this);

        }

      })

    }

    
    render() {
      const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="card">
                <div className="card-body">
                    <h5 className="card-title titre_lib" ><strong>Objectifs</strong></h5><br />
                       <div className="row">
                         <div className="col-md-12">
                          
                         <Form layout="vertical" hideRequiredMark onSubmit={this.loadObjectifs}>

                             <center className="row">

                                <div className="col-lg-4 col-sm-6 col-xs-12" >
                                  <Form.Item label="Le produit : ">
                                  {getFieldDecorator('produitId', {
                                      rules: [{ required: true, message: 'Veuillez Choisir le produit' }],
                                      initialValue : "", 
                                    })(<Select placeholder="Veuillez choisir le produit" >
                                    <Option  key={"produit_" + 0} value={""}>{"Selectionnez un produit"}</Option>
                                    {this.state.produits.map(( m, index) => (
                                        <Option key={"produit_" + m.id} value={m.id}>{m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                    </Form.Item>
                                </div>

                                <div className="col-lg-3 col-sm-6 col-xs-12">
                                  <Form.Item label="Choisir le mois : ">
                                    {getFieldDecorator('month', {
                                      rules: [{ required: true, message: 'Veuillez choisir un mois' }],
                                      initialValue : moment(new Date(), dateFormat), 
                                    })(<MonthPicker style={{width:"100%"}}  placeholder="Choisir un mois"  />)}
                                  </Form.Item>
                                </div>  

                                 <div className="col-lg-2 col-sm-12 col-xs-12" >
                                    <Button loading={this.state.btnLoading} className="mt-4 pull-left" htmlType="submit" size="large" type="primary" > Valider </Button>
                                 </div> 
                                   
                             </center>   
                           
                             <hr></hr>
                             <br/>
                             {this.state.btnLoading ? <LoadingIndicator /> :  (<Row>
                                 <Col span={24}> 
                                   <DataTable>
                                        <thead>
                                            <tr>
                                                <th style={{width:30}} >#</th>
                                                <th>{"Commerciaux"}</th>
                                                <th style={{width:200, textAlign : "center"}} >Vendu / Objectif (carton)</th>
                                                <th style={{width:200, textAlign : "center"}} >Objectif (%)</th>
                                                <th style={{width:200, textAlign : "center"}} >CA</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                        {
                                        this.state.response.map((item,i)=>
                                          <tr key={item.id} >

                                            <th scope="row" style={{width:30}}> {i + 1} </th>
                                            <td style={{fontSize: 18, verticalAlign : "middle"}} ><b >{item.user && item.user.name}</b></td>
                                            <td style={{width:200, textAlign : "center", verticalAlign : "middle"}}>
                                            {item.count ? sMillier(item.count / item.produit.cartonQuantite) : "0" }<br/><br/>
                                              / 
                                            <Popconfirm
                                              icon={null}
                                              onConfirm={()=> this.onConfirm(i, item.user.id, item.objectif)}
                                              title={(<InputNumber id={"input_number_" + i} defaultValue={item.objectif ? item.objectif.value : 0}  step={1}  style={{width: "100%"}} min={1}  />)}
                                              okText="OUI"
                                              cancelText="NON" >
                                               <div className="circle font-weight-bold" style={{cursor:"pointer"}} >                           
                                                <a href="#">{item.objectif ? sMillier(item.objectif.value) : "Non defini"}</a>
                                               </div>
                                             </Popconfirm>
                                             </td>
                                             <td style={{width:200, textAlign : "center", verticalAlign : "middle"}} >{item.objectif ? ((item.count / (item.produit.cartonQuantite * item.objectif.value)) * 100).toFixed(2) : 0} %</td>
                                            <td style={{width:200, textAlign : "center", verticalAlign : "middle"}}>{item.ca ? sMillier(item.ca) : "0" }</td>
                                            
                                          </tr>
                                        )
                                      }

                                    </tbody>
                                  </DataTable>
                                </Col>

                             </Row>)}

                           </Form>
                          </div>
                      </div>
                     </div>
                  </div>               
            </div>                  
        );
    }

}

const APObjectifs = Form.create()(PObjectifs);

export default APObjectifs;




