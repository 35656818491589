import React, { Component } from 'react';
import { exeRequest } from '../../../util/APIUtils';
import LoadingIndicator  from '../../../common/LoadingIndicator';
/*import { Button, Icon, notification } from 'antd';
import { POLL_LIST_SIZE } from '../../../constants';
import {cusStatus} from '../../util/Colors';*/
import { withRouter } from 'react-router-dom';
import DataTable from '../../../module/DataTable';


class V_Cat_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            currentClient: null,
            isLoading: false
        };
       
        this.loadMentions = this.loadMentions.bind(this);
    }


    loadMentions() {

        this.setState({
            isLoading: true
          });

          exeRequest("/vmentions", "GET", null, function(response){
            this.setState({
                lists: (response ? response : []),
                isLoading: false
            })


          }, this)
        
    }
   
   
    componentDidMount() {
        this.loadMentions();
        //loadCategories();
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                lists: [],
                currentClient : null,
                isLoading: false
            });    
            //this.loadCategories();
        }
    }
    
    render() {
        const Views = [];
        this.state.lists.forEach((item, index) => {
              

                Views.push(

                <tr key={item.id} >
                    <th scope="row" style={{width:10}}>{index + 1}</th>
                    {/*<td style={{width:100}} > {item.code ? item.code : "" } </td>*/}
                    <td> <strong> {item.libelle ? item.libelle : "" } </strong></td>
                    <td className="actions" style={{width:30}}>
                    <a href={"#"} style={{cursor: "pointer"}} onClick={()=> this.props.onEditing(item)}  ><i className="fa far fa-edit fa-lg"  style={{color:"black"}} ></i></a>
                        {/*<a href={`${process.env.PUBLIC_URL}/parametrage/cat/${item.id}`} style={{cursor: "pointer"}}  ><i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i></a>*/}
                    </td>
               </tr>

           )     
              
        });

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }

       return (

            <div>
                <DataTable>
                <thead>
                    <tr>
                        <th>#</th>
                        {/*<th>Code</th>*/}
                        <th>Libelle</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Views}
                </tbody>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(V_Cat_List);