import React, { Component } from 'react';

import {
    Route,
    withRouter,
    Switch
  } from 'react-router-dom';

import Menu from '../common/Menu';
import Dashbord from '../common/Dashbord';
import Header from '../common/Header';
import Footer from '../common/Footer';

class MainLayout extends Component {

    constructor(props) {
        super(props);
        console.log("MainLayout",this.props.isAuthenticated);
      }


    render() {
        return (
            <div>
            <Header {...this.props} />
            <Dashbord  {...this.props} />
            <div className="menu-information">
            <div className="container-fluid">
                <div className="row">
                <div className="col-sm-3 col-lg-2 col-xs-12" id="menu_gauche" style={{marginBottom : 20}} >
                    <Menu {...this.props} />
                </div>
                <div className="col-sm-9 col-lg-10 col-xs-12" style={{minHeight: 800}}> 
                    {this.props.children}               
                </div>
              </div>
             </div>
            </div>
           <Footer {...this.props} />
        </div>
        );
    }
}

export default withRouter(MainLayout);




