import React, { Component } from 'react';
import { exeRequest, getAllTyeOperations } from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import {notification, Drawer } from 'antd';
import {TYPE_OPERATION } from '../../constants';
import {colorsActif} from '../../util/Colors';
import {sMillier} from '../../util/Helpers';
import { withRouter } from 'react-router-dom';
import DataTable from '../../module/DataTable';
import $ from "jquery";
import V_History_Details from '../../client/vues/V_History_Details';
import './V_History.css';

class V_History extends Component {

    constructor(props) {
        super(props);

        this.state = {
            history: [],
            isLoading: false,
            typeData : [],
            visible : false,
            hisId : 0
        };

        this.loadHistory = this.loadHistory.bind(this);
        //this.handleLoadMore = this.handleLoadMore.bind(this);
        this.loadTypes = this.loadTypes.bind(this);
    }


    /*switchedView(action, id){   
        this.setState({
            visible: action,
            hisId: id
          });

    }*/


    loadTypes() {

    this.setState({
    isLoading: true
    });


    exeRequest("/typeOperations", "GET", null, function(response){

        //const clients = this.state.clients.slice();  
        if(this.props.clientId) this.loadHistory();

        this.setState({
            typeData : response || [],
            isLoading: false
        })


    }, this);
         
        
    }

    showDrawer = (e) => {
        //var userId = $(e).attr("data-id");
        console.log($(e.currentTarget).attr("data-id"));
        this.setState({
          visible: true,
          hisId : $(e.currentTarget).attr("data-id")
        });

        //return false;

      };
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };




    loadHistory() {

        this.setState({
            isLoading: true
        });

        exeRequest("/clients/"+ this.props.clientId +"/operations", "GET", null, function(response){

            this.setState({
                history: response ? response : [],
                isLoading: false
            })


          }, this);

    }

    componentDidMount() {
       // this.loadTypes();
       if(this.props.clientId) this.loadHistory();
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                history: [],
                isLoading: false
            });    

            if(this.props.clientId) this.loadHistory();
        }
    }
    
    render() {
        
        if(this.state.isLoading) {
            return <LoadingIndicator />
        }



        //const Children = 
        //console.log( this.state.visible)
        //return this.state.visible ? <V_History_Details operationId={this.state.hisId} onBack={() => this.switchedView()} /> : <MainView history={this.state.history} that={this} />

        console.log("2___________  " + this.state.hisId)

        return (
            <div>
               <MainView history={this.state.history} that={this}/>
               <Drawer
               width={1600}
               placement="right"
               closable={false}
               onClose={this.onClose}
               visible={this.state.visible} >
                <V_History_Details operationId={this.state.hisId} ></V_History_Details>
              </Drawer>
            </div>
            );


      
    }
}

function MainView(props){
    const generaleViews = [];
    const visiteViews = [];
    const venteViews = [];
    const arrayViews = [];
    const history = props.history
    const that = props.that;
    console.log(history);

    history.forEach((his, clientIndex) => {

        let html = (<tr key={his.id} >
            <td> {his.ref ? his.ref : "" } </td>
            <td> <strong> {his.typeOperationId ? TYPE_OPERATION[his.typeOperationId.toString()].Lib : "" } </strong></td>
            <td> {his.createdAt ? new Date(his.createdAt).toLocaleString("fr-FR") : "" } </td>                    
            <td> {his.montant ? sMillier(his.montant) : "" } </td>
            <td> {his.createdByName ? his.createdByName : "" } </td>
            <td className="actions">
                <a  onClick={that.showDrawer}  style={{cursor: "pointer"}}  data-id={his.id} ><i className="fa fa-eye fa-lg"  style={{color:"black"}} ></i></a>
            </td>
        </tr>);

      if(TYPE_OPERATION["1"].Id == his.typeOperationId)
       visiteViews.push(html) 
      else venteViews.push(html)

        generaleViews.push(html)     
          
    });

return (         
    <div className="card" style={{marginTop: 20}}>
    <div className="card-body">
        <h5 className="card-title titre_lib"><strong>Historique : </strong></h5>
        <div className="row">
            <div className="col-md-12">
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item"><a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Générale</a></li>
        <li className="nav-item"><a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Visite</a></li>
        <li className="nav-item"><a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Vente</a></li>
    </ul>
    <div className="tab-content" id="myTabContent">
    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
            <div className="card">
                <div className="card-body">
                    <DataTable>                                           
                        <thead>
                            <tr className="titr_liste_comd">
                                <th>Ref Opérations</th>
                                <th>Description</th>
                                <th>Date opération</th>
                                <th>Montant</th>
                                <th>Commercial </th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {generaleViews}
                        </tbody>                                                            
                    </DataTable>
                </div>
               </div>
            </div>
            <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <div className="card">
                    <div className="card-body">
                        <DataTable>                                           
                            <thead>
                                <tr className="titr_liste_comd">
                                    <th>Ref Opérations</th>
                                    <th>Description</th>
                                    <th>Date opération</th>
                                    <th>Montant</th>
                                    <th>Commercial </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {visiteViews}
                            </tbody>                                                            
                        </DataTable>
                    </div>
                </div>
            </div>
            <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                <div className="card">
                    <div className="card-body">
                        <DataTable>                                           
                            <thead>
                                <tr className="titr_liste_comd">
                                    <th>Ref Opérations</th>
                                    <th>Description</th>
                                    <th>Date opération</th>
                                    <th>Montant</th>
                                    <th>Commercial </th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {venteViews}
                            </tbody>                                                            
                        </DataTable>
                    </div>
                </div>
            </div>
          </div>   
        </div>  
      </div>
     </div>
    </div>
    );
}

export default withRouter(V_History);




