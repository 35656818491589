import React, { Component } from 'react';
import MainLayout from '../layout/MainLayout';
import { exeRequest} from '../util/APIUtils';
import { STATUS_CODE_REST_API } from '../constants';
import { Drawer, notification, Popconfirm} from 'antd';
//import AntLayout from '../layout/AntLayout';
import V_Liste from './vues/V_Liste_Duplicate';
import DataTable from '../module/DataTable';

class PDuplicate extends Component {

    constructor(props) {
        super(props);
        console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));
        this.state = {
          lists: [],
          visible : false,
          updateCount : 0
         };
      }

      onClose = () => {
        let uCount = this.state.updateCount + 1;
        this.setState({
          visible: false,
          updateCount : uCount
        });
      };


      getDuplicateData = (data) =>{
        this.setState({
          lists: data,
          visible : true         
         });

      }  

      deleteClient(index){

        const clients = this.state.lists;
              
        exeRequest("/clients/" + clients[index].id , "DELETE", null, function(response){
    
            let clients = this.state.lists.slice(); 
    
            this.setState({
              btnLoading : false
            });
    
            if(response.statusCode == STATUS_CODE_REST_API[1].code){
    
                notification.success({
                      message: 'GeoCRM App',
                      description: 'Suppression du client realisée avec succès ... '
                  });    
                  
                  clients.splice(index,1);

                  this.setState({
                    lists : clients.length > 1 ? clients : []
                  });
               
              }else{
             
                  notification.error({
                      message: 'GeoCRM App',
                      description: response.message
                  });  
        
              }
           
          }, this);
    
       }  

    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                     <h5 className="card-title titre_lib" ><strong>Liste des clients dupliqués</strong></h5>
                       <div className="row">
                         <div className="col-md-12">
                           <V_Liste getData={this.getDuplicateData} updatedView={this.state.updateCount} />
                           <Drawer
                            title="Liste des clients"
                            placement="right"
                            closable={false}
                            onClose={this.onClose}
                            visible={this.state.visible}
                            width={1200}>
                              <DrawerDataTable that={this}/>                                                    
                           </Drawer>
                         </div>
                      </div>
                     </div>
                  </div>      
                      
            </div>    
        );
    }
}

export default PDuplicate;


function DrawerDataTable(props){
 
  const Views = [];
        props.that.state.lists.forEach((item, index) => {
              
                Views.push(

                <tr key={item.id} >
                    <th scope="row" style={{width:10}}>{index + 1}</th>
                    <td  > {item.etablissement ? item.etablissement : "" } </td>
                    <td> <strong> {item.interlocuteur ? item.interlocuteur : "" } </strong></td>
                    <td  > {item.indication ? item.indication : "" } </td>
                    <td  > {item.zoneLib ? item.zoneLib : "" } </td>
                    <td  > {item.segmentLib ? item.segmentLib : "" } </td>
                    <td className="actions" style={{width:30}}>
                     <Popconfirm
                        title="Confirmez-vous cette action ?"
                        onConfirm={()=> props.that.deleteClient(index)}
                        okText="OUI"
                        cancelText="NON" >
                       <a href={"#"} style={{cursor: "pointer"}}  ><i className="fa far fa-trash fa-lg"  style={{color:"red"}} ></i></a>
                        {/*<a href={`${process.env.PUBLIC_URL}/parametrage/cat/${item.id}`} style={{cursor: "pointer"}}  ><i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i></a>*/}
                        </Popconfirm>
                    </td>
               </tr>

           )     
              
        });
 
 return (<DataTable>
  <thead>
      <tr>
          <th>#</th>
          <th>Etablissement</th>
          <th>Interlocuteur</th>
          <th>Indication</th>
          <th>Quartier</th>
          <th>Sous-Quartier</th>
          <th>Actions</th>
      </tr>
  </thead>
  <tbody>
      {Views}
  </tbody>
</DataTable>)
}




