import React, { Component } from 'react';
import V_Segment_List from './vues/V_Segment_List';
import V_Segment_Add from './vues/V_Segment_Add';
import {Button, Icon} from 'antd'
class P_Segment_List extends Component {
    constructor(props) {
        super(props);
         //console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));
      this.state={
        visible:false,
        currentItem: null
      };
      }
    
      showDrawer = (item) => {
        this.setState({
          visible: true,
          currentItem: item
        });
      };

    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                     <h5 className="card-title titre_lib" ><strong>Liste des quatiers</strong>
                        <Button type="primary" className="pull-right" onClick={() => this.showDrawer(null)}>
                                <Icon type="plus"/>Ajouter
                        </Button>
                     </h5>
                       <div className="row">
                         <div className="col-md-12">
                           <V_Segment_List onEditing={this.showDrawer} />
                           <V_Segment_Add visible={this.state.visible} currentItem={this.state.currentItem} />
                         </div>
                      </div>
                     </div>
                  </div>              
            </div>    
        );
    }
}

export default P_Segment_List;




