import React, { Component } from 'react';
import scriptLoader from 'react-async-script-loader';
import $ from "jquery";
$.DataTable = require("datatables.net");

const exportJson = {dom: 'Bfrtip',buttons: ['copy', 'csv', 'excel', 'pdf', 'print']}

class DataTable extends Component {

   /* constructor(props) {
        super(props);       
    }*/

  componentDidMount() {
    const { isScriptLoaded, isScriptLoadSucceed} = this.props
   if (isScriptLoaded && isScriptLoadSucceed) {  
        
        this.$el = $(this.el);
        console.log(this.$el)
        this.dttable = this.$el.DataTable(exportJson);
    }
    //this.handleChange = this.handleChange.bind(this);
    //this.$el.on('change', this.handleChange);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.children !== this.props.children) {
        const { isScriptLoaded, isScriptLoadSucceed } = this.props
        if (isScriptLoaded && isScriptLoadSucceed) {
           if(!this.$el) this.$el = $(this.el);
           console.log(this.$el)
            /*if(this.dttable) this.dttable.destroy();*/
            try {   
              if (!$.fn.DataTable.isDataTable(this.$el)) {       

                let json = this.props.jsonInit ? this.props.jsonInit : {};
                const jsonObj = {
                  ...json,
                  ...exportJson
                };

                this.dttable = this.$el.DataTable(jsonObj);   
              }             
            } catch (error) {
                console.log(error);
            }
        }
      //this.$el.trigger("chosen:updated");
    }

  }

  componentWillReceiveProps({ isScriptLoaded, isScriptLoadSucceed}) {
    if (isScriptLoaded && !this.props.isScriptLoaded) {        
     if(isScriptLoadSucceed){
        if(!this.$el) this.$el = $(this.el);
        console.log($)
        console.log(this.$el)
        /*if(this.dttable)  this.dttable.destroy();*/
         try {

          if (!$.fn.DataTable.isDataTable(this.$el)) {
            let json = this.props.jsonInit ? this.props.jsonInit : {};

            const jsonObj = {
              ...json,
              ...exportJson
            };


            this.dttable = this.$el.DataTable(jsonObj);       

          }         
         } catch (error) {
             console.log(error);
         }

        }

      }


   
}

  componentWillUnmount() {
    //this.$el.off('change', this.handleChange);
    //this.$el.chosen('destroy');
    if(this.dttable){
        //alert("testeur de maggie")
     this.dttable.destroy();
    }
  }
  
  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  render() {
    return (
      <div className="table-responsive">
        <table id={this.props.tableId ? this.props.tableId : new Date().getTime() } style={{width:"100%"}} className="table table-bordered table-striped" ref={el => this.el = el}>
          {this.props.children}
        </table>
      </div>
    );
  }

}


export default scriptLoader(
    [
      /*'https://cdn.datatables.net/buttons/1.6.5/js/dataTables.buttons.min.js',
      'https://cdn.datatables.net/buttons/1.6.5/js/buttons.flash.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js',
      'https://cdn.datatables.net/buttons/1.6.5/js/buttons.html5.min.js',
      'https://cdn.datatables.net/buttons/1.6.5/js/buttons.print.min.js'*/
      //'/js/jquery.dataTables.min.js'
      //'https://cdn.datatables.net/1.10.19/js/jquery.dataTables.min.js'
    ]
  )(DataTable)


 