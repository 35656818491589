import React, { Component } from 'react'
import { exeRequest} from '../util/APIUtils'
import LoadingIndicator  from '../common/LoadingIndicator'
import { Button, Icon, notification } from 'antd'
import MainLayout from '../layout/MainLayout';
import DataTable from '../module/DataTable';
//import { withRouter } from 'react-router-dom'
import V_Timeline  from './vues/V_Timeline'
//import './PProfile.css'

class PTimeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zones: [],
            zoneIdSelected: null,
            isLoading: false
        };

        this.loadAllZones = this.loadAllZones.bind(this);
        this.handleSelectedZone = this.handleSelectedZone.bind(this);
        
    }

    componentDidMount() {
        this.loadAllZones();
    }

    handleSelectedZone(e){
        //e.preventDefault();
        console.log(e.target)
        let zoneId = (e.target.getAttribute("data-zoneid") ? parseInt(e.target.getAttribute("data-zoneid")) : null);
        console.log(zoneId)
        this.setState({
            zoneIdSelected : zoneId
        });
    }

    loadAllZones(){
        
        this.setState({
            isLoading: true
          });

        exeRequest("/zones", "GET", null, function(response){

        this.setState({
            zones: response ? response : [],
            isLoading: false,
            zoneIdSelected: response ? response[0].id : null
        })
        
        console.log("________________________________________  zone Id " + response[0].id);

          }, this);


       
    }

    componentDidUpdate(nextProps) {

        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                zones: [],
                isLoading: false,
                zoneIdSelected: null
            });   

            this.loadAllZones();
        }
    }

      
    
    render() {

    if(this.state.isLoading) {
        return <LoadingIndicator />
    }

    const {zones, zoneIdSelected } = this.state;

     return (
     <div className="row">
        <div className="col-md-3">
        <div className="card">
        <div className="card-body">
          <h5 className="card-title titre_lib" ><strong>Liste des zones</strong></h5>
        {/*<table  className="table table-bordered table-striped">*/}
        <DataTable tableId="client_table" jsonInit={this.props.dtInit} >
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col" className="liclient">Zones</th>
                <th scope="col" className="actions">Actions</th>        
            </tr>
        </thead>
        <tbody>
        {zones.map(zone =>(
          <tr key={zone.id} >
            <th scope="row">{zone.id}</th>
            <td> 
                <strong style={{fontSize:16}} >{zone.libelle ? zone.libelle : "" }</strong> <br />
                {/*<strong className="nomclt">{client.categorieLib ? client.categorieLib : "" }</strong>  &nbsp;|&nbsp; <span className="date">{client.lastOperation ? new Date(client.lastOperation).toLocaleString("fr-FR") : "Jamais visité" }</span>*/}
            </td>
            <td style={{width : 15}} className="action_btn">
                {/*<a href={`${process.env.PUBLIC_URL}/client/${client.id}`} style={{cursor: "pointer"}}  ><i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i></a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                <a href="#" style={{cursor: "pointer"}} data-zoneid={zone.id}  onClick={(e) => this.handleSelectedZone(e)} ><i data-zoneid={zone.id} className="fa far fa-map-marker fa-lg"  style={{color:"black"}} ></i></a>
            </td>
          </tr>
         ))}
        </tbody>
        </DataTable>
         </div>
        </div> 
         {/*</table>*/}
        </div>

        <div className="col-md-9" >
        <div className="card">
        <div className="card-body">
          {/*<h5 className="card-title titre_lib" ><strong>Liste des zones</strong></h5>*/}
            <V_Timeline zoneId={zoneIdSelected} />
        </div>
       </div>
      </div>
     </div>   
     );

    }
}

export default PTimeline;

PTimeline.defaultProps = {
    dtInit : {
        "lengthChange": false,
        "lengthMenu": [ 7, 14, 21, 28, 35]
      }
}
