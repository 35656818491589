import React, { Component } from 'react';
import { changePage } from '../util/Helpers';

class Menu extends Component {


    /*changePage(e){
        e.preventDefault();   
        console.log(e);
        let urlGo = e.target.getAttribute("data-url") ? e.target.getAttribute("data-url") : "/";
        console.log(urlGo)
        console.log(this.props);
        this.props.history.push(urlGo);
      }*/


    render() {
        return (          
                <div className="card card-filter">
                    <article className="card-group-item">
                        <header className="card-header" id="dashboard">
                            <h6 className="title">  DASHBOARD</h6>
                        </header>
                    </article>
                    <article className="card-group-item">
                        <header className="card-header">
                            <a className="" aria-expanded="true" href="#" data-toggle="collapse" data-target="#collapse1">
                                <i className="icon-action fa fa-chevron-down" id="icons"></i>
                                <h6 className="title t-menu" > <i className="fa fa-users"></i> Clients</h6>
                            </a>
                        </header>
                        <div  className="filter-content collapse " id="collapse1">
                            <div className="card-body">
                                <ul className="list-unstyled list-lg">
                                    <li><a data-url={`${process.env.PUBLIC_URL}/`} onClick={(e)=> changePage(e,this)} href="#" > Cartographie</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/client/list`} onClick={(e)=> changePage(e, this)} href="#" > Liste des clients </a></li>                                   
                                    <li><a data-url={`${process.env.PUBLIC_URL}/zone/list`}  href="#" onClick={(e)=> changePage(e, this)}  > Zones </a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/client/enrollments`} onClick={(e)=> changePage(e, this)} href="#" >Enrolements </a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/client/search`}  href="#" onClick={(e)=> changePage(e, this)}  > Statistique </a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/client/consolidate`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Consolidation</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/client/duplicate`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Duplicate</a></li>
                                </ul>
                            </div>
                        </div>
                    </article>

                   {/*<article className="card-group-item">
                        <header className="card-header">
                            <a className="" aria-expanded="true" href="#" data-toggle="collapse" data-target="#collapse3">
                                <i className="icon-action fa fa-chevron-down" id="icons"></i>
                                <h6 className="title t-menu" > <i className="fa fa-cubes"></i> Opérations</h6>
                            </a>
                        </header>
                        <div  className="filter-content collapse " id="collapse3">
                            <div className="card-body">
                                <ul className="list-unstyled list-lg">
                                    <li><a href="#" id="lien" onClick={(e)=> changePage(e,this)} > Gestion des operations</a></li>
                                </ul>
                            </div>
                        </div>
                    </article>*/}
                    <article className="card-group-item">
                        <header className="card-header">
                            <a className="" aria-expanded="true" href="#" data-toggle="collapse" data-target="#collapse4">
                                <i className="icon-action fa fa-chevron-down" id="icons"></i>
                                <h6 className="title t-menu" ><i className="fa fa-handshake-o"></i> Forces de ventes</h6>
                            </a>
                        </header>
                        <div  className="filter-content collapse " id="collapse4">
                            <div className="card-body">
                                <ul className="list-unstyled list-lg">
                                    <li><a data-url={`${process.env.PUBLIC_URL}/user/list`} onClick={(e)=> changePage(e, this)} href="#" id="lien">Commerciaux</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/user/objectif`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Objectifs</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/user/selling`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Corrections</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/reports`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Rapports</a></li>
                                </ul>
                            </div>
                        </div>
                    </article>
                    <article className="card-group-item">
                        <header className="card-header">
                            <a className="" aria-expanded="true" href="#" data-toggle="collapse" data-target="#collapse5">
                                <i className="icon-action fa fa-chevron-down" id="icons"></i>
                                <h6 className="title t-menu" ><i className="fa fa-cogs"></i> Paramètres</h6>
                            </a>
                        </header>
                        <div  className="filter-content collapse " id="collapse5">
                            <div className="card-body">
                                <ul className="list-unstyled list-lg">
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/cat/list`} onClick={(e)=> changePage(e, this)} href="#" id="lien">Categories</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/users/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Utilisateurs</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/posm/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Posms</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/zone/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Zones</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/segment/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Quartiers</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/marque/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Marques</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/mention/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Mentions</a></li>
                                    {/*<li><a data-url={`${process.env.PUBLIC_URL}/param/stock/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Stocks</a></li>*/}
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/produit/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Produits</a></li>
                                    <li><a data-url={`${process.env.PUBLIC_URL}/param/tag/list`}  onClick={(e)=> changePage(e, this)} href="#" id="lien">Tags</a></li>
                                </ul>
                            </div>
                        </div>
                    </article>
                </div>
           
        );
    }
}

export default Menu;




