import React, { Component } from 'react'
import { exeRequest,getAllZone } from '../util/APIUtils'
import LoadingIndicator  from '../common/LoadingIndicator'
import { Button, Icon, notification, Tabs } from 'antd'
//import MainLayout from '../layout/MainLayout';
//import DataTable from '../module/DataTable';
//import { withRouter } from 'react-router-dom'
//import V_Timeline  from './vues/V_Timeline'
import V_Timeline_Commerciaux  from './vues/V_TimeLine_Commerciaux'
import { parse } from 'path';
//import './PProfile.css'


const { TabPane } = Tabs;

class _PTimeline extends Component {

    constructor(props) {
        super(props);
        this.state = {
            zones: [],
            isLoading: false,
            zoneIdSelected : null
        };

        this.loadAllZones = this.loadAllZones.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        
    }

    componentDidMount() {
        this.loadAllZones();
    }

   /* handleSelectedZone(e){
        //e.preventDefault();
        console.log(e.target)
        let zoneId = (e.target.getAttribute("data-zoneid") ? parseInt(e.target.getAttribute("data-zoneid")) : null);
        console.log(zoneId)
        this.setState({
            zoneIdSelected : zoneId
        });
    }*/

    loadAllZones(){
        
      
        this.setState({
            isLoading: true
          });

          exeRequest("/zones", "GET", null, function(response){

            this.setState({
                zones: response ? response : [],
                isLoading: false,
                zoneIdSelected: response ? response[0].id : null
            })
            
            //console.log("________________________________________  zone Id " + response[0].id);
          
          }, this);
    }

    componentDidUpdate(nextProps) {

        /*if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                zones: [],
                isLoading: false
            });   

            this.loadAllZones();
        }*/
    }

    onTabClick(k,e){
        this.setState({
            zoneIdSelected: parseInt(k)
        });
      /*console.log(k);
      console.log(e);*/
    }
    
    render() {

    if(this.state.isLoading) {
        return <LoadingIndicator />
    }

    const {zones} = this.state;

     return (
        <div className="card">
          <div className="card-body">
            <h5 className="card-title titre_lib" ><strong>Liste des zones</strong></h5>
                <Tabs style={{marginTop:30}} onTabClick={(k,e) => this.onTabClick(k,e)} tabPosition={"left"}>
                    {zones.map(zone =>(
                        <TabPane forceRender={true} tab={zone.libelle ? zone.libelle : "" } key={zone.id}>
                            {this.state.zoneIdSelected === zone.id  && <V_Timeline_Commerciaux zoneId={this.state.zoneIdSelected} />}
                        </TabPane>
                    ))}
                </Tabs> 
            </div>
        </div>
     );

    }
}

export default _PTimeline;