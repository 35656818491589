import React, { Component } from 'react';
import {exeRequest} from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import {RegroupItem, sMillier} from '../../util/Helpers';
import { Drawer, Tooltip, Progress, Avatar, DatePicker, Badge } from 'antd';
import { GET_USERS_FILES_CTR } from '../../constants';
import moment from 'moment';
/*import {cusStatus} from '../../util/Colors';*/
import { withRouter } from 'react-router-dom';
import V_Profile from '../../user/vues/V_Profile';
import $ from "jquery";
import './V_Liste.css';


const { MonthPicker} = DatePicker;

const dateFormat = "YYYY-MM-DD";

class UsersList extends Component {

    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            users: [],
            isLoading: false,
            sUserId : 0,
            month : new Date(date.getFullYear(), date.getMonth() + 1, 0)
        };
        this.loadUsersList = this.loadUsersList.bind(this);
    }

    showDrawer = (e) => {
        //var userId = $(e).attr("data-id");
        console.log(e.currentTarget);
        this.setState({
          visible: true,
          sUserId : $(e.currentTarget).attr("data-id")
        });

        return false;

      };
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };


      dateMonthChange = (e) => {

       //console.log(new Date(e._d).toISOString());
       let date = new Date(e._d);
       let lastDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
       console.log("Month Last Date : ", lastDate);

       this.setState({
            month : lastDate
        }); 

        this.loadUsersList(lastDate);

    }


    loadUsersList(month) {

        this.setState({
          isLoading: true
        })

        month = month ? month : this.state.month

        let sDate = new Date(month.getFullYear(), month.getMonth(), 1) ;
        let eDate = month;

        console.log(sDate.toISOString().split("T")[0])
        console.log(eDate.toISOString().split("T")[0])
        //"/users/getByRoles/" + "ROLE_COMM"
        exeRequest("/reportings/udashbord/all" + "/?startdate="+ sDate.toISOString().split("T")[0] +"&endate=" + eDate.toISOString().split("T")[0], "GET", null, function(response){
            this.setState({
                users: response ? response : [],
                isLoading: false
            })

          }, this)
    }

    componentDidMount() {
        this.loadUsersList();
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                users: [],
                isLoading: false
            });    

            this.loadUsersList();
        }
    }

    /*handleLoadMore() {
        this.loadUsersList(this.state.page + 1);
    }

    handleSelectedClient(index){
    let clients = this.state.clients;
   
     console.log(clients[index]);
  
    this.setState({ currentClient: clients[index] });
       
    }*/

    
    render() {

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }

        return (
            <div>

                <center className="row text-center">
                    <div className="col-xs-12 col-sm-12 col-lg-12" style={{margin : "15px"}} >
                        <MonthPicker defaultValue={moment(this.state.month, dateFormat)} onChange={this.dateMonthChange} />
                    </div>
                </center>
              
               <MainView users={this.state.users} that={this} />

              <Drawer
               width={1000}
               placement="right"
               closable={false}
               onClose={this.onClose}
               visible={this.state.visible} >
                 <V_Profile that={this} userId={this.state.sUserId} />
              </Drawer>

            </div>

            );
    }

}

function MainView(props) {
    const usersViews = [];
    //console.log("________________________________________");
    //console.log(props.users);
    //this.state.users{(z.fieldValue ? z.fieldValue : "")} 
    let users = props.users.sort((a, b) => b.amountSelling - a.amountSelling)
    var usersGrouping = RegroupItem(users, "amountSelling");
    console.log("Grouping", usersGrouping)
    //console.log(zones);
    //zones.forEach((z, zindex) => {
        //let classement = 1;  
        let html = [];  
        users.forEach((item, userIndex) => {
            //let visiteCount = item.countVisit + item.countSelling;
            html.push(
             <div key={"users_" + userIndex} className="col-xs-12 col-sm-6 col-md-3" style={{marginTop:10}} > 
                
                    <div className="image-flip box-shadow">
                        <span className="badge badge-danger" style={{height: "30px", paddingTop: "8px", fontSize: "12px"}}>{userIndex + 1 } <sup>{ userIndex == 0 ? "er" : "ème"}</sup> &nbsp; - &nbsp; {item.amountSelling ? sMillier(item.amountSelling) : 0}&nbsp;FCFA </span>
                        <div className="mainflip">
                            <div className="frontside">
                                <div className="card">
                                    <div className="card-body text-center">
                                        <p>
                                        
                                          <Avatar size={100} src={`${GET_USERS_FILES_CTR + item.user.username + ".png"}`}  icon="user" /> 
                                        
                                        {/*<img className=" img-fluid" src={`${process.env.PUBLIC_URL}/images/user/avatar-1.jpg`} alt={user.name} />*/}
                                        </p>
                                         <Badge title={item.user.name + " a " + item.countStockValidation + " entrées de stock a valider"} count={item.countStockValidation}><h6 className="card-title" style={{marginBottom: "2px", marginRight: "10px"}}>{item.user.name}</h6></Badge>
                                        <p className="card-text" style={{marginBottom: "2px"}} >{item.user.email}</p>
                                        <p className="card-text" style={{marginBottom: "2px"}} >{item.user.phone}</p>
                                        <p className="card-text">
                                            { 
                                                item.user.zones.map((zone) =>
                                                    <span key={zone.id} className="tags" style={{fontSize:"10px"}}> {zone.libelle}</span> 
                                                ) 
                                            }
                                        </p>

                                        <div className="card-text"  style={{marginTop:30}} >
                                         <Objectif  countVisit = {item.countVisit}  countSelling = {item.countSelling}  countClients={item.countClients} />
                                         {/*<i className="fa fa-bullseye" ></i>&nbsp; {sMillier(visiteCount) + " / " + sMillier(2 * item.countClients) } &nbsp; {"(" + Math.round((visiteCount / (2 * item.countClients)) * 100) + " %)" } */} 
                                            
                                        </div>

                                        <a href="#"  onClick={props.that.showDrawer} data-id={item.user.id} ><i style={{color : "#3c2683"}} className="fa fa-2x fa-eye" ></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
         
        )     
          
      });

    usersViews.push(
        <div className="psm-card card border-secondary mb-3">
            <div className="card-header"></div>
            <div className="card-body text-secondary">
            <div className="row team">
                {html}
            </div>
            </div>
        </div>   
        );

//});

 return usersViews;
 
}


function Objectif (props){

     let allVisiteCount  = props.countVisit + props.countSelling;
     let objectifCount  = 2 * props.countClients
     let percent = Math.round((allVisiteCount / (objectifCount)) * 100);
     let message = allVisiteCount + " visite(s) pour " + objectifCount + " visite(s) prevue(s) soit " +  percent + " % de visite réalisée."
     return (<div>
               <Tooltip title={message}>
                  <i className="fa fa-bullseye" ></i>&nbsp; {sMillier(allVisiteCount) + " / " + sMillier(objectifCount) } <Progress  percent={percent} width={50} type="circle"  />
                </Tooltip>
            </div>)
}

export default withRouter(UsersList);