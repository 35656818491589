import React, { Component } from 'react';
import './App.css';
import 'antd/dist/antd.css'
import {
  Route,
  withRouter,
  Switch
} from 'react-router-dom';

import { exeRequest } from '../util/APIUtils';
import { ACCESS_TOKEN, CURRENT_USER } from '../constants';

import PClients from '../client/PList';
import PEnrollments from '../client/PEnrollments';
import PSearch from '../client/PSearch';


import Login from '../user/login/Login';
import Signup from '../user/signup/Signup';
//import Profile from '../user/profile/Profile';
import MainLayout from '../layout/MainLayout';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';
import PrivateRoute from '../common/PrivateRoute';
import LoginRoute from '../common/LoginRoute';
import PProfile from '../client/PProfile';
import Pcarte from '../client/PCarte';
//import PRoute from '../client/PRoute';
import PUsers from '../user/PList';
import { Layout, notification,message } from 'antd';
import PTimeline from '../client/PTimeline';
import _PTimeline from '../client/_PTimeline';

/*import {P_Cat_List, P_Posm_List, P_Zone_List, P_Segment_List, 
  P_Users_List, P_Produit_List,P_Tag_List} from '../param/';*/

import P_Mention_List from '../param/mention/P_Mention_List';
import P_Cat_List from '../param/cat/P_Cat_List';
import P_Posm_List from '../param/posm/P_Posm_List';
import P_Zone_List from '../param/zone/P_Zone_List';
import P_Segment_List from '../param/segment/P_Segment_List';
import P_Users_List from '../param/user/P_Users_List';
import P_Produit_List from '../param/produit/P_Produit_List';
import P_Tag_List from '../param/tag/P_Tag_List';
import P_Marque_List from '../param/marque/P_Marque_List';


import PConsolidate from '../client/PConsolidate';
import PDuplicate from '../client/PDuplicate';
import PReport from '../param/reports/PReports';
import PStats from '../client/PStats';
import PObjectif from '../user/PObjectif';
import PPatchSelling from '../user/PPatchSelling';

//import P_Stock_List from '../stock/P_Stock_List';

//import $ from "jquery";
//const { Content } = Layout;

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentUser: (localStorage.getItem(CURRENT_USER) ? JSON.parse(localStorage.getItem(CURRENT_USER)) : null),
      isAuthenticated: (localStorage.getItem(ACCESS_TOKEN) ? true : false),
      isLoading: false
    }

    this.handleLogout = this.handleLogout.bind(this);
    this.loadCurrentUser = this.loadCurrentUser.bind(this);
    this.handleLogin = this.handleLogin.bind(this);

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3
    }); 

  }

  loadCurrentUser() {

    this.setState({
      isLoading: true
    });

    exeRequest("/user/me", "GET", null, function(response){

      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false
      });
   
      }, this);

    /*getCurrentUser()
    .then(response => {
      this.setState({
        currentUser: response,
        isAuthenticated: true,
        isLoading: false
      });
    }).catch(error => {
      this.setState({
        isLoading: false
      });  
    });*/

  }

  componentDidMount() {
    //this.loadCurrentUser();
    this.setState({
      currentUser: (localStorage.getItem(CURRENT_USER) ? JSON.parse(localStorage.getItem(CURRENT_USER)) : null),
      isAuthenticated: (localStorage.getItem(ACCESS_TOKEN) ? true : false),
      isLoading: false
    });
  }

  handleLogout(redirectTo="/", notificationType="success", description="Vous avez été déconnectés avec succès") {

    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(CURRENT_USER);

    this.setState({
      currentUser: null,
      isAuthenticated: false
    });

    this.props.history.push(redirectTo);
    
    notification[notificationType]({
      message: 'GeoCRM App',
      description: description,
    });

  }

  handleLogin(userLogin) {

    message.success("GeoCRM : Bienvenue " + userLogin.name, 10);

    this.setState({
      currentUser: userLogin,
      isAuthenticated: true,
      isLoading: false
    });
    
    //this.loadCurrentUser();
    this.props.history.push("/");

    document.getElementsByTagName("body")[0].classList.remove("login-container");
  }

  render() {

    if(this.state.isLoading) {
      return <LoadingIndicator />
    }

    return (
      <div style={{minHeight: "100%", height: "100%"}} >
        {/*<Header />
        <Dashbord />
         <div>*/}
         <LayoutChooser currentUser={this.state.currentUser} isAuthenticated={this.state.isAuthenticated} handleLogout={this.handleLogout}  changePage={this.changePage}  >
            <Switch>  
                <PrivateRoute authenticated={this.state.isAuthenticated} exact path="/" component={Pcarte} />                 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/signup" component={Signup} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/users/:username" component={Pcarte}  />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/client/list"  component={PClients} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/client/enrollments"  component={PEnrollments} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/client/search"  component={PSearch} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/zone/list" component={_PTimeline} />
                {/*<PrivateRoute authenticated={this.state.isAuthenticated} path="/client/stats" component={PStats} />*/}
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/client/:id" component={PProfile} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/route/:id" component={PTimeline} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/user/list" component={PUsers} />   
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/user/objectif" component={PObjectif} /> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/cat/list" component={P_Cat_List} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/posm/list" component={P_Posm_List} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/zone/list" component={P_Zone_List} />
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/segment/list" component={P_Segment_List} /> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/users/list" component={P_Users_List}/> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/produit/list" component={P_Produit_List}/> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/tag/list" component={P_Tag_List}/> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/mention/list" component={P_Mention_List}/> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/marque/list" component={P_Marque_List}/> 
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/client/consolidate" component={PConsolidate}/>
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/client/duplicate" component={PDuplicate}/>  
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/param/reports" component={PReport}/>  
                <PrivateRoute authenticated={this.state.isAuthenticated} path="/user/selling" component={PPatchSelling} /> 
                {/*<PrivateRoute authenticated={this.state.isAuthenticated} path="/user/:id/stock" component={P_Stock_List} />*/} 
                <LoginRoute authenticated={this.state.isAuthenticated} onLogin={this.handleLogin} path="/login" component={Login}/>   
                {/*<Route path="/login" 
                  render={(props) => <Login onLogin={this.handleLogin} {...props} />}>
                  </Route>  */}   
                <PrivateRoute authenticated={this.state.isAuthenticated}  component={NotFound}/>               
            </Switch>
          </LayoutChooser>
        {/*</div>
        <Footer />*/}
      </div>
    );
  }
}

function LayoutChooser(props){
  if(props.isAuthenticated){
    return(<MainLayout {...props} >{props.children}</MainLayout>)
  }else{
    return(<div>{props.children}</div>)
  }
}

export default withRouter(App);
