import React, { Component } from 'react';
import { exeRequest } from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { Input,Switch, Avatar, notification, Button, Popconfirm, Alert, Tabs } from 'antd';
import { API_BASE_URL,STATUS_CODE_REST_API, STATUS } from '../../constants';
/*import {cusStatus} from '../../util/Colors';*/
import { withRouter } from 'react-router-dom';
import DataTable from '../../module/DataTable';

const { TabPane } = Tabs;

class V_Zone_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            isLoading: false,
            cLoading : false,
            userId : this.props.userId,
            numberUpdated : this.props.numberUpdated 
        };
        //this.validateStock = this.validateStock.bind(this);
        this.onCheckedChange = this.onCheckedChange.bind(this);
        this.loadUserZone = this.loadUserZone.bind(this);
        //this.loadStocks = this.loadStocks.bind(this);
    }


    loadUserZone() {

        this.setState({
            isLoading: true
          });

          exeRequest("/users/" + this.state.userId + "/allZoneStatus", "GET", null, function(response){
           response = response.sort(function(a, b) {
                return parseInt(a.ordre ? a.ordre : 1000) - parseInt(b.ordre ? b.ordre : 1000);
            });

            this.setState({
                lists: response ? response : [],
                isLoading: false,
                //btnLoading : false
            });

            //this.loadStocks(); 


          }, this)
        
    }

   onCheckedChange(checked, index){

    this.setState({
       cLoading: true
        //btnLoading : false
    });

    exeRequest("/users/" + this.state.userId +"/zones/"+ this.state.lists[index].id +"/activate/" + (checked ? STATUS[1].value : STATUS[0].value) , "GET", null, function(response){

        this.setState({
            cLoading: false,
             //btnLoading : false
         });

        if(response.statusCode == STATUS_CODE_REST_API[1].code){         
            let lists = this.state.lists;
            lists[index].status = (checked ? STATUS[1].value : STATUS[0].value)
            lists = lists.sort(function(a, b) {
                return parseInt(a.ordre ? a.ordre : 1000) - parseInt(b.ordre ? b.ordre : 1000);
            });
            this.setState({ lists: lists });  
            //this.props.forceUpdate();
        }else{
            notification.error({
                message: 'GeoCRM App',
                description: "Une erreur est survenue lors du traitement de votre action"
            });
        }

       }, this);

    } 

   /* validateStock(){

         this.setState({
            btnLoading: true
          });

          const lists = this.state.lists;

          var obj = {
              vstocks : lists
          }

          console.log(obj);

        exeRequest("/users/"+ this.state.userId +"/validate/vstocks", "POST", JSON.stringify(obj), function(response){

            if(response.statusCode == STATUS_CODE_REST_API[1].code){

                notification.success({
                    message: 'GeoCRM App',
                    description: 'Stock de '+ lists[0].userName +' Validé avec succès ...'
                });            
             
                console.log()

               this.props.forceUpdate();

            }else{

              this.setState({
                    btnLoading: false
                });

                notification.error({
                    message: 'GeoCRM App',
                    description: 'Une erreur est survenue lors de la validation du stock'
                });  

            }

          }, this);
    }*/
   
    componentDidMount() {
        this.loadUserZone();
        //loadCategories();
    }

    componentDidUpdate(prevProps, prevState) {
       if ((prevState.userId !== this.state.userId) 
       || (prevState.numberUpdated !== this.state.numberUpdated)) {
            this.loadUserZone();
        }
    }

    componentWillReceiveProps({userId, numberUpdated}) {   
          this.setState({ userId: userId !== null ? userId :  this.state.userId });   
          this.setState({ numberUpdated : numberUpdated !== null ? numberUpdated :  this.state.numberUpdated });  
          //console.log("__________________ Stock list _______" + numberUpdated)     
      }
    
    render(){
               

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
   
       return (<MainView  that={this} />);

    }
   
}


function MainView(props){
   
    let that = props.that;

   const { cLoading } = that.state;

    const Views = [];

    that.state.lists.forEach((item, index) => {
           Views.push(
            <tr key={item.id} >
                <th scope="row" > {index + 1} </th>
                <td><strong>{item.libelle ? item.libelle : "" }</strong></td>
                <td><Switch size="small" loading={cLoading ? true : false}  checked={(!item.status || item.status == 0)  ? false : true} onChange={(checked) => that.onCheckedChange(checked, index)}  /></td>
           </tr>
       )     
          
    });

    if(that.state.lists.length === 0){
        return ( <Alert
              message="Validation de stock"
              description="Aucun stock a valider pour cet utilisateur."
              type="info"
              showIcon
            />)
        
     }

   return ( <div>
        <div>
        <table className="table table-bordered table-striped" >
            <thead>
                <tr>
                <th style={{width : 50}} >#</th>
                <th>Zone</th>
                <th style={{width : 80}} >Activer</th>
                {/*<th>Commercial</th>*/}
                </tr>
            </thead>
            <tbody>
                {Views}
            </tbody>
         </table>
           {/*<Popconfirm
                    title="Confirmez-vous cette action ?"
                    onConfirm={()=> that.validateStock()}
                    okText="OUI"
                    cancelText="NON" >
                <Button type="primary" style={{marginTop : 20}} loading={that.state.btnLoading} htmlType="button" size="large" icon="check" className="pull-right">Valider</Button>
           </Popconfirm> */}
       </div>
 </div>);

}




export default withRouter(V_Zone_List);