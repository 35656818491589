import React, { Component } from 'react';
import { exeRequest } from '../util/APIUtils';
import LoadingIndicator  from '../common/LoadingIndicator';
import { Popconfirm, InputNumber, Input,Select, Form, notification, Button, DatePicker, Col, Row } from 'antd';
import { API_BASE_URL,COMM_ROLE, STATUS_CODE_REST_API, TYPE_OPERATION } from '../constants';
/*import {cusStatus} from '../../util/Colors';*/
import {jours} from '../util/Colors';
import {sMillier, changePage, RegroupItem} from '../util/Helpers'
import { withRouter } from 'react-router-dom';
import DataTable from '../module/DataTable';
import moment from 'moment';


const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

class PSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            categories : [],
            zones : [],
            produits : [],
            segments  : [],
            allSegments  : [],
            typeOperation: "0",
            users: [],
            isLoading: false,
        };       
    }


  loadClients = (event) =>{
  
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err){ 
       // this.setState({ btnLoading: true });

        let objRequest = Object.assign({}, values);

        console.log(objRequest);

        let date = objRequest.rangeDate
      
         let startDate = (objRequest.rangeDate ? new Date(date[0]._d.getFullYear(), date[0]._d.getMonth(), date[0]._d.getDate(), 0, 1, 1) : new Date());
         let endDate = (objRequest.rangeDate ? new Date(date[1]._d.getFullYear(), date[1]._d.getMonth(), date[1]._d.getDate(), 23, 59, 59) : new Date());

         this.setState({
           isLoading: true
          });

          console.log("objRequest ===>>> ",objRequest)

          exeRequest("/clients/getsearch?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString() + 
                     "&segmentId=" + objRequest.segmentId + "&type=" + objRequest.operationId + "&zoneId=" + objRequest.zoneId + "&produitId=" + (objRequest.produitId ? objRequest.produitId : 0)
                     + "&categorieId=" + objRequest.categorieId, "GET", 
                      null, function(response){
                  this.setState({
                      clients: response ? response : [],
                      isLoading: false,
                      //btnLoading : false
                  });
          }, this)

        }

      });

  }
        
  loadCategories = () =>{
    exeRequest("/categories", "GET", null, function(response){
      this.setState({
        categories: response ? response : []
      });
    }, this)
  }

  loadZones = () =>{
    exeRequest("/zones", "GET", null, function(response){
      this.setState({
        zones: response ? response : []
      });
    }, this)
  }

  loadAllSegments = () => {

      exeRequest("/segments", "GET", null, function(response){
          this.setState({
              allSegments : response ? response : []
          })
        }, this)
        
  }
 
  loadSegments = (zoneId) => {

    if(zoneId){

      exeRequest("/zones/" + zoneId + "/segments", "GET", null, function(response){
          this.setState({
              segments: response ? response : []
          })
        }, this)

    }else{

      this.setState({
          segments: []
      })

  }

   this.props.form.setFields({
    segmentId: {value: "0"}
   });

}
  
  loadUsers = () => {
    exeRequest("/users/getByRoles/" + COMM_ROLE, "GET", null, function(response){
      this.setState({
        users: response ? response : []
      });

    }, this)
  
}

changeOperation = (e) =>{

  console.log(e);

  this.setState({
    typeOperation : e
  });

}

loadProduits = () =>{
  exeRequest("/produits/enable", "GET", null, function(response){
    this.setState({
      produits: response ? response : []
    });
  }, this)
}

   componentDidMount() {
      this.loadCategories();
      this.loadZones();
      this.loadUsers();
      this.loadAllSegments();
      this.loadProduits();
    }

     
    render() {
        const {getFieldDecorator} = this.props.form;
          return (
              <div>
                  <div className="card">
                  <div className="card-body">
                      <h5 className="card-title titre_lib" ><strong>Statistique </strong></h5><br />
                         <div className="row">
                           <div className="col-md-12">
                            
                           <Form layout="vertical" hideRequiredMark onSubmit={this.loadClients}>
  
                               <div className="row">

                                <div className="col-lg-10 col-sm-12 col-xs-12">
                                    <div className="row">

                                    <div className="col-lg-4 col-sm-12 col-xs-12" >
                                        <Form.Item label="La zone : ">
                                          {getFieldDecorator('zoneId', {
                                              rules: [{ required: true, message: 'Veuillez Choisir la zone' }],
                                              initialValue : "", 
                                            })(<Select placeholder="Veuillez choisir la zone" >
                                            <Option key={"zone_" + 0} onClick={()=>this.loadSegments("")}  value={""}>{"Veuillez choisir la zone"}</Option>
                                            {this.state.zones.map(( m, index) => (
                                                <Option onClick={()=>this.loadSegments(m.id)} key={"zone_" + m.id} value={m.id}>{m.libelle}</Option>
                                            ))}
                                          </Select>)}                                 
                                        </Form.Item>
                                        </div>

                                     <div className="col-lg-4 col-sm-12 col-xs-12" >
                                          <Form.Item label="Le segment : ">
                                          {getFieldDecorator('segmentId', {
                                              rules: [{ required: false, message: 'Veuillez Choisir le segment' }],
                                              initialValue : "0", 
                                            })(<Select placeholder="Veuillez choisir le segment ">
                                            <Option key={"segment_" + 0} value={"0"}>{"Tous les segments"}</Option>
                                            {this.state.segments.map(( m, index) => (
                                                <Option key={"segment_" +m.id} value={m.id}>{jours[m.ordre -1] +  " / " + m.libelle}</Option>
                                            ))}
                                          </Select>)}                                 
                                            </Form.Item>
                                        </div>
                                  
                                      <div className="col-lg-4 col-sm-12 col-xs-12" >
                                          <Form.Item label="Categorie : ">
                                          {getFieldDecorator('categorieId', {
                                              rules: [{ required: false, message: 'Veuillez Choisir la categorie' }],
                                              initialValue : "0", 
                                            })(<Select placeholder="Veuillez choisir la categorie ">
                                            <Option key={"categories_" + 0} value={"0"}>{"Toutes les categories"}</Option>
                                            {this.state.categories.map(( m, index) => (
                                                <Option key={"categories_" +m.id} value={m.id}>{m.libelle}</Option>
                                            ))}
                                          </Select>)}                                 
                                            </Form.Item>
                                        </div>

                                      <div className="col-lg-4 col-sm-12 col-xs-12" >
                                          <Form.Item label="Operation : ">
                                          {getFieldDecorator('operationId', {
                                              rules: [{ required: false, message: 'Veuillez Choisir une operation' }],
                                              initialValue : 0, 
                                            })(<Select placeholder="Veuillez choisir une operation" onChange={this.changeOperation}>
                                            <Option key={"operations_" + 0} value={0}>{"Recherche"}</Option>
                                            <Option key={"operations_1"} value={1}>{"Operations"}</Option>
                                           {/*<Option key={"operations_" +TYPE_OPERATION["2"].id} value={TYPE_OPERATION["2"].Id}>{TYPE_OPERATION["2"].Lib}</Option>*/}  
                                          </Select>)}                               
                                            </Form.Item>
                                        </div>

                                        {this.state.typeOperation > 0 && ( 
                                         <>
                                          <div className="col-lg-4 col-sm-12 col-xs-12" >
                                                <Form.Item label="Intervalle de dates : ">
                                                  {getFieldDecorator('rangeDate', {
                                                      rules: [{ required: true, message: 'Veuillez choisir une intervalle de date' }],
                                                      initialValue : [moment(new Date(), dateFormat), moment(new Date(), dateFormat)], 
                                                    })(<RangePicker style={{width:"100%"}}  placeholder="Choisir une intervalle de date" />)}                                 
                                                  </Form.Item>
                                              </div>

                                          <div className="col-lg-4 col-sm-12 col-xs-12" >
                                          <Form.Item label="Produit : ">
                                          {getFieldDecorator('produitId', {
                                              rules: [{ required: false, message: 'Veuillez Choisir le produit' }],
                                              initialValue : "0", 
                                            })(<Select placeholder="Veuillez choisir le produit ">
                                            <Option key={"produits_" + 0} value={"0"}>{"Tous les produits"}</Option>
                                            {this.state.produits.map(( m, index) => (
                                                <Option key={"produits_" +m.id} value={m.id}>{m.libelle}</Option>
                                            ))}
                                          </Select>)}                                 
                                            </Form.Item>
                                        </div>
                                         </>
                                        )}

                                    </div>
                                </div>

                                <div className="col-lg-2 col-sm-12 col-xs-12" >
                                    <Button loading={this.state.isLoading} className="mt-5 pull-right" htmlType="submit" size="large" type="primary" > Valider </Button>
                                </div>
                                     
                               </div>   
                             
                               <hr></hr>
                               <br/>
                               {this.state.isLoading ? <LoadingIndicator /> :  (<Row>
                                 <Col span={24}> 
                                   <DataTable>
                                        <thead>
                                            <tr>
                                                <th style={{width:20}} >#</th>
                                                <th style={{width:"28%"}} >{"Clients"}</th>
                                                <th style={{width:110}} >Zone</th>
                                                <th style={{width:110}} >Quartier</th>
                                                <th style={{width:110}} >Categorie</th>
                                                <th style={{width:110}} >Nbre Visites</th>
                                                <th style={{width:110, textAlign : "center"}} >Nbre Achats</th>
                                                <th style={{width:110, textAlign : "center"}} >Nbre Produits</th>
                                                <th style={{width:120, textAlign : "center"}} >Chiffre Affaire</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.clients.map((item,i)=>
                                          <tr key={i + "_" +  item.clientId} >
                                            <th scope="row" style={{width:30}}> {i + 1} </th>
                                            <td>
                                            <a data-url={`${process.env.PUBLIC_URL}/client/${item.clientId}`}  onClick={(e)=> changePage(e, this)}><b style={{fontSize: 18}}>{item.categorie + " " +  item.etablissement}</b></a> <br />
                                             <i style={{fontSize: 12}}>{"Interlocuteur : " + item.interlocuteur + (item.telephone && ", Telephone : " + item.telephone) + ", Position : " + item.position }</i> <br />
                                             <i style={{fontSize: 12}}>{item.description}</i>
                                            </td>
                                            <td style={{width:120, textAlign : "center", verticalAlign : "middle"}}>{item.zone}</td>
                                            <td style={{width:120, textAlign : "center", verticalAlign : "middle"}}>{item.segment}</td>
                                            <td style={{width:120, textAlign : "center", verticalAlign : "middle"}}>{item.categorie ? item.categorie : "" } </td>
                                            <td style={{width:110, textAlign : "center", verticalAlign : "middle"}}>{item.countVisit ? sMillier(item.countVisit) : "0" } </td>
                                            <td style={{width:110, textAlign : "center", verticalAlign : "middle"}}>{item.countSelling ? sMillier(item.countSelling) : "0" }</td>
                                            <td style={{width:110, textAlign : "center", verticalAlign : "middle"}}>{item.countProduit ? sMillier(item.countProduit) : "0" }</td>
                                            <td style={{width:120, textAlign : "right", verticalAlign : "middle"}} >{item.ca ? sMillier(item.ca) : "0" }</td>
                                          </tr>
                                        )
                                      }
                                    </tbody>
                                  </DataTable>
                                </Col>
                             
                             </Row>)}
                             </Form>
                            </div>
                        </div>
                       </div>
                    </div>               
              </div>                  
          );
      }
  

   
}

const A_PSearch = Form.create()(PSearch);

export default withRouter(A_PSearch);