import React, { Component } from 'react';
import { exeRequest, getAllCategories } from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { Button, Icon, notification, Popconfirm } from 'antd';
import { POLL_LIST_SIZE, STATUS_CODE_REST_API } from '../../constants';
import {cusStatus} from '../../util/Colors';
import { withRouter, Link } from 'react-router-dom';
import DataTable from '../../module/DataTable';
import { changePage } from '../../util/Helpers';
import './V_Liste.css';

class ClientDuplicate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            isLoading: false,
            btnLoading : false
           
        };
        this.loadClientList = this.loadClientList.bind(this);
        this.handlerDuplicate = this.handlerDuplicate.bind(this);
    }

   handlerDuplicate(index){

    const clients = this.state.clients;

    this.setState({
        btnLoading : true
    });

    exeRequest("/clients/handlerDuplicate", "POST", JSON.stringify(clients[index]), function(response){

        let clients = this.state.clients.slice(); 

        this.setState({
            ///clients: clients,
            btnLoading : false
        });

        if(response.statusCode == STATUS_CODE_REST_API[1].code){

            notification.success({
                  message: 'GeoCRM App',
                  description: 'suppression des doublons realisé avec succès ... '
              });    
              
              clients.splice(index,1);

              this.setState({
                clients: clients
            });
           
          }else{
         
              notification.error({
                  message: 'GeoCRM App',
                  description: 'Une erreur est survenue lors de la suppression des  clients ...'
              });  
    
          }
       
      }, this);

   }

    loadClientList() {

        this.setState({
            isLoading: true
        });

        exeRequest("/clients/getDuplicate", "GET", null, function(response){
            //const clients = this.state.clients.slice();          
            this.setState({
                clients: response ? response  : [],
                isLoading: false
            })
           
          }, this);
        
    }

    componentDidMount() {
        this.loadClientList();
        //loadCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.updatedView !== this.state.updatedView) {
            this.loadClientList();
        }
    }

    componentWillReceiveProps({updatedView}) {
        this.setState({ updatedView : updatedView ? updatedView  : this.state.updatedView }); 
   }

           
    render() {
        const clientViews = [];
        console.log(this.state.clients);
        this.state.clients.forEach((client, clientIndex) => {
            //console.log(client);
                //let color  = cusStatus[client.status.toString()]
                //let icon = this.state.catData.length !== 0 ? this.state.catData.find( c => c.id === client.categorieId).icon : "fa-map-marker"

                clientViews.push(
                <tr key={client.id + "____" + clientIndex} >
                    <th scope="row"> {clientIndex + 1} </th>
                    <td> <strong> {client.etablissement ? client.etablissement : "" } </strong></td>
                    <td> {client.zone ? client.zone : "" } </td>
                    <td> {client.segment ? client.segment : "" } </td>
                    <td style={{width:50, textAlign:"center"}}><a onClick={()=> this.props.getData(client.lists)} > {client.counter ? client.counter : "0" } </a></td>
                    {/*<td><img src={"http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + color} style={{height:20}}/></td>*/}
                    <td className="actions">
                    <a onClick={()=> this.props.getData(client.lists)} > <i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i> </a>
                    {/*<Popconfirm
                                  title="Confirmez-vous cette action ?"
                                  onConfirm={() => this.handlerDuplicate(clientIndex)}
                                  okText="OUI"
                                  cancelText="NON" >
                         <Button type="primary" data-coord_lat_reel={client.coord_lat_reel} data-coord_lng_reel={client.coord_lng_reel}  size="small" loading={this.state.btnLoading} icon="check" />
                    </Popconfirm>*/}
                   </td>

                </tr>
           )     
              
        });

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
        
        return (
            <div>
              <DataTable>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Etablissement</th>
                        <th>Quartier</th>
                        <th>Sous-quartier</th>
                       <th>Occurence</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {clientViews}
                    {/*  {this.state.isLoading ? <LoadingIndicator />: null } */}
                 </tbody>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(ClientDuplicate);