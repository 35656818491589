import React, { Component } from 'react';
import {exeRequest} from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import {notification,Button,InputNumber, Popconfirm, Select, Form, Tooltip, Steps, Alert, DatePicker } from 'antd';
//import {colorsActif} from '../../util/Colors';
import {cusStatus, jours, opStatus} from '../../util/Colors';
import { withRouter } from 'react-router-dom';
import { changePage} from '../../util/Helpers';
import {STATUS_CODE_REST_API} from '../../constants';
//import DataTable from '../../module/DataTable';
import './V_Timeline.css';
import moment from 'moment';


const dateFormat = "YYYY-MM-DD";


const { Step } = Steps;
const { Option } = Select;

/*const customDot = (dot, { status, index }) => (
    <Popover
      content={
        <span>
          step {index} status: {status}
        </span>
      }
    >
      {dot}
    </Popover>
  );*/


class V_Timeline extends Component {

    constructor(props) {
        super(props);

        this.state = {
            clients: [],
            zone: null,
            segments : [],
            isLoading: false,
            sCurrent : 0,
            currentUserId : 0,
            cIsLoading : false,
            sIsLoading : false,
            currentZoneId : null,
            currentDate : new Date(),
            client : null,
            btnVisible : false
        };

        this.loadZone = this.loadZone.bind(this);
        this.loadClients = this.loadClients.bind(this);
        this.loadSegments = this.loadSegments.bind(this);
        this.onCommercialChange = this.onCommercialChange.bind(this);
        this.onSegmentChange = this.onSegmentChange.bind(this);
    }

    
    componentDidMount() {
        console.log("In compoments  ------ ", this.state.currentZoneId)
        
        this.setState({ currentZoneId : this.props.zoneId }); 

        if(this.state.currentZoneId){
        this.loadZone();
        this.loadSegments();
        }

    }

    componentWillReceiveProps({zoneId}) {
        this.setState({ currentZoneId : zoneId ? zoneId  : this.state.currentZoneId }); 
   }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentZoneId !== this.state.currentZoneId) {
            this.loadZone();
            this.loadSegments();
        }
    }


    onCommercialChange = (userId) => {
        console.log('onChange:', userId);
        this.setState({ currentUserId : userId });
        this.loadClients(this.state.sCurrent, userId, this.state.currentDate);
        //this.loadZone();
      };

      onDateChange = (e) => {
         console.log("date date ===>> ", e);
         this.setState({currentDate : e._d});
         this.loadClients(this.state.sCurrent, this.state.currentUserId, e._d);
      }


    onSegmentChange = sCurrent => {
        console.log('onChange:', sCurrent);
        this.setState({ sCurrent });
        this.loadClients(sCurrent, this.state.currentUserId, this.state.currentDate);
        //this.loadZone();
      };


    loadZone() {

        let day = new Date().getDay();

        this.setState({
            isLoading: true,
            zone : null,
            clients : [],
            segments : [],
            sCurrent : (day === 0 ? 5 : (day-1)),
            client : null

          });


          exeRequest("/zones/" +  this.state.currentZoneId, "GET", null, function(response){

            this.setState({
                zone: response || null,
                isLoading: false
             });
                
            }, this);


    }


    display_Agents(users){

        var userDisplay = "";
 
        if(users){
        if(users.length > 0){
         for(var i = 0; i < users.length; i++){
              if(i===0){
                 userDisplay += users[i].name;
              }else{
                 userDisplay += " / " +  users[i].name; 
              }
         }
     }else{
         userDisplay = "Aucun";
     }
 }
 
     return userDisplay;
 
 }


 sendRanking = () =>{  

    let {clients} = this.state;

    this.setState({
       btnLoading: true
     });

     let aObj = [];

     clients.filter(x=> x.isUpdated === true).forEach((client) => {
       aObj.push({
           clientId : client.id,
           order : client.position
       })
     });

     console.log(aObj)

   exeRequest("/clients/ranking", "POST", JSON.stringify(aObj), function(response){

       this.setState({
           btnLoading: false
       });
   
       if(response.statusCode == STATUS_CODE_REST_API[1].code){

           notification.success({
               message: 'GeoCRM App',
               description: 'Validation effectuée avec succès ...'
           }); 
           
           this.setState({
               btnVisible : false
           });
       
       }else{

           notification.error({
               message: 'GeoCRM App',
               description: 'Une erreur est survenue, Veuillez ressayer ...'
           });  

       }

   }, this);

 }

    loadSegments(){

        this.setState({
            sIsLoading: true
        });

        exeRequest("/zones/"+ this.state.currentZoneId +"/segments", "GET", null, function(response){

            this.setState({
                segments: response ? response : [],
                sIsLoading: false
            });
          
           this.loadClients(this.state.sCurrent, this.state.currentUserId, this.state.currentDate);
    
            }, this);

   }


  loadClients(cIndex, userId, date){

    this.setState({
        cIsLoading: true
    });

    let segment = this.state.segments[cIndex];

    var url = userId == 0 ? "/segments/"+ segment.id +"/clients" : "/users/"+ userId +"/segments/"+ segment.id +"/clients?date=" + date.toISOString().split("T")[0]

    exeRequest(url, "GET", null, function(response){

        this.setState({
            clients: response ? response : [],
            cIsLoading: false
        });

        }, this);

}

       
render() {
        
     const { segments, sCurrent, zone, isLoading, sIsLoading, cIsLoading, clients, btnVisible, currentUserId} = this.state;
     const {getFieldDecorator} = this.props.form;
     if(!this.state.currentZoneId){
       return(<center className="alert alert-danger" role="alert">Veuillez selectionner une zone !!!</center>);
     }

     return (       
        <div>   
        <div className="card p-main-content">
         <div className="card-body">
           {/*<h5 className="card-title titre_lib"><strong>Détails de la zone :</strong> {zone ? zone.libelle :  ""}</h5>*/} 
             <div className="row">
                <div className="card-body">  
                 {isLoading ? <LoadingIndicator /> :  <MainView zone={zone} that={this} />}                                  
                </div>
              </div>
             </div>
           </div>

            <div className="card"  style={{marginTop:15}}>
                <div className="card-body">

               {sIsLoading ? (!isLoading ? <LoadingIndicator /> : <div></div> ) :  ( <Steps current={sCurrent} onChange={this.onSegmentChange} >
                        {segments.map(sItem=>(
                        <Step key={sItem.ordre} description={sItem.libelle + " (" + sItem.countClients + ")"} title={jours[sItem.ordre -1]} />
                        ))}
                    </Steps>)}  
                     
                </div>
              </div> 

              <div className="card"  style={{marginTop:15}}>
                <div className="card-body">
                
                    {sIsLoading ? (!isLoading ? <LoadingIndicator /> : <div></div> ) : 
                        <div className="row" style={{marginBottom:25}}>
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                                {/*props.zone ? props.that.display_Agents(props.zone.agents) : ""*/}
                                <span className='libcomd'>Commercial : </span>
                                    <Select defaultValue={currentUserId} style={{width : "100%"}} placeholder="TOUS LES COMMERCIAUX" >
                                        <Option onClick={() => this.onCommercialChange(0)} key={0} value={0}>{"TOUS LES COMMERCIAUX"}</Option>
                                        {zone && zone.agents.map(( m, index) => (
                                            <Option onClick={() => this.onCommercialChange(m.id)} key={m.id} value={m.id}>{m.name}</Option>
                                        ))}
                                </Select>
                            </div> 
                            <div className="col-lg-4 col-sm-6 col-xs-12">
                            <span className='libcomd'>Date  : </span>
                                {getFieldDecorator('date', {
                                rules: [{ required: true, message: 'Veuillez choisir une date' }],
                                initialValue : moment(new Date(), dateFormat), 
                                })(<DatePicker onChange={(e) => this.onDateChange(e)} style={{width:"100%"}}  placeholder="Choisir une date"  />)}
                            </div> 
                        </div>
                    }  
                   
                </div>
              </div> 

              {btnVisible && <Popconfirm
                                title="Confirmez vous cette action ?"
                                onConfirm={()=> this.sendRanking()}
                                okText="OUI"
                                cancelText="NON" >
                            <Button type="primary" style={{marginTop : 20, marginBottom : 20}} loading={this.state.btnLoading} htmlType="button" size="large" icon="check" className="pull-right">Valider</Button>
                        </Popconfirm>}

              <div style={{width:'100%', maxHeight : 700, overflowY:'auto', marginTop : 40, minHeight : 200}} className="card" id="historique" >
                    <div className="card-body">
                        <div className="container" >
                        {cIsLoading ? (!sIsLoading && <LoadingIndicator /> ) :  <TimeLineView clients={clients} that={this} />}                
                        </div>
                    </div>
               </div>

              
         </div> 
        );
    }
}


function MainView(props){
   return(      
        <div className="row">
            <div className="col-md-12">
            
            <div className="row" style={{marginBottom:25}}>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                <span className='libcomd'>Code : </span> {props.zone ? props.zone.code : "" }
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                <span className='libcomd'>Nbre segments :</span> {props.zone ? props.zone.countSegments : ""}
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                 <span className='libcomd'>Nbre Clients : </span> 
                 {props.zone ? 
                 <>
                 <span>{props.zone.countClients.total }{" ("}</span>
                 <Tooltip placement="topRight" title={"Nombre de clients actifs"} ><b style={{color:"green", cursor:"pointer"}}>{props.zone.countClients.countActif}</b></Tooltip>
                 <span>{" / "}</span>
                 <Tooltip placement="topRight" title={"Nombre de clients inactifs visités"} ><b style={{color:"orange", cursor:"pointer"}}>{props.zone.countClients.countInactifVisite}</b></Tooltip>
                 <span>{" / "}</span>
                 <Tooltip placement="topRight" title={"Nombre de clients inactifs non visités"} ><b style={{color:"red", cursor:"pointer"}}>{props.zone.countClients.countInactifNonVisite}</b></Tooltip>
                 <span>{" / "}</span>
                 <Tooltip placement="topRight" title={"Nombre de clients desactivés"} ><b style={{color:"black", cursor:"pointer"}}>{props.zone.countClients.countDesactive}</b></Tooltip>
                 <span> {")"}</span></> : ""}
                </div>

                
                            
            </div>

            <div className="row" style={{marginBottom:25}}>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                    <span className='libcomd'>Libellé : </span> {props.zone ? props.zone.libelle : "" }
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                    <span className='libcomd'>Commercial : </span>
                    {props.zone ? props.that.display_Agents(props.zone.agents) : ""}
                </div>

                <div className="col-lg-4 col-sm-6 col-xs-12">
                    <span className='libcomd'>Routing : </span>{props.zone ? props.zone.routing : ""}
                </div>

                </div>
             </div>
          </div>
           )
}

class TimeLineView extends Component {

    constructor(props){
        super(props);
        this.state={
            btnLoading: false,
            btnVisible : false,
            parent : this.props.that
        }
    }

     
     onConfirm=(index)=>{

        let {parent} = this.state;
        let {clients} = parent.state;
        console.log(document.getElementById("input_number_" + index).value);
        clients[index].position = document.getElementById("input_number_" + index).value;
        clients[index].isUpdated = true;
        parent.setState({
          clients : clients,
          btnVisible : true
          });

     }
    
  componentDidMount() {}


  render(){

    const Views = [];
    let {parent, btnVisible} = this.state;
    let {clients, currentUserId} = parent.state;

    if(clients.length > 0){
        console.log("clients ====>>>> ", clients);

        clients.sort(function(a, b){return a.position-b.position}).forEach((client, clientIndex) => {
            let  elt = "";
            let selt = "";

            let clientCodeCouleur = currentUserId == 0 ? cusStatus[client.estDesactive ? "-1" : client.status.toString()] : opStatus[client.estDesactive ? "-1" : client.opStatus.toString()];

            if(clientIndex % 2 === 0){

                elt = (<div key={clientIndex + "__"+ client.id +"__" + new Date().getTime()} className="row align-items-center how-it-works d-flex">
                            <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
                              <Popconfirm
                                    icon={null}
                                    title={(<InputNumber id={"input_number_" + clientIndex} defaultValue={client.position}  step={1} max={clients.length} style={{width: "100%"}} min={1}  />)}
                                    onConfirm={()=> this.onConfirm(clientIndex)}
                                    okText="OUI"
                                    cancelText="NON" >
                                    <div className="circle font-weight-bold" style={{backgroundColor: "#" + clientCodeCouleur, cursor:"pointer"}} >                           
                                       <a href="#" style={{color:"white"}}>{client.position}</a>
                                  </div>
                              </Popconfirm>
                            </div>
                            <div className="col-6">
                                <h5><a data-url={`${process.env.PUBLIC_URL}/client/${client.id}`}  onClick={(e)=> changePage(e, parent)} href="#" >{client.etablissement} </a><small style={{fontSize : "13px", fontWeight : "bolder"}}>(lng : {client.coord_lng_reel} , lat : {client.coord_lat_reel})</small></h5>
                                <p><strong>Interlocuteur :</strong> {client.interlocuteur} | <strong>Telephone : </strong>{client.telephone}</p>
                                <p><strong>Dernière operation : </strong>{client.lastOperation ? new Date(client.lastOperation).toLocaleString("fr-FR") : "Jamais"} </p>
                            </div>
                        </div>)

                        if((clientIndex + 1) !== clients.length){
                            selt  = (<div key={"row___Col__"+ client.id +"__" + clientIndex + "___" + new Date().getTime()} className="row timeline">
                                    <div className="col-2">
                                    <div className="corner top-right"></div>
                                    </div>
                                    <div className="col-8">
                                    <hr/>
                                    </div>
                                    <div className="col-2">
                                    <div className="corner left-bottom"></div>
                                    </div>
                                </div>)
                        }

            }else{

                elt  = (<div key={clientIndex + "__"+ client.id +"___" + new Date().getTime()} className="row align-items-center justify-content-end how-it-works d-flex">
                            <div className="col-6 text-right">
                                 <h5><a data-url={`${process.env.PUBLIC_URL}/client/${client.id}`}  onClick={(e)=> changePage(e, parent)} href="#" >{client.etablissement} </a><small style={{fontSize : "13px", fontWeight : "bolder"}}>(lng : {client.coord_lng_reel} , lat : {client.coord_lat_reel})</small></h5>
                                <p><strong>Interlocuteur :</strong> {client.interlocuteur} | <strong>Telephone : </strong>{client.telephone}</p>
                                <p><strong>Dernière operation : </strong>{client.lastOperation ? new Date(client.lastOperation).toLocaleString("fr-FR") : "Jamais"} </p>
                            </div>
                            <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                            <Popconfirm
                                    icon={null}
                                    title={(<InputNumber id={"input_number_" + clientIndex} defaultValue={client.position}  step={1} max={clients.length} style={{width: "100%"}} min={1}  />)}
                                    onConfirm={()=> this.onConfirm(clientIndex)}
                                    okText="OUI"
                                    cancelText="NON" >
                                    <div className="circle font-weight-bold" style={{backgroundColor: "#" + clientCodeCouleur, cursor:"pointer"}} >                           
                                       <a href="#" style={{color:"white"}}>{client.position}</a>
                                  </div>
                              </Popconfirm>
                            </div>
                        </div>)

                        if((clientIndex + 1) !== clients.length){

                            selt  =  (<div key={"row___Col__" + client.id + "__" + clientIndex + "___" + new Date().getTime()} className="row timeline">
                                        <div className="col-2">
                                        <div className="corner right-bottom"></div>
                                        </div>
                                        <div className="col-8">
                                        <hr/>
                                        </div>
                                        <div className="col-2">
                                        <div className="corner top-left"></div>
                                        </div>
                                    </div>)
                        }
            }
            
            Views.push(elt)    
            Views.push(selt) 

              
        });

    }else{
        Views.push( <Alert
            message="Liste des utilisateurs"
            description="Aucune donnée a afficher dans cette vue "
            type="info"
            showIcon
          />)
    }

    return Views;

  }



}

const A_V_Timeline = Form.create()(V_Timeline)

export default withRouter(A_V_Timeline);