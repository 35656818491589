import React, { Component } from 'react';
import { exeRequest, getAllCategories } from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { Button, Icon, notification } from 'antd';
import { POLL_LIST_SIZE } from '../../constants';
import {cusStatus} from '../../util/Colors';
import { withRouter, Link } from 'react-router-dom';
import DataTable from '../../module/DataTable';
import { changePage, sMillier } from '../../util/Helpers';
import DDataTable from '../../module/DDataTable';
import { CLIENT_LIST_SIZE } from '../../constants';
import './V_Liste.css';

class ClientList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            catData : [],
            cDashbord : null
        };
       this.loadCategories = this.loadCategories.bind(this);
    }


  loadCategories() {

        this.setState({
            isLoading: true
          });
  

    exeRequest("/categories", "GET", null, function(response){

            this.setState({
                catData : response || [],
                isLoading: false
            })

            this.loadDashbord();
        
        }, this);
   
        
    }

    loadDashbord = () => {

        this.setState({
            isLoading: true
          });
  

       exeRequest("/reportings/cdashbord", "GET", null, function(response){

            this.setState({
                cdashbord : response || [],
                isLoading: false
            });
        
        }, this);
           
    }

    componentDidMount() {
      this.loadCategories();
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                catData: [],
                isLoading: false
            });    

            this.loadCategories();
        }
    }

         
    render() {

        const mapLegende = [];

        this.state.catData.forEach((cat, catIndex) => {
            console.log(cat);
            mapLegende.push(
                <li key={cat.id} > <i className={`fa far ${cat.icon}`}> </i> { cat.libelle }</li>
           )     
              
        });


        const columns = [
            {
              title: '#',
              key: '#',
              render: (text, record) => (
                <i className={`fa far ${record.categorieIcon ? record.categorieIcon : "" } fa-map-marker bgr`}  style={{backgroundColor: "#" + cusStatus[record.estDesactive ? "0" : record.status.toString()]}} ></i>          
              ),
            },
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: 'Etablissement',
                dataIndex: 'etablissement',
                key: 'etablissement',
            },
            {
                title: 'Catégorie',
                dataIndex: 'categorieLib',
                key: 'categorieLib',
            },
            {
                title: 'Quartier',
                dataIndex: 'zoneLib',
                key: 'zoneLib',
            },
            {
                title: 'Sous-quartier',
                dataIndex: 'segmentLib',
                key: 'segmentLib',
            },
            {
                title: 'Interlocuteur',
                dataIndex: 'interlocuteur',
                key: 'interlocuteur',
            },
            {
                title: 'Telephone',
                dataIndex: 'phone',
                key: 'phone',
            },
            {
               title: 'Actions',
               className:'actions',
               key: 'actions',
               width: 50,
                render: (text, record) => (
                  <div>
                     <a data-url={`${process.env.PUBLIC_URL}/client/${record.id}`}  onClick={(e)=> changePage(e, this)}  style={{cursor: "pointer"}}  ><i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i></a>&nbsp;&nbsp;&nbsp;
                  </div>
                ),
              },
          ];
    
        return (
            <div className="row">
                <div className="col-md-12 col-lg-12 col-12" >
                    <ul className="mapLegende">{mapLegende}</ul>
                </div>
               <div className="col-md-12 col-lg-12 col-12" style={{marginTop : "20px", marginBottom: "20px"}}>
               <center className="row">
                   <div className="col-lg-3 col-sm-6 col-xs-12">
                       <Button type="primary"  icon="usergroup-delete" size="large" style={{height:"80px", marginBottom: "5px", minWidth: "300px", backgroundColor: "#" + cusStatus[1], borderColor : "#" + cusStatus[1]}}>{this.state.cdashbord ?  sMillier(this.state.cdashbord.countActif) : 0} <br/> Clients actifs</Button>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                      <Button type="primary"  icon="usergroup-delete" size="large" style={{height:"80px", marginBottom: "5px",  minWidth: "300px" , backgroundColor: "#" + cusStatus[2], borderColor : "#" + cusStatus[2] }}>{this.state.cdashbord ?  sMillier(this.state.cdashbord.countInactif) : 0} <br/> Clients inatifs visités</Button>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                     <Button type="primary"  icon="usergroup-delete" size="large" style={{height:"80px", marginBottom: "5px", minWidth: "300px" , backgroundColor: "#" + cusStatus[3], borderColor : "#" + cusStatus[3] }}>{this.state.cdashbord ?  sMillier(this.state.cdashbord.countInactifNonVisited) : 0} <br/> Clients inatifs non visités</Button>       
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                     <Button type="primary"  icon="usergroup-delete" size="large" style={{height:"80px", marginBottom: "5px", minWidth: "300px" , backgroundColor: "#" + cusStatus[0], borderColor : "#" + cusStatus[0] }}>{this.state.cdashbord ?  sMillier(this.state.cdashbord.countDesactive) : 0} <br/> Clients desactivés</Button>       
                    </div>
                  </center>
               </div>
                <div className="col-md-12 col-lg-12 col-12" style={{marginTop:20}}>
                    <DDataTable size={"middle"} pageSize={CLIENT_LIST_SIZE} pagination={{pageSize : 10, position : "top"}}  url={"/clients/search"} pageSizeOptions={['100', '200', '300', '400', '500', '1000']} columns={columns}  />
                </div>
            </div>
        );
    }
}

export default withRouter(ClientList);