import React, { Component } from 'react';
import { Upload, Icon, Modal, message } from 'antd';
import "./FileUpload.css";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class FileUpload extends React.Component {
  
  state = {
    previewVisible: false,
    previewImage: '',
    fileList: [],
  };

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
    });
  };

  handleChange = ({ fileList }) => {
      this.setState({ fileList });
      this.props.handleChangeFile(fileList);
  }

  beforeUpload = (file) =>  {
    this.props.beforeUploadFile(file);
    return false;
  }


  componentWillReceiveProps({fileList}) {
    this.setState({ fileList : fileList ? fileList  : this.state.fileList }); 
    /*this.setState({ currentItem : currentItem }); 
    this.setState({ files : [{url : GET_PRODUCTS_FILES_CTR + currentItem.sku + ".png"}] }); */
  }

  /*getFiles = (fileList) =>{
    console.log(fileList);
  }*/

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">{this.props.label}</div>
      </div>
    );
    return (
      <div className="clearfix">
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          beforeUpload={this.beforeUpload}
          >
          {fileList.length > 0 && !this.props.multiple  ? null : uploadButton}
        </Upload>
        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default FileUpload;
