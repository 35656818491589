import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, message, Icon, InputNumber,notification,Radio } from 'antd';
import { exeRequest } from '../../../util/APIUtils';
import  FileUpload from  '../../../module/FileUpload';
import { GET_USERS_FILES_CTR,STATUS_CODE_REST_API, STATUS } from '../../../constants';

const { Option } = Select;

class DrawerForm extends Component {

  constructor(props) {

    super(props);
    this.state = {
        visible: false,
        currentItem : null,
        btnLoading : false,
        roles : [],
        files  : [] 
    };
    
  }

 componentWillReceiveProps({visible, currentItem}) {
  this.setState({ visible : visible ? visible  : this.state.visible }); 
  this.setState({ currentItem : currentItem }); 
  //console.log("testeur de maggie ", currentItem)
  //if(cu)
  this.setState({ files : currentItem && this.state.files.length == 0 ? [{url : GET_USERS_FILES_CTR + currentItem.username + ".png",uid: currentItem.id, name: 'image.png',status: 'done' }] : this.state.files }); 
   }

   //this.loadMarque=this.loadMarque.bind(this)
  
  componentDidMount() {
    this.setState({ visible : false }); 
  }
  
  componentDidUpdate(prevProps, prevState) {

    if (prevState.visible !== this.state.visible) {
      if(this.state.visible && this.state.roles.length==0){this.loadRole()}
      if(this.state.visible){this.props.form.resetFields()}
    }

  }

  loadRole= () =>  {

    this.setState({
        isLoading: true
      });

      exeRequest("/roles", "GET", null, function(response){
        this.setState({
            roles: (response ? response : []),
            isLoading: false
        });
      }, this) 
  }

 /* onClose = () => {
    this.setState({
      visible: false,
    });
  };*/

  handleChangeFile = (fileList) =>{
    console.log("file console ", fileList)
     this.setState({
       files : fileList
     });
 }
 
 beforeUploadFile = (file) => {
  
   }

   compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
        callback('les mots de passe ne correspondent pas');
        //this.setState({submitDisabled: true});
    }else {
       // this.setState({submitDisabled: false});
        callback();
    }
}
 
   onClose = () => {
     //console.log("cool", cool);
     //this.onClose();
     this.setState({
       visible: false,
       btnLoading: false,
       currentItem : null,
       files : []
     });
     
   };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) { 
        //this.setState({ btnLoading: true });
        //if(this.state.files.length > 0){
        let objRequest = Object.assign({}, values);

        if(this.state.files.length > 0){
          const jPhoto = {photo : this.state.files[0].status !== "done" ? this.state.files[0].thumbUrl.split(",")[1] : null};
          objRequest = Object.assign({}, jPhoto, objRequest);
        }               
        console.log("test ", objRequest);
        ///return false;
        this.setState({
          btnLoading: true
        })
        
        
      exeRequest("/users", (objRequest.id && objRequest.id !== 0 ? "PUT" : "POST") , JSON.stringify(objRequest), function (response) {

            if (response.statusCode == STATUS_CODE_REST_API[1].code) {
              notification.success({
                message: 'GeoCRM App',
                description: 'utilisateur enregistré avec succès'
              });

              this.onClose();
              window.location.reload(true);
              //window.location.href = process.env.PUBLIC_URL + "/param/users/list"

            }else if(response.statusCode == STATUS_CODE_REST_API[3].code) {
              
              notification.error({
                message: 'GeoCRM App',
                description: response.message,
                btnLoading:false
                
              });

            } else {

            notification.error({
              message: 'GeoCRM App',
              description: "Erreur lors de l'enregistrement de l'utilisateur ",
              btnLoading:false
              
            });
          
          }

        }, this)
        
    }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
       <Drawer
          title="Ajout d'utilisateur "
          width={720}
          background={'#ff676'}
          onClose={this.onClose}
          visible={this.state.visible}
        >
           
          <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

            {getFieldDecorator('id', {
              initialValue : this.state.currentItem ? this.state.currentItem.id : null,           
            })(<Input type="hidden"   />)}


            {getFieldDecorator('status', {
              initialValue : this.state.currentItem ? this.state.currentItem.status : STATUS[0].value,          
            })(<Input type="hidden"   />)}

          <Row gutter={16}>
              <Col span={24} style={{marginBottom:20}}>
                <FileUpload multiple={false} label={"Choisir son avatar"} beforeUploadFile={this.beforeUploadFile}  handleChangeFile={this.handleChangeFile} fileList={this.state.files}  />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Code">
                  {getFieldDecorator('code', {
                      rules: [{ required: true, message: 'Veuillez saisir le code  !' }],
                      initialValue : this.state.currentItem ? this.state.currentItem.code : "",     
                    })(<Input placeholder="Saisissez le code de l'utilisateur" maxLength={10} />)
                  }
                </Form.Item>
              </Col>
            </Row>
         


            <Row gutter={16}>
              <Col span={24}>
                  <Form.Item label="Nom complet">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: 'Veuillez saisir votre nom complet !' }],
                      initialValue : this.state.currentItem ? this.state.currentItem.name : "",  
                    })(
                      <Input placeholder="Saisissez le nom complet" minLength={4} maxLength={40}/>)}
                  </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                  <Form.Item label="Login">
                    {getFieldDecorator('username', {
                      rules: [{ required: true, message: 'Veuillez saisir votre login !' }],
                      initialValue : this.state.currentItem ? this.state.currentItem.username : "",  
                    })(
                      <Input disabled={this.state.currentItem ? true : false} placeholder="Saisissez le login"  minLength={3} maxLength={15} />)}
                  </Form.Item>
              </Col>

              <Col span={12}>
                  <Form.Item label="Email">
                    {getFieldDecorator('email', {
                      rules: [
                        {type: 'email', message: "Votre saisie n'est pas un email valid"},
                        { required: true, message: "Veuillez saisir l'email !" }
                      ],
                      initialValue : this.state.currentItem ? this.state.currentItem.email : "",  
                    })(
                      <Input
                        prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="saisissez l'email" maxLength={40}
                        disabled={this.state.currentItem ? true : false}
                      />,
                    )}
                  </Form.Item>
              </Col>
            </Row>

            {!this.state.currentItem ?  (<Row gutter={16}  >
              <Col span={12}>
                  <Form.Item label="Mot de passe">
                    {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Veuillez saisir votre mot de passe ' }],
                    })(
                      <Input.Password  prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  placeholder="Saisissez le mot de passe" minLength={3} maxLength={20}/>,
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Repetez le mot de passe">
                    {getFieldDecorator('rpassword', {
                      rules: [{ required: true, message: 'Veuillez saisir votre mot de passe ' }, 
                      {validator: this.compareToFirstPassword}]
                    })(
                      <Input.Password prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}  placeholder="Repetez mot de passe" minLength={3} maxLength={20}/>,
                    )}
                  </Form.Item>
                </Col>
            </Row>) : (<div></div>) }
         

            <Row gutter={16}>

              <Col span={12}>
                <Form.Item label="Contact">
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Veuillez saisir le contact ' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.phone : "",  
                  })(<Input placeholder="Saisissez le contact"  minLength={8} maxLength={11}/>)}
                </Form.Item>
              </Col>

              <Col span={12} >
                <Form.Item label="Role : ">
                {getFieldDecorator('roleId', {
                    rules: [{ required: true, message: 'Veuillez selectionner le role' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.roleId : ""
                  })(
                    <Select placeholder="Veuillez selectionner le role">
                         {this.state.roles.map(( m, index) => (
                         <Option key={m.id} value={m.id}>{m.name}</Option>
                          ))}
                    </Select>
                  )}
                   </Form.Item>
                </Col>
            </Row>
         
          <div
           style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            padding: '10px 16px',
            background: '#fff',
            textAlign: 'right',
          }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button   loading={this.state.btnLoading} htmlType="submit" type="primary">
              Enregistrer
            </Button>
          </div>

          </Form>

        </Drawer>
      </div>
    );
  }

}

const ADrawerForm = Form.create()(DrawerForm);

export default ADrawerForm;

