import React, { Component } from 'react';
import { exeRequest } from '../../util/APIUtils';
import {sMillier} from '../../util/Helpers';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { DatePicker, Alert} from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
//import './V_Profile.css';

const dateFormat = "DD/MM/YYYY"


class V_Stats_Users extends Component {

    constructor(props) {

        super(props);

        var date = new Date();
        this.state = {
            data : null,
            userId : this.props.userId,
            isLoading: false,
            dataDetails : null,
            numberUpdated :  this.props.numberUpdated,
            sdate :  new Date(date.getFullYear(), date.getMonth(), 1).toISOString().split("T")[0],
            edate : new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString().split("T")[0]
        };

        //this.loadUsersList = this.loadUsersList.bind(this);
        //this.handleLoadMore = this.handleLoadMore.bind(this);
        //this.loadCategories = this.loadCategories.bind(this);
    }

    componentWillReceiveProps({userId, numberUpdated}) {   
        this.setState({ userId: userId !== null ? userId :  this.state.userId });
        this.setState({ numberUpdated : numberUpdated !== null ? numberUpdated :  this.state.numberUpdated });   
        console.log("V_Stats_Users number _____________________ " + numberUpdated);      
    }

    loadUserInfos(sdate, edate) {

        this.setState({
            isLoading: true
          });

          sdate = sdate ? sdate : this.state.sdate
          edate = edate ? edate : this.state.edate

          exeRequest("/reportings/udashbord/"+ this.state.userId +"/?startdate="+ sdate +"&endate=" + edate, "GET", null, function(response){

            this.setState({
                data: (response ? response : null),
                isLoading: false
            })


          }, this);        
    }


    componentDidUpdate(prevProps, prevState) {
        if ((prevState.userId !== this.state.userId)
        || (prevState.numberUpdated !== this.state.numberUpdated)) {
             this.loadUserInfos();
             this.getUserDetails();
         }
     }


    componentDidMount() {
        this.loadUserInfos();
        this.getUserDetails();
        // this.loadTypes();
       // if(this.props.userId) 
     }

 
    /* componentDidUpdate(nextProps) {
        if(this.props.userId !== nextProps.userId) {
             // Reset State
             /*this.setState({
                 data : null,
                 isLoading: false
             }); 
             if(this.props.userId) this.loadUserInfos();
         }
     }*/


    dateTimeChange = (e) => {
        console.log(new Date(e[0]._d).toISOString());

        this.setState({
            sdate : new Date(e[0]._d).toISOString().split("T")[0],
            edate : new Date(e[1]._d).toISOString().split("T")[0]
        }); 

        this.loadUserInfos(new Date(e[0]._d).toISOString().split("T")[0], new Date(e[1]._d).toISOString().split("T")[0]);
        this.getUserDetails(new Date(e[0]._d).toISOString().split("T")[0], new Date(e[1]._d).toISOString().split("T")[0]);
    }

    getUserDetails = (sdate, edate) =>{

        this.setState({
            dataDetails: null
          });

          sdate = sdate ? sdate : this.state.sdate
          edate = edate ? edate : this.state.edate

          exeRequest("/users/"+ this.state.userId +"/sellingDashboard/?startdate="+ sdate +"&endate=" + edate, "GET", null, function(response){

            this.setState({
                dataDetails: (response ? response : null)
            })

          }, this); 
    }


      render() {

        const { data, isLoading, dataDetails} = this.state;

        const {RangePicker} = DatePicker;


       /* if(isLoading) {
            return <LoadingIndicator />
        }
        if(!data){
            return ( <Alert
                message="Statistique Utilisateur"
                description="Utilisateur introuvable dans la base de données."
                type="info"
                showIcon
              />)
            //return <div>Utilisateur introuvable dans la base de données</div>
        }

           const zoneHtml =   data.user.zones.map(function(zone){
            <span className="tags">zone.libelle</span> 
        }); */

        return (
            <div>

                  <center className="row text-center">
                       <div className="col-xs-12 col-sm-12 col-lg-12" style={{margin : "15px"}} >
                           <RangePicker defaultValue={[moment(new Date(this.state.sdate), dateFormat), moment(new Date(this.state.edate), dateFormat)]} onChange={this.dateTimeChange}> </RangePicker>
                        </div>
                    </center>

                  {isLoading ? <LoadingIndicator /> :  (data ? (<div className="row divider text-center">

                        <div className="col-xs-4 col-sm-4 col-lg-4 emphasis">
                            <h2><strong> {data.countVisit ? data.countVisit : "0" } </strong></h2>                    
                            <p><small>visite(s)</small></p>
                            <button className="btn btn-success btn-block"><span className="fa fa-tasks"></span> VISITE </button>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-lg-4 emphasis">
                            <h2><strong>{ data.amountSelling ? sMillier(data.amountSelling)  : "0" } / { data.countSelling ? data.countSelling : "0" }</strong></h2>                    
                            <p><small>FCFA</small></p>
                            <button className="btn btn-info btn-block"><span className="fa fa-shopping-cart"></span> VENTE </button>
                        </div>

                        <div className="col-xs-4 col-sm-4 col-lg-4 emphasis">
                            <h2><strong>{ data.amountInStocks ? sMillier(data.amountInStocks) : "0" }</strong></h2>                    
                            <p><small>FCFA</small></p>
                            <button className="btn btn-warning btn-block" style={{color: "white"}}><span className="fa fa-money"></span> STOCK </button>
                        </div>
                   </div>) : <Alert message="Statistique Utilisateur" description="Utilisateur introuvable dans la base de données." type="info" showIcon />)
                  }

               {dataDetails && dataDetails.length > 0 && (<div>
               <hr />
                <table className="table table-bordered table-striped" >
                    <thead>
                        <tr>
                            <th>Produit</th>
                            {/*<th>Commercial</th>*/}
                            <th style={{width : 120}} >Qte en items </th>
                            <th style={{width : 120}} >Qte en Carton </th>
                            <th style={{width : 120}} >CA</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                      dataDetails.map((item) =>{
                        return (<tr>
                            <td>{item.produitName}</td>
                            <td>{sMillier(item.count)}</td>
                            <td>{sMillier(item.count / item.qteCarton)}</td>
                            <td style={{textAlign:"right"}}>{sMillier(item.ca)}</td>
                        </tr>)
                        })
                    }
                </tbody>
                </table>
                </div>)
                }

           </div>
        );
    }
    
}

export default withRouter(V_Stats_Users);