


import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
      <div className="footer">
         <div className="container-fluid">
            <div className="row justify-content-lg-center">Copyright © 2019 | GeoCRM Prosuma Côte d'Ivoire</div>
          </div>
        </div>
        );
    }
}

export default Footer;

