import React, { Component } from 'react';
import { exeRequest } from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { Input,InputNumber, Form, notification, Button, Popconfirm, Alert, Tabs, Avatar } from 'antd';
import { API_BASE_URL,GET_PRODUCTS_FILES_CTR, STATUS_CODE_REST_API } from '../../constants';
/*import {cusStatus} from '../../util/Colors';*/
import { withRouter } from 'react-router-dom';
import DataTable from '../../module/DataTable';

const { TabPane } = Tabs;

class V_Stock_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            stocks : [],
            isLoading: false,
            btnLoading : false,
            userId : this.props.userId,
            numberUpdated : this.props.numberUpdated 
        };
        this.validateStock = this.validateStock.bind(this);
        this.onQteChange = this.onQteChange.bind(this);
        this.loadVStocks = this.loadVStocks.bind(this);
        this.loadStocks = this.loadStocks.bind(this);
    }


    loadVStocks() {

        this.setState({
            isLoading: true
          });

          exeRequest("/users/" + this.state.userId + "/get/vstocks/0", "GET", null, function(response){
           
            this.setState({
                lists: response ? response : []
                //isLoading: false,
                //btnLoading : false
            });

            this.loadStocks(); 


          }, this)
        
    }

    loadStocks() {

        this.setState({
            isLoading: true
          });

          exeRequest("/users/" + this.state.userId + "/get/stocks", "GET", null, function(response){
           
            this.setState({
                stocks: response ? response : [],
                isLoading: false,
                btnLoading : false
            });


          }, this)
        
    }

    onQteChange(e, index){
      //alert(e + "_"  + index);
      const lists = this.state.lists;
      lists[index].qte = e
      this.setState({ lists: lists });       
    } 

    validateStock(){

         this.setState({
            btnLoading: true
          });

          const lists = this.state.lists;

          var obj = {
              vstocks : lists
          }

          console.log(obj);

        exeRequest("/users/"+ this.state.userId +"/validate/vstocks", "POST", JSON.stringify(obj), function(response){

            if(response.statusCode == STATUS_CODE_REST_API[1].code){

                notification.success({
                    message: 'GeoCRM App',
                    description: 'Stock de '+ lists[0].userName +' Validé avec succès ...'
                });            
             
                //console.log()
               this.props.forceUpdate(true);

            }else{

              this.setState({
                    btnLoading: false
                });

                notification.error({
                    message: 'GeoCRM App',
                    description: 'Une erreur est survenue lors de la validation du stock'
                });  

            }

          }, this);
    }
   
    componentDidMount() {
        this.loadVStocks();
        //loadCategories();
    }

    componentDidUpdate(prevProps, prevState) {
       if ((prevState.userId !== this.state.userId) 
       || (prevState.numberUpdated !== this.state.numberUpdated)) {
            this.loadVStocks();
        }
    }

    componentWillReceiveProps({userId, numberUpdated}) {   
          this.setState({ userId: userId !== null ? userId :  this.state.userId });   
          this.setState({ numberUpdated : numberUpdated !== null ? numberUpdated :  this.state.numberUpdated });  
          console.log("__________________ Stock list _______" + numberUpdated)     
      }
    
    render() {
               

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
   

       return (
        <Tabs style={{marginTop:30}}  tabPosition={"left"}>
            <TabPane forceRender={true} tab={"Stock à valider"} key={1}>
                <MainView  that={this} />
            </TabPane>
            <TabPane forceRender={true} tab={"Stock actuel"} key={2}>
                <MainViewStocks that={this} />
            </TabPane>
          </Tabs> 
          );

    }
   
}


function MainView(props){
   
    let that = props.that;

    const { getFieldDecorator } = that.props.form;

    const Views = [];

    console.log("LOGGER", that.state.lists);

    that.state.lists.forEach((item, index) => {
           Views.push(
            <tr key={item.id} >
                <th scope="row" >
                 {/*<img width="100%"  src={`${GET_PRODUCTS_FILES_CTR + item.produitSku}.png`}  />*/}
                 <Avatar size={100} src={`${GET_PRODUCTS_FILES_CTR + item.produitSku}.png`}  icon="picture" /> 
               </th>
                <td> <strong>{item.produitName ? item.produitName : "" } </strong></td>
                <td>
                <Form.Item label="" style={{marginBottom:0}}>
                    {getFieldDecorator('qte_'  + index, {initialValue: (item.qte ? item.qte : 0) })(<InputNumber step={item.produitQte/item.cartonQuantite} onChange={(e) => that.onQteChange(e, index) } style={{width: "100%"}} min={0}  />)}
                    <span className="ant-form-text"></span>
                </Form.Item>
                </td>
                <td> {item.qte ? Math.round(item.qte * item.cartonQuantite) : "0" }</td>
           </tr>
       )     
          
    });

    if(that.state.lists.length === 0){
        return ( <Alert
              message="Validation de stock"
              description="Aucun stock a valider pour cet utilisateur."
              type="info"
              showIcon
            />)
        
     }

   return ( <div>
        <div>
        <table className="table table-bordered table-striped" >
                <thead>
                    <tr>
                        <th style={{width : 100}} >#</th>
                        <th>Produit</th>
                        {/*<th>Commercial</th>*/}
                        <th style={{width : 200}} >Qte en Carton </th>
                        <th style={{width : 150}} >Qte en items </th>
                        {/*<th>Commercial</th>*/}
                    </tr>
                </thead>
                <tbody>
                    {Views}
                </tbody>
            </table>
            <Popconfirm
                    title="Confirmez-vous cette action ?"
                    onConfirm={()=> that.validateStock()}
                    okText="OUI"
                    cancelText="NON" >
                <Button type="primary" style={{marginTop : 20}} loading={that.state.btnLoading} htmlType="button" size="large" icon="check" className="pull-right">Valider</Button>
            </Popconfirm> 
       </div>
 </div>);

}


class MainViewStocks extends Component {


    constructor(props){
        super(props);
        this.state={
            btnLoading: false,
            value : 1,
            index : -1,
            parent : this.props.that,
            lists : []
        }
    }


 /* onQteChange = (e, index) => {
    this.setState({value: e});       
  } 
  onEdit = (item, index) =>{
    this.setState({value: (item.vstock ? item.vstock : item.stock), index:index});        
  }
  onConfirm = () =>{
    const lists = this.state.lists;
    console.log("XXXXXX value ===>", this.state.value);
    console.log("XXXXXX index ===>", this.state.index);
    //lists[this.state.index].stock = this.state.value;
    lists[this.state.index].vstock = ((lists[this.state.index].vstock ? lists[this.state.index].vstock : lists[this.state.index].stock) - this.state.value);
    this.setState({lists: lists});       

  }*/


  componentDidMount() {

    let {parent} = this.state;

    this.setState({
     lists : parent.state.stocks
    });

  }


  onConfirm = (produitId) =>{  

     let {parent} = this.state;

     console.log("produitId ====>>> " + produitId)

     this.setState({
        btnLoading: true
      });

    exeRequest("/users/" + parent.state.userId + "/clear/" + produitId +  "/stocks", "GET", null, function(response){
    
    this.setState({
        btnLoading : false
    });

    parent.props.forceUpdate();


    }, this);

  }



   render(){

        const Views = [];

        this.state.lists.forEach((item, index) => {
            Views.push(
                <tr key={item.stockId + "__" + index} >
                    <th scope="row" >
                    <Avatar size={100} src={`${GET_PRODUCTS_FILES_CTR + item.sku}.png`}  icon="picture" /> 
                        {/*<img  width="100%"  src={`${GET_PRODUCTS_FILES_CTR + item.sku}.png`}  />*/} 
                    </th>
                    <td> <strong>{item.produitName ? item.produitName : "" } </strong></td>
                    {/*<td>  {item.userName ? item.userName : "" } </td>*/}
                    <td>{item.vstock ? (item.vstock / item.cartonQuantite) : (item.stock ? (item.stock / item.cartonQuantite) : "0") } </td>
                    <td> {item.vstock ? item.vstock  : (item.stock ? item.stock : "0") }</td>
                    <td>
                    <Popconfirm
                    title={`Voulez vous vider le stock de ${item.produitName} ?`} 
                    onConfirm={()=> this.onConfirm(item.produitId)}
                    okText="OUI"
                    cancelText="NON" >
                        <Button type="primary" style={{marginTop : 20}} loading={this.state.btnLoading} htmlType="button" size="large" icon="delete" className="pull-right"></Button>
                    </Popconfirm>
                    </td>
            </tr>
        )     
            
        });

        if(this.state.lists.length === 0){
            
            return(<Alert
                message="Chargement de stock"
                description="Cet utilisateur n'a pas de stock disponible ... "
                type="info"
                showIcon
                />)
            
        }

    return (<div>                     
            <div>
                <table className="table table-bordered table-striped" >
                <thead>
                    <tr>
                        <th style={{width : 100}}>#</th>
                        <th>Produit</th>
                        {/*<th>Commercial</th>*/}
                        <th style={{width : 120}} >Qte en Carton </th>
                        <th style={{width : 120}} >Qte en items </th>
                        <th style={{width : 120}} > Actions </th>
                    </tr>
                </thead>
                <tbody>
                {Views}
               </tbody>
            </table> 

            {<Popconfirm
                    title="Voulez vous vider le stock du commercial ?"
                    onConfirm={()=> this.onConfirm(0)}
                    okText="OUI"
                    cancelText="NON" >
                <Button type="primary" style={{marginTop : 20}} loading={this.state.btnLoading} htmlType="button" size="large" icon="delete" className="pull-right">Vider le stock</Button>
            </Popconfirm>}

       </div>
 </div>);
    
}


}


/*function MainViewStocks(props){
    let that = props.that;
    const Views = [];
    that.state.stocks.forEach((item, index) => {
           Views.push(
            <tr key={item.id + "__" + index} >
                <th scope="row" >
                  <Avatar size={100} src={`${GET_PRODUCTS_FILES_CTR + item.sku}.png`}  icon="picture" /> 
                </th>
                <td> <strong>{item.produitName ? item.produitName : "" } </strong></td>
                <td>{item.stock ? (item.stock / item.cartonQuantite) : "0" } </td>
                <td> {item.stock ? item.stock  : "0" }</td>
                <td>
                  <Popconfirm
                    icon={null}
                    title={(<InputNumber step={1} max={10} style={{width: "100%"}} min={0}  />)}
                    onConfirm={()=> console.log("_____")}
                    okText="OUI"
                    cancelText="NON" >
                      <a href="#" ><i class="fa fa-edit"></i></a>
                    </Popconfirm>
                </td>
           </tr>
       )       
    });
    if(that.state.stocks.length === 0){
        return(<Alert
              message="Chargement de stock"
              description="Cet utilisateur n'a pas de stock disponible ... "
              type="info"
              showIcon
            />)  
     }
   return ( <div>                     
            <div>
                <table className="table table-bordered table-striped" >
                <thead>
                    <tr>
                        <th style={{width : 100}}>#</th>
                        <th>Produit</th>
                        <th style={{width : 120}} >Qte en Carton </th>
                        <th style={{width : 120}} >Qte en items </th>
                        <th style={{width : 100}} >Actions</th>
                    </tr>
                </thead>
                <tbody>
                {Views}
                </tbody>
            </table>          
       </div>
 </div>);
}*/

const A_V_Stock_List = Form.create()(V_Stock_List);

export default withRouter(A_V_Stock_List);