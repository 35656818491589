import React, { Component } from 'react';
import { exeRequestFinal } from '../../util/APIUtils';
import './Login.css';
import { Link } from 'react-router-dom';
import { ACCESS_TOKEN, CURRENT_USER, COMM_ROLE } from '../../constants';
import { Form, Input, Button, Icon, notification, Checkbox, message } from 'antd';
const FormItem = Form.Item;

class Login extends Component {
    render() {
        const AntWrappedLoginForm = Form.create()(LoginForm)
        return (
               <div className="login-content">
                    <AntWrappedLoginForm onLogin={this.props.onLogin} />
                </div> );
             {/*<div className="login-container">
               <h1 className="page-title">Login</h1>
                
            </div>*/}
       
    }
}

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            btnLoading : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount(){
      document.getElementsByTagName("body")[0].classList.add("login-container");
    }

    handleSubmit(event) {
        event.preventDefault();   
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.setState({btnLoading : true});
                 const loginRequest = Object.assign({}, values);

                exeRequestFinal("/auth/signin", "POST", loginRequest, function(response){
                    console.log(response);
                    if(response.user.role !== COMM_ROLE){
                        localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                        localStorage.setItem(CURRENT_USER, JSON.stringify(response.user));
                        ///alert("ok merci")
                        this.props.onLogin(response.user);
                    }else{
                        this.setState({btnLoading : false});
                        notification.error({
                            message: 'GeoCRM App',
                            description: "Vous n'êtes pas habilités a acceder a la plateforme"
                        });    
                    }
                  
                  },function(error){

                    this.setState({btnLoading : false});

                    if(error.statusCode == 21) {
                        //message.error('Nom utilisateur ou Mot de passe incorrect. Veuillez réessayer SVP', 5);
                        notification.error({
                            message: 'GeoCRM App',
                            description: 'Nom utilisateur ou Mot de passe incorrect. Veuillez réessayer SVP'
                        });     

                    } else {
                        //message.error(error.message || 'Desolé ! Une erreur inattendue s\'est produite. Veuillez réessayer !', 5);
                        notification.error({
                            message: 'GeoCRM App',
                            description: error.message || 'Desolé ! Une erreur inattendue s\'est produite. Veuillez réessayer !'
                        });                                           
                    }

                  }, this);

               /* login(loginRequest)
                .then(response => {
                    console.log(response);
                    localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                    localStorage.setItem(CURRENT_USER, JSON.stringify(response.user));
                    ///alert("ok merci")
                    this.props.onLogin(response.user);
                }).catch(error => {

                    this.setState({btnLoading : false});

                    if(error.statusCode == 21) {
                        //message.error('Nom utilisateur ou Mot de passe incorrect. Veuillez réessayer SVP', 5);
                        notification.error({
                            message: 'GeoCRM App',
                            description: 'Nom utilisateur ou Mot de passe incorrect. Veuillez réessayer SVP'
                        });                   
                    } else {
                        //message.error(error.message || 'Desolé ! Une erreur inattendue s\'est produite. Veuillez réessayer !', 5);
                        notification.error({
                            message: 'GeoCRM App',
                            description: error.message || 'Desolé ! Une erreur inattendue s\'est produite. Veuillez réessayer !'
                        });                                           
                    }
                });*/
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        return (
          <div className="container">
            <div className="row">
              <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
                <div className="card card-signin">
                  <article className="card-body">  
                      <div className="d-flex justify-content-center">
                            <div className="brand_logo_container">
                                <img src={`${process.env.PUBLIC_URL}/images/logo_prosuma_acceuil.png`} className="brand_logo" alt="user" />
                            </div>
                      </div>
                      <hr className="my-4" />
                      
                      <h4 className="card-title text-center mb-4 mt-1">Geo <i className="fa fa-map-marker"></i> CRM - Connexion</h4>
                      
                      <Form onSubmit={this.handleSubmit} className="login-form">
                        <FormItem>
                            {getFieldDecorator('usernameOrEmail', {
                                rules: [{ required: true, message: 'SVP veuillez saisir votre login !!!' }],
                            })(
                            <Input 
                                prefix={<Icon type="user" />}
                                size="large"
                                name="usernameOrEmail" 
                                placeholder="Username or Email" />    
                            )}
                        </FormItem>
                        <FormItem>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: 'SVP veuillez saisir votre mot de passe !!!' }],
                        })(<Input 
                                prefix={<Icon type="lock" />}
                                size="large"
                                name="password" 
                                type="password" 
                                placeholder="Password"  />                        
                        )}
                        </FormItem>
                        <FormItem>
                         <div style={{marginBottom:20}}>
                            {getFieldDecorator('remember', {
                                    valuePropName: 'checked',
                                    initialValue: true,
                                })(<Checkbox>Remember me</Checkbox>)}
                                {/*<a style={{float:"right"}} className="login-form-forgot" href="">
                                    Forgot password
                            </a>*/}
                         </div>
                         <center>
                           <Button type="primary" loading={this.state.btnLoading} htmlType="submit" size="large" className="login-form-button">Connexion</Button>
                            {/*Or <Link to="/signup">register now!</Link>*/}
                        </center>
                        </FormItem>
                    </Form>
                      
                      {/*<form>
                        <div className="form-group">
                          <div className="input-group">
                              <div className="input-group-prepend">
                                  <span className="input-group-text"> <i className="fa fa-user"></i> </span>
                              </div>
                              <input name="" className="form-control" placeholder="Login" type="text" required />
                          </div> 
                        </div> 
                         <div className="form-group">
                          <div className="input-group">
                              <div className="input-group-prepend">
                                  <span className="input-group-text"> <i className="fa fa-lock"></i> </span>
                              </div>
                                <input className="form-control" placeholder="Mot de passe" type="password" required />
                          </div> 
                        </div> 
                        <div className="form-group"> 
                            <div className="checkbox">
                              <label> <input type="checkbox" /> Se souvenir de moi </label>
                            </div> 
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary btn-block"> S'identifier  </button>
                        </div> 
                        <div className="forget">
                         <span className="forget-pass"><a href="Recuperation.html">Mot de passe oublié ?</a></span>
                        </div>
                      </form>*/}
                  </article>
                </div>
                <div className="copy-right">
                     Copyright © 2019 | GeoCRM - PROSUMA
                </div>
              </div>
            </div>
          </div>
        );
    }
}


export default Login;