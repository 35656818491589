import React, { Component } from 'react';
import V_Produit_Add from './vues/V_Produit_Add';
import V_Produit_List from './vues/V_Produit_List';
import {Button, Icon} from 'antd'
class P_Produit_List extends Component {
    constructor(props) {
        super(props);
         //console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));
        this.state={
          visible:false,
          currentItem : null
        }
      }

    /* onEditing = (item) =>{
         this.setState({
            currentItem: item
        });           
    }*/

      showDrawer = (item) => {

        this.setState({
          visible: true,
          currentItem: item
        });

        //this.onEditing({});

      };

    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                     <h5 className="card-title titre_lib" ><strong>Liste des produits</strong>
                        <Button type="primary" className="pull-right" onClick={() => this.showDrawer(null)}>
                                <Icon type="plus"/>Ajouter
                        </Button>
                      </h5>

                       <div className="row">
                         <div className="col-md-12">
                           <V_Produit_List onEditing={this.showDrawer} />
                           <V_Produit_Add visible={this.state.visible} currentItem={this.state.currentItem} />
                         </div>
                      </div>
                     </div>
                  </div>              
            </div>    
        );
    }
}

export default P_Produit_List;




