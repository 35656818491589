import React, { Component } from 'react';
import { exeRequest } from '../../util/APIUtils';
import {sMillier} from '../../util/Helpers';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { DatePicker, Avatar} from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import './V_Profile.css';
import V_Stock_List from '../stock/V_Stock_List'
import V_Zone_List from '../zone/V_Zone_List'
import V_Stats_Users from './V_Stats_Users'
import { GET_USERS_FILES_CTR,STATUS_CODE_REST_API, STATUS } from '../../constants';




class V_Profile extends Component {

    constructor(props) {

        super(props);

       // var date = new Date();

        this.state = {
            data : null,
            isLoading: false,
            isUpdate : false,
            numberUpdated : 0
        };

        //this.loadUsersList = this.loadUsersList.bind(this);
        this.forceUpdate = this.forceUpdate.bind(this);
        this.loadUserInfos = this.loadUserInfos.bind(this);
    }

    forceUpdate(isStockValidation) {
        let number = this.state.numberUpdated + 1;
        //number++;
        this.setState({
            numberUpdated : number
        })

        if(isStockValidation){

            let lists = this.props.that.state.users;
            lists.filter(x => x.user.id == this.props.userId)[0].countStockValidation = 0;
            this.props.that.setState({
                users : lists
            })

        }else{
            this.loadUserInfos();
        }

        //console.log(this.state);
        //console.log("V_Profile number _____________________ " + number);
      }

    loadUserInfos() {

        this.setState({
            isLoading: true
          });

         exeRequest("/users/"+ this.props.userId , "GET", null, function(response){
            this.setState({
                data: (response ? response : null),
                isLoading: false
            });


          }, this)        
    }

    componentDidMount() {
        this.loadUserInfos();
   }
 
     componentDidUpdate(nextProps) {
        if(this.props.userId !== nextProps.userId) {
           if(this.props.userId) this.loadUserInfos();
         }
     }

    
      render() {

        const { data, isLoading} = this.state;


        if(isLoading) {
            return <LoadingIndicator />
        }


        if(!data){
            return <div>Utilisateur introuvable dans la base de données</div>
        }

        /* const zoneHtml =   data.user.zones.map(function(zone){
            <span className="tags">zone.libelle</span> 
        }); */

        return (
            <div className="container">
        <div className="row">
            <div className="col-md-12 col-lg-12">
            <div className="well profile psm_user_profile">
                <div className="row">
                    <div className="col-xs-12 col-lg-8 col-sm-8">
                        <h2>{ data.name ? data.name : "" }</h2>
                        <p><strong>Tel : </strong>{ data.phone ? data.phone : "" } </p>
                        <p><strong>Poste : </strong>Commercial </p>
                        <p><strong>Description : </strong> Read, out with friends, listen to music, draw and learn new things. </p>
                        <p><strong>Zone : </strong>
                           { 
                                data.zones.map((zone) =>
                                    <span key={zone.id} className="tags">{zone.libelle}</span> 
                                ) 
                            }
                        </p>
                    </div>             
                    <div className="col-xs-12 col-lg-4 col-sm-4 text-center">
                        <figure>
                            {/*<img src={`${process.env.PUBLIC_URL}/images/user/avatar-1.jpg`} alt="" className="img-circle img-responsive" />*/}
                            <Avatar shape="square" size={150} src={`${GET_USERS_FILES_CTR + data.username}.png`}  icon="user"  />
                            <figcaption className="ratings">
                                <p>Ratings
                                <a href="#">
                                    <span className="fa fa-star"></span>
                                </a>
                                <a href="#">
                                    <span className="fa fa-star"></span>
                                </a>
                                <a href="#">
                                    <span className="fa fa-star"></span>
                                </a>
                                <a href="#">
                                    <span className="fa fa-star"></span>
                                </a>
                                <a href="#">
                                    <span className="fa fa-star-o"></span>
                                </a> 
                                </p>
                            </figcaption>
                        </figure>
                    </div>
                </div>  


 {/*<div className="card" style={{marginTop: 20}}>
    <div className="card-body">
                        <h5 className="card-title titre_lib"><strong>Historique : </strong></h5>*/}
        <div className="divider row" style={{marginTop: 10}} >
            <div className="col-md-12">
    <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item"><a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Statistique</a></li>
        <li className="nav-item"><a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Stock</a></li>
        <li className="nav-item"><a className="nav-link" id="profile-tab" data-toggle="tab" href="#zone" role="tab" aria-controls="zone" aria-selected="false">Zone</a></li>
     
    </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active divider" id="home" role="tabpanel" aria-labelledby="home-tab" style={{marginBottom:10}}>
                <V_Stats_Users userId={this.props.userId} forceUpdate={this.forceUpdate}  numberUpdated={this.state.numberUpdated} />
            </div>
            <div className="tab-pane fade divider" id="profile" role="tabpanel" aria-labelledby="profile-tab" style={{marginBottom:10}} >
                <V_Stock_List  userId={this.props.userId}  forceUpdate={this.forceUpdate} numberUpdated={this.state.numberUpdated} />              
            </div>

            <div className="tab-pane fade divider" id="zone" role="tabpanel" aria-labelledby="profile-tab" style={{marginBottom:10}} >
                <V_Zone_List userId={this.props.userId}  forceUpdate={this.forceUpdate} numberUpdated={this.state.numberUpdated} />              
            </div>

            </div>   
        </div>  
        </div>
       {/*</div>
      </div>*/}
     </div>                 
    </div>
   </div>
 </div>
        );
    }
}

export default withRouter(V_Profile);