import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Icon, notification } from 'antd';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { exeRequest } from '../../util/APIUtils';
import {GET_REPORTS_FILES_CTR, GET_PDF_VIEWER} from '../../constants'
import locale from 'antd/es/date-picker/locale/fr_BE';
import {sMillier, changePage, RegroupItem} from '../../util/Helpers'
import DataTable from '../../module/DataTable';
import moment from 'moment';

const { MonthPicker, WeekPicker, RangePicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

class PReport extends Component {

    constructor(props) {
        super(props);
        this.state = {
           typeReport : 1,
           produits: [],
           objRequest : null,
           isLoading : false,
           btnLoading : false,
           fileUrl : null,
           result : null
        }
      }

      componentDidMount(){
        this.loadProduits();
      }


      loadProduits = () => {

        this.setState({
          isLoading: true
        })

        exeRequest("/produits/enable", "GET", null, function(response){
            this.setState({
                produits: response ? response : [],
                isLoading: false
            })

          }, this)
    }

    changeReport = (e) =>{

      console.log(e);

      this.setState({
        typeReport : e
      });

    }

    getEnDate = (date) =>{
      return new Date(date).toISOString().split("T")[0];
    }

    openFile(format, fileUrl){

      if(format === "xls"){
        window.open(GET_REPORTS_FILES_CTR + fileUrl)
      }else{
        this.launchPopup(GET_PDF_VIEWER + "?file=" + GET_REPORTS_FILES_CTR + fileUrl, 1080, 700);
      }

    }

    handleSubmit = (event) =>{

    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) { 
       // this.setState({ btnLoading: true });

        let objRequest = Object.assign({}, values);

        console.log(objRequest);

       
         
        this.setState({
          btnLoading: true
        });

        let urlC = null;
        if(this.state.typeReport > 1){
          urlC = "weekly?produitId=" + objRequest.produitId + "&date=" + this.getEnDate(objRequest.week._d) + "&format=" + objRequest.format;
        }else{
          let date = objRequest.rangeDate
          let startDate = new Date(date[0]._d.getFullYear(), date[0]._d.getMonth(), date[0]._d.getDate(), 0, 1, 1) 
          let endDate = new Date(date[1]._d.getFullYear(), date[1]._d.getMonth(), date[1]._d.getDate(), 23, 59, 59);
          urlC = "monthly?produitId=" + objRequest.produitId + "&sdate=" + startDate.toISOString() + "&edate=" + endDate.toISOString() + "&type=" + this.state.typeReport + "&format=" + objRequest.format
        }
     
       exeRequest("/reportings/" + urlC,  "GET"  , null, function (response) {
       
           console.log(response);

            this.setState({
              objRequest: objRequest,
              result : response.message,
              btnLoading: false,
              //fileUrl : response.message ? GET_REPORTS_FILES_CTR + response.message : null,
            });

            if(!response.message["1"]){
                notification.error({
                message: 'GeoCRM App',
                description: 'Error lors de la generation du rapport'
              }); 

            }else if (this.state.typeReport > 1){

              this.openFile(objRequest.format, response.message["1"])

            }

            
       
        }, this);

      }

    });

 }

 launchPopup(URL, POPUP_WIDTH, POPUP_HEIGHT) {
  const left = window.screen.width / 2 - POPUP_WIDTH / 2,
    top = window.screen.height / 2 - POPUP_HEIGHT / 2;
  const win = window.open(
    URL,
    "PDF Viewer",
    "menubar=no,location=no,resizable=no,scrollbars=no,status=no, width=" +
      POPUP_WIDTH +
      ", height=" +
      POPUP_HEIGHT +
      ", top=" +
      top +
      ", left=" +
      left
  );
  if (win) win.opener = window;
}

    
    render() {
      const { getFieldDecorator } = this.props.form;
      //console.log("this.state.result[]", this.state.result["2"])
        return (
            <div>
               <div className="card">
                <div className="card-body">
                    <h5 className="card-title titre_lib" ><strong>Reporting</strong></h5><br />
                       <div className="row">
                         <div className="col-xs-12 col-md-12">
                          
                            <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

                                 <div className="row">

                                  <div className="col-lg-3 col-sm-6 col-xs-12">
                                    <Form.Item label="Type de Rapport">
                                      {getFieldDecorator('reportType', {
                                        rules: [{ required: true, message: 'Veuillez Choisir le type de rapport' }],
                                        initialValue : this.state.typeReport ? this.state.typeReport : 1, 
                                      })(<Select placeholder="Veuillez selectionner le type de rapport" onChange={this.changeReport} >
                                           <Option key={"type_2"} value={1}>{"Rapport mensuel par commercial"}</Option>
                                           <Option key={"type_1"} value={2}>{"Rapport Hebdomadaire"}</Option>
                                           <Option key={"type_2"} value={0}>{"Rapport mensuel par zone"}</Option>
                                           
                                         </Select>)}
                                    </Form.Item>
                                  </div>

                                  <div className="col-lg-2 col-sm-6 col-xs-12" >
                                    <Form.Item label="Le format d'export : ">
                                    {getFieldDecorator('format', {
                                        rules: [{ required: true, message: 'Veuillez Choisir le format' }],
                                        initialValue : "", 
                                      })(<Select placeholder="Veuillez choisir le format" >
                                      <Option key={"pdf_1"} value={"pdf"}>{"Format PDF"}</Option>
                                      <Option key={"xls_1"} value={"xls"}>{"Format Excel"}</Option>
                                    </Select>)}                                 
                                      </Form.Item>
                                  </div>

                                <div className="col-lg-3 col-sm-6 col-xs-12" >
                                  <Form.Item label="Le produit : ">
                                  {getFieldDecorator('produitId', {
                                      rules: [{ required: true, message: 'Veuillez Choisir le produit' }],
                                      initialValue : "", 
                                    })(<Select placeholder="Veuillez choisir le produit" >
                                    {this.state.produits.map(( m, index) => (
                                        <Option key={m.id} value={m.id}>{m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                    </Form.Item>
                                </div>

                                {this.state.typeReport > 1 ? ( 
                                      <div className="col-lg-2 col-sm-6 col-xs-12" >
                                        <Form.Item label="Choisir la semaine : ">
                                          {getFieldDecorator('week', {
                                            rules: [{ required: true, message: 'Veuillez choisir une semaine' }],
                                            initialValue : moment(new Date(), dateFormat), 
                                          })(<WeekPicker style={{width:"100%"}} format={dateFormat} onChange={(e, m)=> console.log("DATETIME", m)}  locale={locale} placeholder="Choisir une semaine" />)}
                                        </Form.Item>
                                      </div>
                                    ) : (
                                      <div className="col-lg-3 col-sm-6 col-xs-12" >


                                        <Form.Item label="Intervalle de dates : ">
                                          {getFieldDecorator('rangeDate', {
                                              rules: [{ required: true, message: 'Veuillez choisir une intervalle de date' }],
                                              initialValue : [moment(new Date(), dateFormat), moment(new Date(), dateFormat)], 
                                            })(<RangePicker style={{width:"100%"}}  placeholder="Choisir une intervalle de date" />)}                                 
                                        </Form.Item>


                                        {/*<Form.Item label="Choisir le mois : ">
                                          {getFieldDecorator('month', {
                                            rules: [{ required: true, message: 'Veuillez choisir un mois' }],
                                            initialValue : moment(new Date(), dateFormat), 
                                          })(<MonthPicker style={{width:"100%"}}  placeholder="Choisir un mois"  />)}
                                        </Form.Item>*/}

                                      </div>   
                                    )}

                                  <div className="col-lg-1 col-sm-6 col-xs-12" >
                                    <Button loading={this.state.btnLoading} className="mt-4 pull-left" htmlType="submit" icon="check" size="large" type="primary" ></Button>
                                  </div>

                                </div>

                              {/*<div className="row">
                                <div className="col-lg-12 col-sm-12 col-xs-12" style={{minHeight:300}}>
                                    {this.state.fileUrl && ( 
                                    <iframe src={"https://docs.google.com/viewerng/viewer?url="+ this.state.fileUrl +"&embedded=true"} style={{width:"100%", height:600}} frameBorder="0"></iframe>
                                    )}
                                </div>
                                    </div>*/}
                              
                              </Form>
                       
                          </div>

                          <div className="col-xs-12  col-md-12">
                                 
                          {!this.state.btnLoading && this.state.result &&  !(this.state.typeReport > 1) && (
                            <>
                             <ReportView result={this.state.result} />
                             <Button type="primary" style={{marginTop : 20}}  htmlType="button" size="large" onClick={() => this.openFile(this.state.objRequest.format, this.state.result["1"])} icon="file" className="pull-right">Exporter</Button>
                            </>
                          )}
                          </div>

                      </div>
                   </div>
               </div>               
            </div>                  
        );
    }

}


function ReportView(props){
  return (
    <Row>
    <Col span={24}> 
        <DataTable >
          <thead>
              <tr>
                  <th style={{width:30}} >#</th>
                  <th>Commercial</th>
                  <th style={{width:120, textAlign : "center", verticalAlign : "middle"}} >Visites progs</th>
                  <th style={{width:120, textAlign : "center", verticalAlign : "middle"}} >Visites effectuées</th>
                  <th style={{width:120, textAlign : "center", verticalAlign : "middle"}} >Visites factureés</th>
                  <th style={{width:120, textAlign : "center", verticalAlign : "middle"}} >Clients actifs</th>
                  <th style={{width:120, textAlign : "center", verticalAlign : "middle"}}>Ventes(qte)</th>
                  <th style={{width:180, textAlign : "center", verticalAlign : "middle"}}>Chiffre Affaire (CA)</th>
                </tr>
          </thead>
          <tbody>
          {props.result["2"]?.map((item,i)=>
            <tr key={i + "_" +  item.itemId} >
              <th scope="row" style={{textAlign : "center", verticalAlign : "middle", width:30}}> {i + 1} </th>
              <td style={{verticalAlign : "middle"}} > {item.item} </td>
              <td style={{textAlign : "center",verticalAlign : "middle"}} >{sMillier(item.categorieReports.sum("visites_prog"))}</td>
              <td style={{textAlign : "center",verticalAlign : "middle"}} >{sMillier(item.categorieReports.sum("visites_effec"))}</td>
              <td style={{textAlign : "center",verticalAlign : "middle"}} >{sMillier(item.categorieReports.sum("visites_fac"))}</td>
              <td style={{textAlign : "center",verticalAlign : "middle"}} >{sMillier(item.categorieReports.sum("client_actif"))}</td>
              <td style={{textAlign : "center",verticalAlign : "middle"}} >{sMillier(item.categorieReports.sum("qte_ventes"))}</td>
              <td style={{textAlign : "right",verticalAlign : "middle"}} >{sMillier(item.categorieReports.sum("ventes"))}</td>                                              
            </tr>                                                                               
          )
        }
      </tbody>
    </DataTable>
    </Col>
    </Row>
  )
}


const AFPReport = Form.create()(PReport);

export default AFPReport;