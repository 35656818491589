import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select,notification, InputNumber} from 'antd';
import { exeRequest } from '../../util/APIUtils';
import { withRouter } from 'react-router-dom';
import { STATUS_CODE_REST_API } from '../../constants';

const { Option } = Select;

class DrawerForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
      visible: false,
      btnLoading: false,
      currentItem : null,
      btnLoading : false,
      zones : [],
      segments : [],
      categories : []
    };
  //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps({visible, currentItem}) {
    this.setState({ visible : visible ? visible  : this.state.visible }); 
    this.setState({ currentItem : currentItem }); 
    //console.log("testeur de maggie ", currentItem)
    //if(cu)
    //this.setState({ files : currentItem && this.state.files.length == 0 ? [{url : GET_USERS_FILES_CTR + currentItem.username + ".png",uid: currentItem.id, name: 'image.png',status: 'done' }] : this.state.files }); 
     }
     
 
  componentDidMount() {
    this.setState({ visible: false });
    //this.loadAgences();

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible) {
       if(this.state.visible){
         this.props.form.resetFields()
         this.loadZones();
        }
    }
  }

  onClose = () => {

    this.setState({
      visible: false,
      btnLoading: false,
      currentItem : null
    });

    //this.props.history.push(process.env.PUBLIC_URL + "/param/cat/list");
    //window.location.href = process.env.PUBLIC_URL + "/param/cat/list";

  };


  loadZones= () =>  {

    this.setState({
      isLoading: true
    });

      exeRequest("/zones", "GET", null, function(response){
        this.setState({
            zones: (response ? response : [])
        });

        this.loadCategories();

      }, this) 
  }

  

  loadSegments= (zoneId) =>  {

      exeRequest("/zones/"+ (zoneId ? zoneId : this.state.currentItem.zoneId ) + "/segments"  , "GET", null, function(response){

        this.setState({
          segments: (response ? response : [])
        });

        //this.loadZones();

      }, this) 
  }

  
  
  
  loadCategories= () =>  {

    exeRequest("/categories", "GET", null, function(response){
        this.setState({
            categories: (response ? response : [])
        });
        
        this.loadSegments();

      }, this)

  }

  
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ btnLoading: true });
        const objRequest = Object.assign({}, values);
        console.log(objRequest);

       // return false;

        this.setState({
          btnLoading: true
        })

        exeRequest("/clients/" + objRequest.id, "POST" , JSON.stringify(objRequest), function (response){
          
          if (response.statusCode == STATUS_CODE_REST_API[1].code) {
            notification.success({
              message: 'GeoCRM App',
              description: 'Client mis à jour avec succès'
            });
            this.onClose();
            window.location.reload(true);
            //window.location.href = process.env.PUBLIC_URL + "/param/cat/list"
            //this.props.history.push(process.env.PUBLIC_URL + "/param/cat/list")
            
          } else {

          notification.error({
            message: 'GeoCRM App',
            description: "Une erreur est survenue lors de la mise à jour du client",
            btnLoading:false
            
          });

          this.setState({
            btnLoading: false
          })
        }

        }, this)
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer 
          title="Mise a jour Client "
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

          {getFieldDecorator('id', {
            initialValue : this.state.currentItem ? this.state.currentItem.id : null,           
          })(<Input type="hidden" />)}

         {getFieldDecorator('coord_lng', {
            initialValue : this.state.currentItem ? this.state.currentItem.coord_lng : null,           
          })(<Input type="hidden" />)}

           {getFieldDecorator('coord_lat', {
            initialValue : this.state.currentItem ? this.state.currentItem.coord_lat : null,           
          })(<Input type="hidden" />)}

           {getFieldDecorator('coord_lng_reel', {
            initialValue : this.state.currentItem ? this.state.currentItem.coord_lng_reel : null,           
          })(<Input type="hidden" />)}


           {getFieldDecorator('coord_lat_reel', {
            initialValue : this.state.currentItem ? this.state.currentItem.coord_lat_reel : null,           
          })(<Input type="hidden" />)}

          {getFieldDecorator('description', {
            initialValue : this.state.currentItem ? this.state.currentItem.description : null,           
          })(<Input type="hidden" />)}


           {getFieldDecorator('code', {
            initialValue : this.state.currentItem ? this.state.currentItem.code : null,           
          })(<Input type="hidden" />)}

          {getFieldDecorator('autourEcole', {
            initialValue : this.state.currentItem ? this.state.currentItem.autourEcole : null,           
          })(<Input type="hidden" />)}

       {getFieldDecorator('status', {
            initialValue : this.state.currentItem ? this.state.currentItem.status : null,           
          })(<Input type="hidden" />)}
 
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Etablissement">
                  {getFieldDecorator('etablissement', {
                    rules: [{ required: true, message: "Veuillez saisir le nom de l'etablissement" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.etablissement.replace(this.state.currentItem.categorieLib, "").trim() : "", 
                  })(<Input placeholder="Entrez le nom de l'etablissement" />)}
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Interlocuteur">
                  {getFieldDecorator('interlocuteur', {
                    rules: [{ required: true, message: "Veuillez saisir le nom de du gerant" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.interlocuteur : "", 
                  })(<Input placeholder="Entrez le nom de gerant" />)}
                </Form.Item>
              </Col>
            </Row>


           <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Contact">
                  {getFieldDecorator('telephone', {
                    rules: [{ required: false, message: "Veuillez saisir le contact" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.telephone : "", 
                  })(<Input placeholder="Entrez le contact " />)}
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={16}>
              <Col span={24} >
                  <Form.Item label="Quartier : ">
                  {getFieldDecorator('zoneId', {
                      rules: [{ required: true, message: 'Veuillez selectionner la zone ' }],
                      initialValue : this.state.currentItem ? this.state.currentItem.zoneId : ""
                    })(
                      <Select placeholder="Veuillez selectionner la zone " style={{width: "100%"}} onChange={(e) => this.loadSegments(e)} >
                          {this.state.zones.map(( m, index) => (
                          <Option key={m.id} value={m.id}>{m.libelle}</Option>
                            ))}
                      </Select>
                    )}
                    </Form.Item>
                  </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24} >
                  <Form.Item label="Sous Quartier : ">
                  {getFieldDecorator('segmentId', {
                      rules: [{ required: true, message: 'Veuillez selectionner le sous quartier ' }],
                      initialValue : this.state.currentItem ? this.state.currentItem.segmentId : ""
                    })(
                      <Select placeholder="Veuillez selectionner le sous quartier " style={{width: "100%"}}>
                          {this.state.segments.map(( m, index) => (
                          <Option key={m.id} value={m.id}>{m.libelle}</Option>
                            ))}
                      </Select>
                    )}
                    </Form.Item>
                  </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24} >
                  <Form.Item label="Categorie : ">
                  {getFieldDecorator('categorieId', {
                      rules: [{ required: true, message: 'Veuillez selectionner la categorie' }],
                      initialValue : this.state.currentItem ? this.state.currentItem.categorieId : ""
                    })(
                      <Select placeholder="Veuillez selectionner la categorie" style={{width: "100%"}} >
                          {this.state.categories.map(( m, index) => (
                          <Option key={m.id} value={m.id}>{m.libelle}</Option>
                            ))}
                      </Select>
                    )}
                    </Form.Item>
                  </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Position">
                  {getFieldDecorator('position', {
                    rules: [{ required: true, message: "Veuillez selectionnez la position : " }],
                    initialValue : this.state.currentItem ? this.state.currentItem.position : "", 
                  })(<InputNumber step={1} style={{width: "100%"}} min={1}  />)}
                </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Indication">
                  {getFieldDecorator('indication', {
                    rules: [{ required: true, message: "Veuillez saisir l'indication du client" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.indication : ""
                  })(<Input.TextArea size="10" placeholder="Entrez l'indication du client" />)}
                </Form.Item>
              </Col>
            </Row>
              
            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >

            <Button onClick={this.onClose} style={{marginRight: 8 }} size="small">Annuler</Button>
            <Button type="primary" loading={this.state.btnLoading} htmlType="submit" size="small">Enregistrer</Button>

            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}

const V_Editing = Form.create()(DrawerForm);

export default withRouter(V_Editing);

//export default ADrawerForm;

