import React, { Component} from 'react';
import { exeRequest } from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import {notification } from 'antd';
import {TYPE_OPERATION } from '../../constants';
//import {colorsActif} from '../../util/Colors';
import {sMillier} from '../../util/Helpers';
import Map  from '../../module/Map';
import { withRouter } from 'react-router-dom';
import DataTable from '../../module/DataTable';
import './V_History.css';

class V_History_Details extends Component {

    constructor(props) {
        super(props);

        this.state = {
            operation : null,
            isLoading: true
        };

        this.loadOperation = this.loadOperation.bind(this);
       //console.log("_____________________________________" + this.props.operationId)
        //this.handleLoadMore = this.handleLoadMore.bind(this);
        //this.loadTypes = this.loadTypes.bind(this);
    }
    
    loadOperation() {
        //console.log("InOperationDetails "  + this.props.operationId)

       this.setState({
            isLoading: true
        });

        exeRequest("/operations/"+ this.props.operationId, "GET", null, function(response){

            this.setState({
                operation: response ? response : null,
                isLoading: false
            })


          }, this)
     
        
    }

    componentDidMount() {
       // this.loadTypes();
       console.log("_____________________________________" + this.props.operationId)
       if(this.props.operationId) this.loadOperation();
    }

    componentDidUpdate(nextProps) {

        console.log("_____________________________________" + this.props.operationId)

        if(this.props.operationId !== nextProps.operationId) {
            // Reset State

           /* this.setState({
                operation: null,
                isLoading: false
            }); */

            if(this.props.operationId) this.loadOperation();
        }

    }
    
    render() {
        
        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
        
        //const GoView = ;  
        return (<div>
            {/* <a href="#" onClick={() =>  this.props.onBack(false, null)}>back</a>*/}
               {this.state.operation.typeOperationId ===  TYPE_OPERATION["1"].Id ?  <MainViewVisite operation={this.state.operation} /> : <MainViewVente operation={this.state.operation} />}
            </div>);
    }
}

function MainViewVisite(props){
    const operation = props.operation;
    const itemOp = [{
        coord_lat_reel : operation.coord_lat,
        coord_lng_reel : operation.coord_lng,
        etablissement : operation.ref ? operation.ref : operation.createdByName,
        status : 1,
        categorieId : null
    },
    {
        coord_lat_reel : operation.client_coord_lat,
        coord_lng_reel : operation.client_coord_lng,
        etablissement : operation.clientName && operation.clientName,
        status : 1,
        categorieId : null
    }]
    return(
        <div className="card" style={{marginTop: 20}}>
                <div className="card-body">
                    <h5 className="card-title titre_lib"><strong> VISITE N° : {operation ? operation.ref :  ""}  </strong></h5>
       <div className="row">
          <div className="col-md-8 p-main-content" >
            <div className="row" style={{marginBottom:25}}>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>operation :&nbsp;</span> Visite
                </div>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>Reference :&nbsp;</span> {operation.ref ? operation.ref : "" } 
                </div>
             
            </div>
            <div className="row" style={{marginBottom:25}}>

               <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>Commercial :&nbsp;</span> {operation ? operation.createdByName : "" }
                </div>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>Date visite :&nbsp;</span> {operation.createdAt ? new Date(operation.createdAt).toLocaleString("fr-FR") : "" } 
                </div>
                
            </div>

                <div className="row" style={{marginBottom:25}}>

                    <div className="col-lg-12 col-sm-12 col-xs-12">
                    <span className='libcomd'>Action : </span> <br/>
                     <div className="card">
                      <div className="card-body">
                         {operation ? operation.action : "" }
                      </div>
                        
                         </div>  
                      </div>  
                </div>

          </div>  

            <div className="col-md-4">
                <Map hideLegende={true} polyline={true} mapHeight={300} data={itemOp ? itemOp : []}   position={itemOp? {lat : itemOp[0].coord_lat_reel, lng: itemOp[0].coord_lng_reel} : null} onError={() => notification.error({
                    message: 'GeoCRM App',
                    description:'Error ! Impossible de charger la carte google map ! Verifiez votre connexion internet.'
                })} />
            </div>
          </div>
        </div>
     </div>

    )
}


function MainViewVente(props){
    const operation = props.operation;

    const itemOp = [{
        coord_lat_reel : operation.coord_lat,
        coord_lng_reel : operation.coord_lng,
        etablissement : operation.ref ? operation.ref : operation.createdByName,
        color : "000000",
        categorieId : null
    },
    {
        coord_lat_reel : operation.client_coord_lat,
        coord_lng_reel : operation.client_coord_lng,
        etablissement : operation.clientName && operation.clientName,
        status : operation.clientStatus,
        categorieId : operation.clientCategorieId
    }]

    const produitsViews = [];
    operation.operationProduits.forEach((item, itemIndex) => {
       produitsViews.push( <tr key={itemIndex}>
                             <th >{itemIndex   + 1}</th>
                            <td>{item.produitLib}</td>
                            <td style={{textAlign:'right'}} >{item.nbreProduit}</td>
                            <td style={{textAlign:'right'}} >{Math.round(item.montant / item.nbreProduit)} </td>
                            <td style={{textAlign:'right'}} >{(item.montant ? sMillier(item.montant) : 0)}</td>
                        </tr>);      
         });

    return(
        <div className="card" style={{marginTop: 20}}>
                <div className="card-body">
                    <h5 className="card-title titre_lib"><strong> VENTE N° : {operation ? operation.ref :  ""}  </strong></h5>
       <div className="row">
          <div className="col-md-8 p-main-content" >
            <div className="row" style={{marginBottom:25}}>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>operation :&nbsp;</span> Vente
                </div>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>Reference :&nbsp;</span> {operation.ref ? operation.ref : "" } 
                </div>
             
            </div>
            <div className="row" style={{marginBottom:25}}>

               <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>Commercial :&nbsp;</span> {operation ? operation.createdByName : "" }
                </div>

                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <span className='libcomd'>Date vente :&nbsp;</span> {operation.createdAt ? new Date(operation.createdAt).toLocaleString("fr-FR") : "" } 
                </div>
                
            </div>

            <div className="row" style={{marginBottom:25}}>

            <div className="col-lg-12 col-sm-12 col-xs-12">
            <span className='libcomd'>Montant :&nbsp;</span> <span className="badge badge-warning"  style={{fontWeight: "bolder", color: "white"}} > {operation ? sMillier(operation.montant) : "0" } </span> 
            </div>

            </div>

            <div className="row" style={{marginBottom:25}}>
              <div className="col-lg-12 col-sm-12 col-xs-12">
                <span className='libcomd'>Details de la vente </span> <br/><br/>
                   <DataTable> 
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Produit</th>
                                <th style={{width:"80px"}}>Nombre</th>
                                <th style={{width:"150px"}} >Prix unitaire</th>
                                <th style={{width:"200px"}} >Montant</th>
                            </tr>
                        </thead>
                        <tbody>
                            {produitsViews}
                            {/*  {this.state.isLoading ? <LoadingIndicator />: null } */}
                        </tbody>
                        </DataTable>
                    </div>  
            </div>

          </div>  

            <div className="col-md-4">
                <Map hideInfoWindow={true} polyline={true} hideLegende={true}  mapHeight={300} data={itemOp ? itemOp : []}  position={itemOp? {lat : itemOp[0].coord_lat_reel, lng: itemOp[0].coord_lng_reel} : null} onError={() => notification.error({
                    message: 'GeoCRM App',
                    description:'Error ! Impossible de charger la carte google map ! Verifiez votre connexion internet.'
                })} />
            </div>
          </div>
        </div>
     </div>

    )
}

export default withRouter(V_History_Details);




