import React, { Component } from 'react';
import { changePage } from '../util/Helpers';
import {GET_USERS_FILES_CTR} from '../constants';
import { Avatar } from 'antd';
import $ from "jquery";
class Header extends Component {
    
    changeSelected(e, that){
        //alert("OK de meme")
      $("#navbarsExample07 .nav-item").removeClass("active");
      $(e.target).parent().addClass("active");
      changePage(e, that)
    }

    render() {
        //const {login, username}= this.state
        return (
         <div>
            <div className="header">
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <a className="navbar-brand" >Geo <i className="fa fa-map-marker"></i> CRM</a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavDropdown">
                        <ul className="nav navbar-nav ml-auto">
                            <li className="profile">
                               <Avatar size="large" src={GET_USERS_FILES_CTR + (this.props.currentUser && this.props.currentUser.username) +".png"}  icon="user" /> 
                               {/*<img src={`${process.env.PUBLIC_URL}/images/user/avatar-1.jpg`} alt="user-img" />*/}
                             </li>
                             <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="dropdown05" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> {(this.props.currentUser && this.props.currentUser.name)} </a>
                                <div className="dropdown-menu" aria-labelledby="dropdown05">
                                    <a className="dropdown-item" ><i className="fa fa-user"></i> Mon profil</a>
                                    <a className="dropdown-item" ><i className="fa fa-lock"></i> Modifier mot de passe</a>
                                    <a className="dropdown-item"  onClick={()=>this.props.handleLogout()} ><i className="fa fa-power-off"></i> Se déconnecter</a>
                                </div>
                              </li>
                        </ul>
                    </div>
                </nav>
            </div>
             <div className="menu">
                <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
                    <div className="container">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExample07" aria-controls="navbarsExample07" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                        <div className="collapse navbar-collapse justify-content-lg-center" id="navbarsExample07">
                            <ul className="navbar-nav ">
                                <li className="nav-item active">
                                    <a className="nav-link" data-url={`${process.env.PUBLIC_URL}/`}  onClick={(e)=> this.changeSelected(e, this)}  >Acceuil <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-url={`${process.env.PUBLIC_URL}/client/list`}  onClick={(e)=> this.changeSelected(e, this)} >Clients</a>
                                </li>
                                {/*<li className="nav-item">
                                    <a className="nav-link" onClick={(e)=> this.changeSelected(e, this)} >Operations</a>
                                 </li>*/}
                                <li className="nav-item">
                                    <a className="nav-link" data-url={`${process.env.PUBLIC_URL}/user/list`}  onClick={(e)=> this.changeSelected(e, this)} >Force de vente</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
         </div>
      );
    }
}

export default Header;
