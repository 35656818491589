import React, { Component } from 'react';
import { exeRequest } from '../../../util/APIUtils';
import LoadingIndicator  from '../../../common/LoadingIndicator';
import {Switch } from 'antd';
/*import { POLL_LIST_SIZE } from '../../../constants';
import {cusStatus} from '../../util/Colors';*/
import { GET_PRODUCTS_FILES_CTR,STATUS_CODE_REST_API, STATUS } from '../../../constants';
import { withRouter } from 'react-router-dom';
import DataTable from '../../../module/DataTable';


class V_Marque_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            currentClient: null,
            isActifLoading : false,
           isLoading: false
        };
        /*this.loadClientList = this.loadClientList.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);*/
        this.loadMarque = this.loadMarque.bind(this);
    }


    loadMarque() {

        this.setState({
            isLoading: true
          });

          exeRequest("/marques", "GET", null, function(response){

            //console.log("2222222222222222222222", response.data)
            //const lists = this.state.lists.slice();  

            this.setState({
                lists: (response ? response : []),
                /*page: response.page,
                size: response.size,
                totalElements: response.totalElements,
                totalPages: response.totalPages,
                last: response.last,
                */
                isLoading: false
            })


          }, this)
        
    }
   
    componentDidMount() {
        this.loadMarque();
        //loadCategories();
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                lists: [],
                /*page: 0,
                size: 10,
                totalElements: 0,
                totalPages: 0,
                last: true,
                */
                currentClient : null,
                isLoading: false
            });    
            //this.loadmarque();
        }
    }
    

    onCheckedChange(checked, index){

        this.setState({
            isActifLoading: true
            //btnLoading : false
        });
    
        exeRequest("/marques/" + this.state.lists[index].id + "/activate/" + (checked ? STATUS[1].value : STATUS[0].value), "GET", null, function(response){
    
            this.setState({
                isActifLoading: false,
                 //btnLoading : false
             });
    
           if(response.statusCode == STATUS_CODE_REST_API[1].code){         
                let lists = this.state.lists;
                lists[index].status = (checked ? STATUS[1].value : STATUS[0].value)
                this.setState({ lists: lists });  
            }
            /*else{
                notification.error({
                    message: 'GeoCRM App',
                    description: "Une erreur est survenue lors du traitement de votre action"
                });
            }*/
    
           }, this);
    
        } 


    render() {
        const Views = [];
        this.state.lists.forEach((item, index) => {
                //console.log(client);
                //let color  = cusStatus[client.status.toString()]
                //let icon = this.state.catData.length !== 0 ? this.state.catData.find( c => c.id === client.categorieId).icon : "fa-map-marker"

                Views.push(

                <tr key={item.id} >
                     <th scope="row" style={{width:10}}>{index + 1}</th>
                    {/*<td style={{width:100}} > {item.id ? item.id: "" } </td>
                    <td style={{width:100}} > {item.code ? item.code: "" } </td>*/}
                    <td> {item.libelle ? item.libelle: "" } </td>
                    <td style={{width:10}} > <Switch size="small" loading={this.state.isActifLoading ? true : false}  checked={(!item.status || item.status == 0)  ? false : true} onChange={(checked) => this.onCheckedChange(checked, index)}  /> </td>
                    <td className="actions" style={{width:30}}>
                        <a href={"#"} style={{cursor: "pointer"}} onClick={()=> this.props.onEditing(item)}  ><i className="fa far fa-edit fa-lg"  style={{color:"black"}} ></i></a>
                    </td>
                </tr>

           )     
              
        });

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }

       return (

            <div>
                <DataTable>
                <thead>
                    <tr>
                        <th>#</th>
                      {/*<th>Id</th>
                        <th>Code</th>*/}
                        <th>Libellé</th>
                        <th>Est actif</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Views}
                </tbody>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(V_Marque_List);