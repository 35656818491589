import React, { Component } from 'react';
import { getClientFiles} from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import scriptLoader from 'react-async-script-loader';
import {notification } from 'antd';
import { exeRequest} from '../../util/APIUtils';
import { withRouter } from 'react-router-dom';
import {GET_CLIENT_FILES_CTR} from '../../constants';
import './V_Portefolio.css';
import $ from "jquery";
import  {RegroupItem, validURL} from '../../util/Helpers'
$.isotope = require("isotope-layout");

class V_Posm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posms : [],
            posmSelected : [],
            isLoading: false
        };

       
    }
 

   loadPosm = () =>{

    this.setState({
        isLoading: true
      });

        exeRequest("/posms/", "GET", null, function(response){

            this.setState({
                posms: response || []
            });

            this.loadClientPosm();

        }, this);

   }

  loadClientPosm  = () => {
     
    exeRequest("/clients/"+  this.props.clientId  +"/posms" , "GET", null, function(response){
        this.setState({
            posmSelected: response || [],
            isLoading: false
        });
    }, this);
    
    }

    componentDidMount() {
       this.loadPosm();
        //this.initIsotope()
    }

    componentDidUpdate(nextProps) {

        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                posms: [],
                isLoading: false
            });   

            this.loadClientPosm();

        }
    }

        
    render() {

        let views = [];

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
        

        this.state.posms.forEach((p, index) => {  
           views.push(<div key={"posm_" + index} className="col-lg-4 col-md-4 col-xs-12" ><dl className="dlist-inline">
                <dt><input type="checkbox" disabled="disabled" checked={this.state.posmSelected.filter(x => x.posmId == p.id).length === 0 ? "" : "checked" } />&nbsp;{p.libelle}</dt>             
            </dl></div>)
        });


        if(this.state.posms.length === 0){
           return <div></div>
        }
       

        return (          
            <div>
                <h4 className="wow titre_lib fadeInUp">Posm</h4> 
                <hr className="style wow fadeInUp" data-wow-delay="0.2s" />
                <div className="row">{views}</div>
            </div>          
        )

    }

}



export default withRouter(V_Posm);
