import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select,notification} from 'antd';
import { exeRequest } from '../../../util/APIUtils';
import { withRouter } from 'react-router-dom';
import { STATUS_CODE_REST_API, STATUS } from '../../../constants';

//const { Option } = Select;

class DrawerForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
      visible: false,
      btnLoading: false,
      currentItem : null,
      btnLoading : false,
    };
  //this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillReceiveProps({visible, currentItem}) {
    this.setState({ visible : visible ? visible  : this.state.visible }); 
    this.setState({ currentItem : currentItem }); 
    //console.log("testeur de maggie ", currentItem)
    //if(cu)
    //this.setState({ files : currentItem && this.state.files.length == 0 ? [{url : GET_USERS_FILES_CTR + currentItem.username + ".png",uid: currentItem.id, name: 'image.png',status: 'done' }] : this.state.files }); 
     }
     
 

  componentDidMount() {
    this.setState({ visible: false });
  }

  componentDidUpdate(prevProps, prevState) {

    if (prevState.visible !== this.state.visible) {
       if(this.state.visible){this.props.form.resetFields()}
    }
  }

  onClose = () => {

    this.setState({
      visible: false,
      btnLoading: false,
      currentItem : null
    });

    //this.props.history.push(process.env.PUBLIC_URL + "/param/cat/list");
    //window.location.href = process.env.PUBLIC_URL + "/param/cat/list";

  };
  
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ btnLoading: true });
        const objRequest = Object.assign({}, values);
        console.log(objRequest);
        this.setState({
          btnLoading: true
        })

        exeRequest("/vmentions", (objRequest.id && objRequest.id !== 0 ? "PUT" : "POST") , JSON.stringify(objRequest), function (response){
          
          if (response.statusCode == STATUS_CODE_REST_API[1].code) {
            notification.success({
              message: 'GeoCRM App',
              description: 'Mention enregistrée avec succès'
            });
            this.onClose();
            window.location.reload(true);
            //window.location.href = process.env.PUBLIC_URL + "/param/cat/list"
            //this.props.history.push(process.env.PUBLIC_URL + "/param/cat/list")
            
          } else {

          notification.error({
            message: 'GeoCRM App',
            description: "Une erreur est survenue lors de l'enregistrement de la mention ",
            btnLoading:false
            
          });

          this.setState({
            btnLoading: false
          })
        }

        }, this)
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer 
          title="Ajout de mention"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

          {getFieldDecorator('id', {
            initialValue : this.state.currentItem ? this.state.currentItem.id : null,           
          })(<Input type="hidden"   />)}

           {getFieldDecorator('status', {
            initialValue : this.state.currentItem ? this.state.currentItem.status : STATUS[1].value,           
          })(<Input type="hidden"   />)}


            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Code">
                  {getFieldDecorator('code', {
                    rules: [{ required: true, message: 'Veuillez saisir le code de la mention' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.code : "", 
                  })(<Input placeholder="Entrez le code de la mention" />)}
                </Form.Item>
              </Col>
            </Row>


            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Libelle">
                  {getFieldDecorator('libelle', {
                    rules: [{ required: true, message: 'Veuillez saisir le libellé de la mention' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.libelle : "", 
                  })(<Input placeholder="Entrez le libelle de la mention" />)}
                </Form.Item>
              </Col>
            </Row>

            <div
              style={{
                position: 'absolute',
                left: 0,
                bottom: 0,
                width: '100%',
                borderTop: '1px solid #e9e9e9',
                padding: '10px 16px',
                background: '#fff',
                textAlign: 'right',
              }}
            >
              <Button onClick={this.onClose} style={{ marginRight: 8 }} size="small">
                Annuler
            </Button>
              <Button type="primary" loading={this.state.btnLoading} htmlType="submit" size="small">
                Enregistrer
            </Button>
            </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}

const V_Mention_Add = Form.create()(DrawerForm);

export default withRouter(V_Mention_Add);

//export default ADrawerForm;

