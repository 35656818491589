import React, { Component } from 'react';
import { exeRequest} from '../util/APIUtils';
import LoadingIndicator  from '../common/LoadingIndicator';
import { Button, Popconfirm, Alert, notification, Progress } from 'antd';
import {STATUS_CODE_REST_API} from '../constants';

class PConsolidate extends Component {

    constructor(props) {
        super(props);
        console.log("USER CONNECTED ______________",this.props.authenticated);

      this.state = {
          isLoading: false,
          btnLoading : false         
      };

    this.consolidate = this.consolidate.bind(this);
      
   }

    consolidate(){

      this.setState({
        isLoading: true,
        btnLoading : true         
      });

      //return false;

      exeRequest("/clients/consolidate", "GET", null, function(response){
        
        this.setState({
          isLoading: false,
          btnLoading : false         
         });

      if(response.statusCode == STATUS_CODE_REST_API[1].code){

        notification.success({
              message: 'GeoCRM App',
              description: 'Consolidation des clients avec succès ... '
          });            
       
      }else{
     
          notification.error({
              message: 'GeoCRM App',
              description: 'Une erreur est survenue lors de la consolidation des clients ...'
          });  

      }

      }, this);



    }  


    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                     <h5 className="card-title titre_lib" ><strong>Consolidation des clients</strong></h5>
                       <div className="row">
                         <center className="col-md-12">

                          <div>
                            <Progress type="circle" percent={100} />
                          </div>

                          <Popconfirm
                                  title="Confirmez-vous cette action ?"
                                  onConfirm={()=> this.consolidate()}
                                  okText="OUI"
                                  cancelText="NON" >
                              <Button type="primary" style={{marginTop : 20}} loading={this.state.btnLoading} htmlType="button" size="200" icon="check" >Lancer la consolidation</Button>
                          </Popconfirm> 
                         </center>
                      </div>
                     </div>
                  </div>      

               { /*<div className="container-fluid fixed-top bg-dark py-3">
                  <div className="row collapse show no-gutters d-flex h-100 position-relative">
                      <div className="col-3 px-0 w-sidebar navbar-collapse collapse d-none d-md-flex">
                         
                      </div>
                      <div className="col px-3 px-md-0">
                         
                          <a data-toggle="collapse" href="#" data-target=".collapse" role="button" className="p-1">
                              <i className="fa fa-bars fa-lg"></i>
                          </a>
                      </div>
                  </div>
        </div> */ }             
            </div>    
        );
    }
}

export default PConsolidate;




