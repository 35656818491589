import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Icon, notification } from 'antd';
import LoadingIndicator  from '../common/LoadingIndicator';
import { exeRequest } from '../util/APIUtils';
import {GET_REPORTS_FILES_CTR, GET_PDF_VIEWER} from '../constants'
import {jours} from '../util/Colors';
import {sMillier, changePage} from '../util/Helpers'
import locale from 'antd/es/date-picker/locale/fr_BE';
import DataTable from '../module/DataTable';
import moment from 'moment';

const { MonthPicker, WeekPicker } = DatePicker;
const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

class PStats extends Component {

    constructor(props) {
        super(props);
        this.state = {
           segments : [],
           produits: [],
           zones:[],
           response : [],
           isLoading : false,
           btnLoading : false
        }
      }


    componentDidMount(){
      this.loadProduits();
      this.loadZones();
    }

      
    loadProduits = () => {

        exeRequest("/produits/enable", "GET", null, function(response){
            this.setState({
                produits: response ? response : []
            })

          }, this)
    }

   loadZones = () => {

        exeRequest("/zones", "GET", null, function(response){
            this.setState({
                zones: response ? response : []
            });

          }, this)
    }

    loadSegments = (zoneId) => {

      if(zoneId){

        exeRequest("/zones/" + zoneId + "/segments", "GET", null, function(response){
            this.setState({
                segments: response ? response : []
            })
          }, this)

      }else{

        this.setState({
            segments: []
        })

    }

     this.props.form.setFields({
      segmentId: {value: ""}
     });

  }

  getEnDate = (date) =>{
      return new Date(date).toISOString().split("T")[0];
    }

  handleSubmit = (event) =>{
  
      event.preventDefault();
  
      this.props.form.validateFields((err, values) => {

        if (!err) { 
         // this.setState({ btnLoading: true });
  
          let objRequest = Object.assign({}, values);
  
          console.log(objRequest);
  
          this.setState({
            btnLoading: true
          });

          let urlC = "";

          urlC +=  (objRequest.produitId ? "produitId=" + objRequest.produitId + "&" : "");

          urlC +=  (objRequest.segmentId ? "segmentId=" + objRequest.segmentId + "&" : "");

          urlC +=  (objRequest.month ? "date=" + this.getEnDate(objRequest.month._d) : ""); 
          
         console.log(urlC);
  
         //return false;
        exeRequest("/zones/" + objRequest.zoneId + "/stats?" + urlC, "GET", null, function(response){
            this.setState({
                response: response ? response : [],
                btnLoading: false
            })
  
          }, this)

        }

      })

    }

    
    render() {
      const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <div className="card">
                <div className="card-body">
                    <h5 className="card-title titre_lib" ><strong>Statistiques</strong></h5><br />
                       <div className="row">
                         <div className="col-md-12">
                          
                         <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

                             <div className="row">

                               <div className="col-lg-3 col-sm-6 col-xs-12" >
                                 <Form.Item label="La zone : ">
                                  {getFieldDecorator('zoneId', {
                                      rules: [{ required: false, message: 'Veuillez Choisir la zone' }],
                                      initialValue : "0", 
                                    })(<Select placeholder="Veuillez choisir la zone" >
                                    <Option key={"zone_" + 0} onClick={()=>this.loadSegments("")}  value={"0"}>{"Toutes les zones"}</Option>
                                    {this.state.zones.map(( m, index) => (
                                        <Option onClick={()=>this.loadSegments(m.id)} key={"zone_" + m.id} value={m.id}>{m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                 </Form.Item>
                                </div>

                                <div className="col-lg-3 col-sm-6 col-xs-12" >
                                  <Form.Item label="Le segment : ">
                                  {getFieldDecorator('segmentId', {
                                      rules: [{ required: false, message: 'Veuillez Choisir le segment' }],
                                      initialValue : "", 
                                    })(<Select placeholder="Veuillez choisir le segment ">
                                    <Option key={"segment_" + 0} value={""}>{"Tous les segments"}</Option>
                                    {this.state.segments.map(( m, index) => (
                                        <Option key={"segment_" +m.id} value={m.id}>{jours[m.ordre -1] +  " / " + m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                    </Form.Item>
                                </div>

                                <div className="col-lg-3 col-sm-6 col-xs-12" >
                                  <Form.Item label="Le produit : ">
                                  {getFieldDecorator('produitId', {
                                      rules: [{ required: false, message: 'Veuillez Choisir le produit' }],
                                      initialValue : 0, 
                                    })(<Select placeholder="Veuillez choisir le produit" >
                                    <Option  key={"produit_" + 0} value={0}>{"Tous les produits"}</Option>
                                    {this.state.produits.map(( m, index) => (
                                        <Option key={"produit_" + m.id} value={m.id}>{m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                    </Form.Item>
                                </div>

                                <div className="col-lg-2 col-sm-6 col-xs-12">
                                  <Form.Item label="Choisir le mois : ">
                                    {getFieldDecorator('month', {
                                      rules: [{ required: true, message: 'Veuillez choisir un mois' }],
                                      initialValue : moment(new Date(), dateFormat), 
                                    })(<MonthPicker style={{width:"100%"}}  placeholder="Choisir un mois"  />)}
                                  </Form.Item>
                                </div>  

                                 <div className="col-lg-1 col-sm-12 col-xs-12" >
                                    <Button loading={this.state.btnLoading} className="mt-4 pull-right" htmlType="submit" size="large" type="primary" > Valider </Button>
                                 </div> 
                                   
                             </div>   
                           
                             <hr></hr>
                             <br/>
                             {this.state.btnLoading ? <LoadingIndicator /> :  (<Row>
                                 <Col span={24}> 
                                   <DataTable>
                                        <thead>
                                            <tr>
                                                <th style={{width:30}} >#</th>
                                                <th>{"Clients"}</th>
                                                <th style={{width:120}} >Nbre Visites</th>
                                                <th style={{width:120, textAlign : "center"}} >Nbre Achats</th>
                                                <th style={{width:120, textAlign : "center"}} >Nbre Produits</th>
                                                <th style={{width:120, textAlign : "center"}} >Chiffre Affaire</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {this.state.response.map((item,i)=>
                                          <tr key={i + "_" +  item.clientId} >
                                            <th scope="row" style={{width:30}}> {i + 1} </th>
                                            <td>
                                            <a data-url={`${process.env.PUBLIC_URL}/client/${item.clientId}`}  onClick={(e)=> changePage(e, this)}><b style={{fontSize: 18}}>{item.categorie + " " +  item.etablissement}</b></a> <br />
                                             <i style={{fontSize: 12}}>{"Zone : " + item.zone + ", Quartier : " + item.segment}</i><br />
                                             <i style={{fontSize: 12}}>{"Interlocuteur : " + item.interlocuteur + (item.telephone && ", Telephone : " + item.telephone) + ", Position : " + item.position }</i> <br />
                                             <i style={{fontSize: 12}}>{item.description}</i>
                                            </td>
                                            <td style={{width:120, textAlign : "center", verticalAlign : "middle"}}>{item.countVisit ? sMillier(item.countVisit) : "0" } </td>
                                            <td style={{width:120, textAlign : "center", verticalAlign : "middle"}}>{item.countSelling ? sMillier(item.countSelling) : "0" }</td>
                                            <td style={{width:120, textAlign : "center", verticalAlign : "middle"}}>{item.countProduit ? sMillier(item.countProduit) : "0" }</td>
                                            <td style={{width:120, textAlign : "right", verticalAlign : "middle"}} >{item.ca ? sMillier(item.ca) : "0" }</td>
                                          </tr>
                                        )
                                      }
                                    </tbody>
                                  </DataTable>
                                </Col>
                             
                             </Row>)}

                           </Form>
                          </div>
                      </div>
                     </div>
                  </div>               
            </div>                  
        );
    }

}

const AFPStats = Form.create()(PStats);

export default AFPStats;




