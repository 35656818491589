import React, { Component } from 'react';
import MainLayout from '../layout/MainLayout';
import V_Liste from './vues/V_Liste';

class PList extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.isAuthenticated);
      }


    render() {
        return (  
          <div className="card">
          <div className="card-body">
              <h5 className="card-title titre_lib" ><strong>Liste des commerciaux</strong></h5>
                  <div className="row">
                    <div className="col-md-12">
                      <V_Liste />
                    </div>
                </div>
                </div>
            </div>                    
        );
    }
}

export default PList;




