                                                                                                                                                                                                                                                                                                                                                              import React, { Component } from 'react';
import { exeRequest } from '../../../util/APIUtils';
import LoadingIndicator  from '../../../common/LoadingIndicator';
import { Button, Switch, Avatar } from 'antd';
import { GET_PRODUCTS_FILES_CTR,STATUS_CODE_REST_API, STATUS } from '../../../constants';
/*import {cusStatus} from '../../util/Colors';*/
import { withRouter } from 'react-router-dom';
import DataTable from '../../../module/DataTable';


class V_Produit_List extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            currentItem : null,
            isLoading: false,
            isActifLoading : false 
        };
        /*this.loadClientList = this.loadClientList.bind(this);
        this.handleLoadMore = this.handleLoadMore.bind(this);*/
        this.loadProduit = this.loadProduit.bind(this);
    }


    loadProduit() {

        this.setState({
            isLoading: true
          });

          exeRequest("/produits", "GET", null, function(response){

            //console.log("2222222222222222222222", response.data)

            const lists = this.state.lists.slice();  

            this.setState({
                lists: lists.concat(response ? response : []),
                page: response.page,
                size: response.size,
                totalElements: response.totalElements,
                totalPages: response.totalPages,
                last: response.last,
                isLoading: false
            })


          }, this)
        
    }


    onCheckedChange(checked, index){

        this.setState({
            isActifLoading: true
            //btnLoading : false
        });
    
        exeRequest("/produits/" + this.state.lists[index].id + "/activate/" + (checked ? STATUS[1].value : STATUS[0].value), "GET", null, function(response){
    
            this.setState({
                isActifLoading: false,
                 //btnLoading : false
             });
    
           if(response.statusCode == STATUS_CODE_REST_API[1].code){         
                let lists = this.state.lists;
                lists[index].status = (checked ? STATUS[1].value : STATUS[0].value)
                this.setState({ lists: lists });  
            }
            /*else{
                notification.error({
                    message: 'GeoCRM App',
                    description: "Une erreur est survenue lors du traitement de votre action"
                });
            }*/
    
           }, this);
    
        } 
    

    /*onEditing(item){
        this.props.onEditing(item);
    }*/
     
    componentDidMount() {
        this.loadProduit();
        //loadCategories();
    }

    componentDidUpdate(nextProps) {
        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                lists: [],
                currentItem : null,
                isLoading: false
            });    
            this.loadProduit();
        }
    }
    
    render() {
        const Views = [];
        this.state.lists.forEach((item, index) => {
                //console.log(client);
                //let color  = cusStatus[client.status.toString()]
                //let icon = this.state.catData.length !== 0 ? this.state.catData.find( c => c.id === client.categorieId).icon : "fa-map-marker"

                Views.push(

                <tr key={item.id + "___" + index} >
                    <th scope="row" style={{width:60}}>
                       <Avatar size={60} src={`${GET_PRODUCTS_FILES_CTR + item.sku}.png`}  icon="picture" /> 
                    </th>
                    <td style={{width:100}} > {item.sku ? item.sku: "" } </td>
                    <td style={{width:100}} > {item.libelle ? item.libelle: "" } </td>
                    <td style={{width:100}} > {item.description ? item.description: "" } </td>
                    <td style={{width:100}} > {item.marqueId ? item.marqueId: "" } </td>
                    <td style={{width:100}} > {item.prixQuantite ? item.quantite + " / " + item.prixQuantite: "" } </td>
                    <td style={{width:100}} > {item.cartonQuantite ? item.cartonQuantite: "" } </td>
                    <td style={{width:10}} > <Switch size="small" loading={this.state.isActifLoading ? true : false}  checked={(!item.status || item.status == 0)  ? false : true} onChange={(checked) => this.onCheckedChange(checked, index)}  /> </td>
                    <td className="actions" style={{width:30}}>
                       {/* <a href={`${process.env.PUBLIC_URL}/parametrage/cat/${item.id}`} style={{cursor: "pointer"}}  ><i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i></a>&nbsp;&nbsp;&nbsp;*/}
                        <a href={"#"} style={{cursor: "pointer"}} onClick={()=> this.props.onEditing(item)}  ><i className="fa far fa-edit fa-lg"  style={{color:"black"}} ></i></a>
                  
                    </td>
               </tr>

           )     
              
        });

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }

       return (

            <div>
                <DataTable>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Sku</th>
                        <th>libellé</th>
                        <th>description</th>
                        <th>MarqueId</th>
                        <th>Prix</th>
                        <th>Qte / Carton</th>
                        <th>Est actif</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {Views}
                </tbody>
                </DataTable>
            </div>
        );
    }
}

export default withRouter(V_Produit_List);