import React from 'react';
import {
    Route,
    Redirect
  } from "react-router-dom";
  
  
const LoginRoute = ({ component: Component, authenticated, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        !authenticated ? (
          <Component authenticated={authenticated} {...rest} {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: props.location }
            }}
          />
        )
      }
    />
);
  
export default LoginRoute