import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, DatePicker, Icon, InputNumber,notification } from 'antd';
import { exeRequest } from '../../../util/APIUtils';
import { STATUS_CODE_REST_API } from '../../../constants';

const { Option } = Select;

class DrawerForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
        visible: false,
        zones:[],
        isLoading:false,
        currentItem: null,
        btnLoading: false
    };
    
    this.handleSubmit=this.handleSubmit.bind(this)
    //this.loadZone=this.loadZone.bind(this)
  }

 componentWillReceiveProps({visible,currentItem}) {
     this.setState({ visible : visible ? visible  : this.state.visible });
     this.setState({ currentItem : currentItem }); 
   }
  
  componentDidMount() {
    this.setState({ visible : false }); 
  }
  
  componentDidUpdate(prevProps, prevState) {

     if (prevState.visible !== this.state.visible) {
        if(this.state.visible && this.state.zones.length==0){this.loadZone()}
            if(this.state.visible){this.props.form.resetFields()}
      }

  }

  loadZone() {
    this.setState({
        isLoading: true
      });

      exeRequest("/zones", "GET", null, function(response){
        this.setState({
            zones: (response ? response : []),
            isLoading: false
        })
      }, this) 
  }

  onClose = () => {
    this.setState({
      visible: false,
      btnLoading:false,
      currentItem: null
    });
  };

  handleSubmit(event) {
    event.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) { 
        this.setState({ btnLoading: true });
        const objRequest = Object.assign({}, values);
        console.log(objRequest)
        this.setState({
          btnLoading: false
        })

        exeRequest("/segments", (objRequest.id && objRequest.id !== 0 ? "PUT" : "POST") , JSON.stringify(objRequest), function (response) {
          
          if (response.statusCode == STATUS_CODE_REST_API[1].code) {
            notification.success({
              message: 'GeoCRM App',
              description: 'POSM enregistrée avec succès'
            });
            this.onClose();
            window.location.reload(true);
            //window.location.href = process.env.PUBLIC_URL + "/param/segment/list"
          } else {

          notification.error({
            message: 'GeoCRM App',
            description: "Une erreur est survenue lors de l'enregistrement du POSM",
            btnLoading:false
            
          });

          this.setState({
            btnLoading: false
          })
        }

        }, this)
      }
    });
  }


  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
       <Drawer 
          title="Ajout d'un quartier"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

          {getFieldDecorator('id', {
            initialValue : this.state.currentItem ? this.state.currentItem.id : null,           
          })(<Input type="hidden"   />)}

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Code">
                  {getFieldDecorator('code', {
                    rules: [{ required: true, message: 'Veuillez saisir le code du quartier' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.code : "", 
                  })(<Input placeholder="Entrez le code de la quartier" />)}
                </Form.Item>
              </Col>
            </Row>
         

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Libelle">
                  {getFieldDecorator('libelle', {
                    rules: [{ required: true, message: 'Veuillez saisir le libellé du quartier' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.libelle : "", 
                  })(<Input placeholder="Entrez le libelle du quartier" />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} >
                <Form.Item label="Zone ">
                {getFieldDecorator('zoneId', {
                    rules: [{ required: true, message: 'Veuillez selectionner la zone' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.zoneId : "", 
                  })(
                    <Select placeholder="Veuillez selectionner La Zone">
                         {this.state.zones.map(( z, index) => (
                      <Option key={z.id} value={z.id}>{z.libelle}</Option>
                          ))}
                    </Select>
                  )}
                  
                  </Form.Item>
                </Col>
            </Row>
            
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="L'ordre">
                  {getFieldDecorator('ordre', {
                    rules: [{ required: true, message: "Veuillez saisir l'ordre" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.ordre : "", 
                  })(<InputNumber placeholder="Entrez l'ordre'" />)}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="center Lat">
                  {getFieldDecorator('centerLat', {
                    rules: [{ required: true, message: "Veuillez saisir le center lat" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.centerLat : "", 
                  })(<InputNumber style={{width:100}} min={0} max={10} step={0.1} placeholder="Entrez le center lat" />)}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Center Lng">
                  {getFieldDecorator('centerLng', {
                    rules: [{ required: true, message: "Veuillez saisir le center Ing" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.centerLng : "", 
                  })(<InputNumber min={0} max={10} step={0.1} placeholder="Entrez le center Ing" />)}
                </Form.Item>
              </Col>
            </Row>
          
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button loading={this.state.btnLoading} htmlType="submit" type="primary">
              Enregistrer
            </Button>
          </div>
          </Form>
        </Drawer>
      </div>
    );
  }
}

const ADrawerForm = Form.create()(DrawerForm);

export default ADrawerForm;

