import React, { Component } from 'react';
import MainLayout from '../layout/MainLayout';
import V_Carte from './vues/V_Carte';
import { DatePicker, Radio } from 'antd';
import locale from 'antd/es/date-picker/locale/fr_BE';

const { MonthPicker, RangePicker, WeekPicker } = DatePicker;


class Pcarte extends Component {

    constructor(props) {
        super(props);
        console.log(this.props.isAuthenticated);
      }


    render() {
        return (
            <div>
                <div className="card">
                <div className="card-body">
                    <h5 className="card-title titre_lib" ><strong>Cartographie</strong></h5><br />
                       <div className="row">
                         <div className="col-md-12">
                           {/*<WeekPicker format={"DD/MM/YYYY"} onChange={(e, m)=> console.log("DATETIME", m)}  locale={locale} placeholder="Select Week" />*/}
                           <V_Carte />
                         </div>
                      </div>
                     </div>
                  </div>               
            </div>                  
        );
    }
}

export default Pcarte;




