import React, { Component } from 'react';
import { exeRequest} from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import scriptLoader from 'react-async-script-loader';
import {notification, Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import {GET_CLIENT_FILES_CTR} from '../../constants';
import './V_Portefolio.css';
import $ from "jquery";
import  {RegroupItem, validURL} from '../../util/Helpers'
window.$.isotope = require("isotope-layout");

class V_Portefolio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            files: [],
            isLoading: false,
            previewVisible : false,
            previewImage : {}
        };

        this.loadClientFiles = this.loadClientFiles.bind(this);
        this.initIsotope = this.initIsotope.bind(this);
        this.makeFullScreen = this.makeFullScreen.bind(this);
        //this.handleLoadMore = this.handleLoadMore.bind(this);
        //this.setMap = this.setMap.bind(this);
        
    }

    makeFullScreen(e) {
        var divObj = e.target;
      //Use the specification method before using prefixed versions
     if (divObj.requestFullscreen) {
       divObj.requestFullscreen();
     }
     else if (divObj.msRequestFullscreen) {
       divObj.msRequestFullscreen();               
     }
     else if (divObj.mozRequestFullScreen) {
       divObj.mozRequestFullScreen();      
     }
     else if (divObj.webkitRequestFullscreen) {
       divObj.webkitRequestFullscreen();       
     } else {
       console.log("Fullscreen API is not supported");
     } 

   }

    initIsotope(){

      if(!this.$el) this.$el = $(this.el);
            console.log($)
            console.log(this.$el)
             try {
                console.log("____________________________________________");
                console.log($.isotope);
                console.log("____________________________________________");

                var $container = $('.portfolioContainer'); 

                console.log("$container ===> ", $container)

                $container.isotope({
                 filter: '*',
                 animationOptions: {
                     duration: 750,
                     easing: 'linear',
                     queue: false
                 }
             });
    
               $('.portfolioFilter a').click(function(){

                   $('.portfolioFilter .current').removeClass('current');

                   $(this).addClass('current');
            
                   var selector = $(this).attr('data-filter');

                   $container.isotope({
                       filter: selector,
                       animationOptions: {
                           duration: 750,
                           easing: 'linear',
                           queue: false
                       }
                    });

                    return false;
               });
             } catch (error) {
                 console.log(error);
             } 

    }

    loadClientFiles() {
       
        this.setState({
            isLoading: true
        });

        exeRequest("/clients/" + this.props.clientId + "/files", "GET", null, function(response){

            this.setState({
                files: response ? response : [],
                isLoading: false
            })
            //this.initIsotope();
           
          }, this);


    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = (url, tag) => {
      if (url) {
        this.setState({
            previewImage: {url : url, tag : tag},
            previewVisible: true,
          });
      }   

    };

    componentDidMount() {
       this.loadClientFiles();
        //this.initIsotope()
    }

    componentDidUpdate(nextProps) {

        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                files: [],
                isLoading: false
            });   

            this.loadClientFiles();
            //this.initIsotope();
        }

    }

        
    render() {

        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
        console.log(this.state.files);
        let data = RegroupItem(this.state.files, "tagLib")
        let filterView = [];
        let imageView = [];

        data.forEach((tag, indexP) => {  
            let classe = "class_" + indexP;
            filterView.push(<a key={indexP} href="#" data-filter={`.${classe}`}>{tag.fieldValue}</a>)
            tag.items.forEach((file, index) => {  
             let url = GET_CLIENT_FILES_CTR + file.name
             //validURL(file.name) ? file.name : "data:image/png;base64," + file.name
             imageView.push(<div  key={indexP + "_" + index} className={`${classe}`}>
                    <img onClick={()=> this.handlePreview(url, file.tagLib)} src={`${url}`} alt="image" />
             </div>)
            });
        });

        if(this.state.files.length === 0){
           return <div></div>
        }
       

        return (
            <div className="portofolio" id="portofolio">
            <div>
                <h4 className="wow titre_lib fadeInUp">Portofolio</h4> 
               {/* <h3 className="text wow fadeInUp" data-wow-delay="0.4s">
                Nous vous présentons GCT en quelques images et vidéos</h3> */}
                <hr className="style wow fadeInUp" data-wow-delay="0.2s" />
                <div className="row">
                    <div className="col-lg-12">
                    <div className="portfolioFilter clearfix">
                      <a href="#" data-filter="*" className="current">Toutes les photos</a>
                      {filterView}
                    </div>
                    </div>
                    <div className="portfolioContainer"  ref={el => this.el = el} >
                      {imageView}
                    </div>
                </div>
            </div>
             <Modal visible={this.state.previewVisible} footer={this.state.previewImage.tag ? this.state.previewImage.tag .toUpperCase() : ""} onCancel={this.handleCancel}>
               <img alt="example" style={{ width: '100%' }} src={this.state.previewImage.url}   />
             </Modal>
            </div> 
        )
    }

}



export default withRouter(V_Portefolio);
