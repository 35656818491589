import React, { Component } from 'react';
import { getAllClients, exeRequest} from '../../util/APIUtils';
import LoadingIndicator  from '../../common/LoadingIndicator';
import { notification, Drawer, Button, Icon } from 'antd';
import { CLIENT_LIST_SIZE } from '../../constants';
import { withRouter } from 'react-router-dom';
import Map  from '../../module/Map';
import DataTable from '../../module/DataTable';
import {cusStatus} from '../../util/Colors';
import { changePage } from '../../util/Helpers';
import DDataTable from '../../module/DDataTable';
import './V_Carte.css';

class V_Carte extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            currentClient: null,
            map : null,
            isLoading: false,
            visible : false,
            currentPosition : null
        };
     
    }

    
    getData = (clients) =>{
        this.setState({
            clients: clients
          });
    }

    componentDidMount() {
        //this.loadClientList();
    }

    componentDidUpdate(nextProps) {

        if(this.props.isAuthenticated !== nextProps.isAuthenticated) {
            // Reset State
            this.setState({
                clients: [],
                currentClient : null,
                map : null,
                isLoading: false
            });   

            //this.loadClientList();
        }
    }

    showDrawer = () => {
        this.setState({
          visible: true,
        });
      };
    
      onClose = () => {
        this.setState({
          visible: false,
        });
      };

    handleSelectedClient(index){
    console.log(index);
    //let client = this.state.clients[index];
    this.setState({ currentClient: index, currentPosition : { lat : index.coord_lat_reel, lng : index.coord_lng_reel } });    
    //console.log(this.state.currentPosition);
    }

    
    render() {
        if(this.state.isLoading) {
            return <LoadingIndicator />
        }
        return <MainView currentPosition={this.state.currentPosition} clients={this.state.clients}  that={this} />
    }
}


function MainView(props){
    //const clientListViews = [];
    //console.log(this.state.clients);
    //this.state.clients
    const data = props.clients;
    const that = props.that;

    const columns = [
        {
          title: '#',
          key: '#',
          render: (text, record) => (
            <i className={`fa far ${record.categorieIcon ? record.categorieIcon : "" } fa-map-marker bgr`}  style={{backgroundColor: "#" + cusStatus[record.estDesactive ? "0" : record.status.toString()]}} ></i>          
          ),
        },
        {
          title: 'Clients',
          className:'liclient',
          key: 'clients',
          render: (text, record) => (
            <div>
              <strong style={{fontSize:16}} >{record.etablissement ? record.etablissement : "" }</strong> <br />
              <strong className="nomclt">{record.categorieLib ? record.categorieLib : "" }</strong>  &nbsp;|&nbsp; <span className="date">{record.lastOperation ? new Date(record.lastOperation).toLocaleString("fr-FR") : "Jamais visité" }</span>
            </div>
          ),
          onFilter: (value, record) => record.etablissement.indexOf(value) === 0,
          sorter: (a, b) => a.etablissement.length - b.etablissement.length,
          sortDirections: ['descend'],
        },
        {
           title: 'Actions',
           className:'actions',
           key: 'actions',
           width: 80,
            render: (text, record) => (
              <div>
                 <a data-url={`${process.env.PUBLIC_URL}/client/${record.id}`}  onClick={(e)=> changePage(e, that)}  style={{cursor: "pointer"}}  ><i className="fa far fa-eye fa-lg"  style={{color:"black"}} ></i></a>&nbsp;&nbsp;&nbsp;
                 <a  style={{cursor: "pointer"}} onClick={() => that.handleSelectedClient(record)} ><i className="fa far fa-map-marker fa-lg"  style={{color:"black"}} ></i></a>
              </div>
            ),
          },
      ];

     
   return (  <div className="row" >

        {/*<div className="col-md-3"></div>*/}

      <div className="col-md-12 col-lg-12 col-12"  style={{marginTop:-35}} >

      <Button type="primary" onClick={that.showDrawer} style={{ position:"absolute", right : 0, top : "-35px"  }}>
          <Icon type={that.state.visible ? 'menu-unfold' : 'menu-fold'} />
        </Button>
                <Drawer
                title="Liste des clients"
                placement="right"
                closable={false}
                onClose={that.onClose}
                visible={that.state.visible}
                width={700}>

                <DDataTable size={"middle"} pageSize={CLIENT_LIST_SIZE} pagination={{pageSize : 7, position : "top"}}  url={"/clients/search"} pageSizeOptions={['100', '200', '300', '400', '500', '1000']} columns={columns} getData={that.getData} />

                </Drawer>
                    <Map hideLegende={false} hideInfoWindow={false} data={data} polyline={false}  position={props.currentPosition} onError={() => notification.error({
                        message: 'GeoCRM App',
                        description:'Error ! Impossible de charger la carte google map ! Verifiez votre connexion internet.'
                    })}  />
          </div>   
     </div>
  );

}

export default withRouter(V_Carte);

V_Carte.defaultProps = {
    dtInit : {
        "lengthChange": false,
        "lengthMenu": [ 7, 14, 21, 28, 35]
      }
}

