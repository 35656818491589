
import React, { Component } from 'react';
import { exeRequest} from '../util/APIUtils';
import {sMillier, changePage} from '../util/Helpers'
import { Button, Icon, notification } from 'antd';


class Dashbord extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mDashbord : null,
            setIntervalId : null
        };

        this.loadMdashbord = this.loadMdashbord.bind(this);
               
    }

    loadMdashbord() {

        exeRequest("/reportings/mdashbord", "GET", null, function(response){

            this.setState({
                mDashbord: response ? response : null
            });
          
          }, this);

    }

    setIntervalId= setInterval(() => {
       this.loadMdashbord();
       //console.log("MMMMDDMDMDMDMD")
    }, 150000);

    //300000

    componentWillUnmount(){      
        clearInterval(this.state.setIntervalId);
    }

    componentDidMount() {

        this.loadMdashbord();

    }

    render() {

     const {mDashbord} = this.state;

        return (
            <div className="information">
             <div className="container-fluid">
              <div className="row">
                <div className="col-lg-2" id="logo_acceuil">
                    <img src={`${process.env.PUBLIC_URL}/images/logo_prosuma_acceuil.png`}  id="logo" alt="" />
                </div>
                <div className="col-lg-10">
                <div className="row">
                    <div className="col-lg-3 col-sm-6 col-xs-12" data-url="/client/list" onClick={(e)=> changePage(e, this)} style={{cursor : "pointer"}}>
                        <div className="main-box infographic-box" id="box1">
                            <i className="fa fa-users bg-radious-white c"></i>
                            <span className="headline">Clients</span>
                            <span className="value">{mDashbord ?  sMillier(mDashbord.countClients) : 0}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="main-box infographic-box"  id="box2" data-url="/user/list" onClick={(e)=> changePage(e, this)} style={{cursor : "pointer"}}  >
                            <i className="fa fa-user bg-radious-white f"></i>
                            <span className="headline">Commerciaux</span>
                            <span className="value">{mDashbord ?  sMillier(mDashbord.countSellers) : 0}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="main-box infographic-box " id="box3" data-url="/user/objectif" onClick={(e)=> changePage(e, this)} style={{cursor : "pointer"}} >
                            <i className="fa fa-bullseye bg-radious-white l"></i>
                            <span className="headline">Objectifs</span>
                            <span className="value">xx{/*mDashbord ?  sMillier(mDashbord.countGoals) : 0*/}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6 col-xs-12">
                        <div className="main-box infographic-box " id="box4" data-url="/zone/stats" onClick={(e)=> changePage(e, this)} style={{cursor : "pointer"}} >
                            <i className="fa fa-pie-chart bg-radious-white r"></i>
                            <span className="headline">Statistiques</span>
                             <span className="value">xx{/*mDashbord ?  sMillier(mDashbord.countStats) : 0*/}</span>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
      </div>
        );
    }

}

export default Dashbord;

