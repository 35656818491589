import React, { Component } from 'react';
import { exeRequest } from '../util/APIUtils';
import LoadingIndicator  from '../common/LoadingIndicator';
import { Popconfirm, InputNumber, Input,Select, Form, notification, Button, DatePicker, Col, Row } from 'antd';
import { API_BASE_URL,COMM_ROLE, STATUS_CODE_REST_API } from '../constants';
/*import {cusStatus} from '../../util/Colors';*/
import {jours} from '../util/Colors';
import {sMillier, changePage, RegroupItem} from '../util/Helpers'
import { withRouter } from 'react-router-dom';
import DataTable from '../module/DataTable';
import moment from 'moment';


const { Option } = Select;
const dateFormat = "YYYY-MM-DD";
const { RangePicker } = DatePicker;

class PEnrollments extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            categories : [],
            zones : [],
            segments  : [],
            allSegments  : [],
            users: [],
            isLoading: false,
        };       
    }


  loadClients = (event) =>{
  
    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err){ 
       // this.setState({ btnLoading: true });

        let objRequest = Object.assign({}, values);

        console.log(objRequest);

        let date = objRequest.rangeDate
      
         let startDate = new Date(date[0]._d.getFullYear(), date[0]._d.getMonth(), date[0]._d.getDate(), 0, 1, 1) 
         let endDate = new Date(date[1]._d.getFullYear(), date[1]._d.getMonth(), date[1]._d.getDate(), 23, 59, 59);

         this.setState({
           isLoading: true
          });

          console.log("objRequest ===>>> ",objRequest)

          exeRequest("/clients/enrollements?startDate=" + startDate.toISOString() + "&endDate=" + endDate.toISOString() + "&segmentId=" + objRequest.segmentId + "&zoneId=" + objRequest.zoneId, "GET", null, function(response){
            this.setState({
                clients: response ? response : [],
                isLoading: false,
                //btnLoading : false
            });

          }, this)

        }

      });

  }
        
  loadCategories = () =>{
    exeRequest("/categories", "GET", null, function(response){
      this.setState({
        categories: response ? response : []
      });
    }, this)
  }

  loadZones = () =>{
    exeRequest("/zones", "GET", null, function(response){
      this.setState({
        zones: response ? response : []
      });
    }, this)
  }

  loadAllSegments = () => {

      exeRequest("/segments", "GET", null, function(response){
          this.setState({
              allSegments : response ? response : []
          })
        }, this)
        
  }
 
  loadSegments = (zoneId) => {

    if(zoneId){

      exeRequest("/zones/" + zoneId + "/segments", "GET", null, function(response){
          this.setState({
              segments: response ? response : []
          })
        }, this)

    }else{

      this.setState({
          segments: []
      })

  }

   this.props.form.setFields({
    segmentId: {value: "0"}
   });

}
  
  loadUsers = () => {
    exeRequest("/users/getByRoles/" + COMM_ROLE, "GET", null, function(response){
      this.setState({
        users: response ? response : []
      });

    }, this)
  
}

   componentDidMount() {
      this.loadCategories();
      this.loadZones();
      this.loadUsers();
      this.loadAllSegments();
    }

     
    render() {
        const {getFieldDecorator} = this.props.form;
          return (
              <div>
                  <div className="card">
                  <div className="card-body">
                      <h5 className="card-title titre_lib" ><strong>Les clients par date enrolement</strong></h5><br />
                         <div className="row">
                           <div className="col-md-12">
                            
                           <Form layout="vertical" hideRequiredMark onSubmit={this.loadClients}>
  
                               <div className="row">

                                  <div className="col-lg-4 col-sm-12 col-xs-12" >
                                    <Form.Item label="Intervalle de dates : ">
                                      {getFieldDecorator('rangeDate', {
                                          rules: [{ required: true, message: 'Veuillez choisir une intervalle de date' }],
                                          initialValue : [moment(new Date(), dateFormat), moment(new Date(), dateFormat)], 
                                        })(<RangePicker style={{width:"100%"}}  placeholder="Choisir une intervalle de date" />)}                                 
                                      </Form.Item>
                                  </div>

                                  <div className="col-lg-3 col-sm-12 col-xs-12" >
                                 <Form.Item label="La zone : ">
                                  {getFieldDecorator('zoneId', {
                                      rules: [{ required: false, message: 'Veuillez Choisir la zone' }],
                                      initialValue : "0", 
                                    })(<Select placeholder="Veuillez choisir la zone" >
                                    <Option key={"zone_" + 0} onClick={()=>this.loadSegments("")}  value={"0"}>{"Toutes les zones"}</Option>
                                    {this.state.zones.map(( m, index) => (
                                        <Option onClick={()=>this.loadSegments(m.id)} key={"zone_" + m.id} value={m.id}>{m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                 </Form.Item>
                                </div>

                                <div className="col-lg-3 col-sm-12 col-xs-12" >
                                  <Form.Item label="Le segment : ">
                                  {getFieldDecorator('segmentId', {
                                      rules: [{ required: false, message: 'Veuillez Choisir le segment' }],
                                      initialValue : "0", 
                                    })(<Select placeholder="Veuillez choisir le segment ">
                                    <Option key={"segment_" + 0} value={"0"}>{"Tous les segments"}</Option>
                                    {this.state.segments.map(( m, index) => (
                                        <Option key={"segment_" +m.id} value={m.id}>{jours[m.ordre -1] +  " / " + m.libelle}</Option>
                                    ))}
                                  </Select>)}                                 
                                    </Form.Item>
                                </div>

                                <div className="col-lg-2 col-sm-12 col-xs-12" >
                                    <Button loading={this.state.isLoading} className="mt-4 pull-right" htmlType="submit" size="large" type="primary" > Valider </Button>
                                </div>
                                     
                               </div>   
                             
                               <hr></hr>
                               <br/>
                               {this.state.isLoading ? <LoadingIndicator /> :  (<Row>
                                 <Col span={24}>{this.state.clients.length > 0 && <div className="pull-right" style={{backgroundColor:"red", color:"white", padding:20, marginBottom:20}} >{this.state.clients.length} Clients enrollés</div>}</Col>
                                   <Col span={24}> 
                                       <DataTable >
                                          <thead>
                                              <tr>
                                                 <th style={{width:40}} >#</th>
                                                 <th>Etablissement</th>
                                                 <th style={{width:200, textAlign : "center", verticalAlign : "middle"}} >enrôleur(Commercial)</th>
                                                 <th style={{width:200, textAlign : "center", verticalAlign : "middle"}}>Date de creation</th>
                                                 
                                                </tr>
                                          </thead>
                                          <tbody>
                                          {this.state.clients?.map((item,i)=>
                                            <tr key={i + "_" +  item.id} >
                                               <th scope="row" style={{textAlign : "center", verticalAlign : "middle", width:30}}> {i + 1} </th>
                                               <td style={{verticalAlign : "middle"}} >
                                               <a data-url={`${process.env.PUBLIC_URL}/client/${item.id}`}  onClick={(e)=> changePage(e, this)}>
                                                <b style={{fontSize: 18}}>{this.state.categories.filter(x=> x.id == item.categorieId)[0].libelle + " " +  item.etablissement}</b></a> <br />
                                                <i style={{fontSize: 12}}>{"Zone : " + this.state.zones.filter(x=> x.id == item.zoneId)[0].libelle + ", Quartier : " + this.state.allSegments.filter(x=> x.id == item.segmentId)[0].libelle}</i><br />
                                                <i style={{fontSize: 12}}>{"Interlocuteur : " + item.interlocuteur + (item.telephone && ", Telephone : " + item.telephone) + ", Position : " + item.position }</i> <br />
                                                <i style={{fontSize: 12}}>{item.description}</i>
                                              </td>
                                              <td style={{verticalAlign : "middle"}} >
                                                {this.state.users.filter(x=> x.id == item.createdBy)[0].name}
                                               </td>
                                              <td style={{verticalAlign : "middle"}} >
                                                {new Date(item.createdAt).toLocaleString("fr-FR")}
                                               </td>
                                            </tr>                                                                               
                                          )
                                        }
                                      </tbody>
                                    </DataTable>
                                  </Col>
                                </Row>)}
                             </Form>
                            </div>
                        </div>
                       </div>
                    </div>               
              </div>                  
          );
      }
  

   
}

const A_PEnrollments = Form.create()(PEnrollments);

export default withRouter(A_PEnrollments);