import React, { Component } from 'react';
import { Button, Icon } from 'antd';
import V_Mention_List from './vues/V_Mention_List';
import V_Mention_Add from './vues/V_Mention_Add';


class P_Mention_List extends Component {
    constructor(props) {
        super(props);
         //console.log("USER CONNECTED ______________",this.props.authenticated);
        //console.log(typeOf(this.props.handleLogout));
            this.state = {
              visible: false,
              currentItem : null 
          };
      
      }

      showDrawer = (item) => {
        this.setState({
          visible: true,
          currentItem : item 
        });
      };


    render() {
        return (
            <div >
                 <div className="card">
                   <div className="card-body">
                       <h5 className="card-title titre_lib" >
                         <strong>Liste des mentions</strong>
                             <Button type="primary" className="pull-right" onClick={() => this.showDrawer(null)}>
                               <Icon type="plus" /> Ajouter
                             </Button>
                       </h5>
                       <div className="row">
                         <div className="col-md-12" style={{marginTop:"30px"}}> 
                            <V_Mention_List onEditing={this.showDrawer} />                        
                            <V_Mention_Add visible={this.state.visible} currentItem={this.state.currentItem} />
                         </div>
                      </div>
                     </div>
                  </div>              
            </div>    
        );
    }
}

export default P_Mention_List;




