import React, { Component } from 'react';
import { exeRequest } from '../util/APIUtils';
import LoadingIndicator  from '../common/LoadingIndicator';
import { Popconfirm, InputNumber, Input,Select, Form, notification, Button, DatePicker, Col, Row } from 'antd';
import { API_BASE_URL,COMM_ROLE, STATUS_CODE_REST_API } from '../constants';
/*import {cusStatus} from '../../util/Colors';*/
import {sMillier, changePage, RegroupItem} from '../util/Helpers'
import { withRouter } from 'react-router-dom';
import DataTable from '../module/DataTable';
import moment from 'moment';


const { Option } = Select;
const dateFormat = "YYYY-MM-DD";

class PPatchSelling extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sellings: [],
            products:[],
            categories:[],
            isLoading: false,
            users : [],
            btnLoading : false
        };       
    }


    loadProducts = () => {

          exeRequest("/produits", "GET", null, function(response){

            this.setState({
              products: response ? response : []
            });

          }, this)
        
    }


    loadCategories = () => {

      exeRequest("/categories", "GET", null, function(response){

        this.setState({
          categories: response ? response : []
        });

      }, this)
    
}

    loadUsers = () => {
        exeRequest("/users/getByRoles/" + COMM_ROLE, "GET", null, function(response){
          this.setState({
            users: response ? response : []
          });

        }, this)
      
  }


    loadSelling = (event) => {

       event.preventDefault();
  
        this.props.form.validateFields((err, values) => {
  
          if (!err) { 

         let objRequest = Object.assign({}, values);

         console.log(objRequest.date);

         this.setState({
            btnLoading: true
          });

          exeRequest("/users/" + objRequest.userId + "/sellingDetails?date=" + objRequest.date._d.toISOString().split("T")[0], "GET", null, function(response){
            this.setState({
                sellings: response ? response : [],
                btnLoading: false,
                //btnLoading : false
            });

          }, this)
        }

     })

  }
        
  getProductName = (id) => {
    console.log("this.state.products ==>>", this.state.products);
    let filter = this.state.products.filter(x => x.id == id);
    console.log("filter filter ===<> ", filter)
    return filter.length > 0 ? filter[0].sku + " - " +  filter[0].libelle : ""
  } 


  getCategoriesName = (id) => {
    console.log("this.state.products ==>>", this.state.categories);
    let filter = this.state.categories.filter(x => x.id == id);
    console.log("filter filter ===<> ", filter)
    return filter.length > 0 ? filter[0].libelle : ""
  } 

  
    onQteChange = (e, index) =>{
      //alert(e + "_"  + index);
      const lists = this.state.lists;
      lists[index].qte = e
      this.setState({ lists: lists });       
    } 

    validatePatch = () =>{

          let {sellings} = this.state;
          /*let itemIndex = sellings.findIndex((elt) => elt.id == item.id)
          console.log(document.getElementById("input_patch_selling_" + item.id).value);
          let unitPrice = (item.montant / item.nbreProduit)
          sellings[itemIndex].nbreProduit = document.getElementById("input_patch_selling_" + item.id).value;
          sellings[itemIndex].montant = Math.round(unitPrice * document.getElementById("input_patch_selling_" + item.id).value);*/

          this.setState({
            btnLoading: true
          });

        exeRequest("/operations/patchSellingDetails", "PUT", JSON.stringify(sellings), function(response){

          this.setState({
            btnLoading: false
          });

            console.log(response);

            if(response.statusCode == STATUS_CODE_REST_API[1].code){

                notification.success({
                    message: 'GeoCRM App',
                    description: 'Vente corrigée avec succès ...'
                });      
                
            }else{

             let values = response.message;
             if(Array.isArray(values)){

              
              let names = "";

              values.forEach(item => {

                    let index = sellings.findIndex(x => x.id == item.id)
                    sellings.splice(index, 1 , item);
                    let s = this.getProductName(item.produitId);
                    names += !names.includes(s) ? (names ?  " / " : "") + s : "" ;

                });

                this.setState({
                  sellings : sellings
                });

                notification.error({
                    message: 'GeoCRM App',
                    description: 'Les produits suivants : '+ names  +' sont en rupture de stock'
                }); 


              }else{

                 notification.error({
                    message: 'GeoCRM App',
                    description: 'Une erreur est survenue lors de la correction de la vente'
                }); 

              } 

            }

          }, this);
    }
 
    onConfirm=(item)=>{
      let {sellings} = this.state;
      let itemIndex = sellings.findIndex((elt) => elt.id == item.id)
      console.log(document.getElementById("input_patch_selling_" + item.id).value);
      let unitPrice = (item.montant / item.nbreProduit)

      sellings[itemIndex].nbreProduit = document.getElementById("input_patch_selling_" + item.id).value;
      sellings[itemIndex].montant = Math.round(unitPrice * document.getElementById("input_patch_selling_" + item.id).value);

      this.setState({
        sellings : sellings
        });
   }
   
    componentDidMount() {
        this.loadProducts();
        this.loadCategories();
        this.loadUsers();
    }

     
    render() {
        const { getFieldDecorator } = this.props.form;
        const sellingData = RegroupItem(this.state.sellings, "produitId")
        console.log("sellingData", sellingData)
          return (
              <div>
                  <div className="card">
                  <div className="card-body">
                      <h5 className="card-title titre_lib" ><strong>Correction des ventes</strong></h5><br />
                         <div className="row">
                           <div className="col-md-12">
                            
                           <Form layout="vertical" hideRequiredMark onSubmit={this.loadSelling}>
  
                               <div className="row">
 
                                  <div className="col-lg-4 col-sm-6 col-xs-12" >
                                    <Form.Item label="Commercial : ">
                                    {getFieldDecorator('userId', {
                                        rules: [{ required: true, message: 'Veuillez Choisir le commercial' }],
                                        initialValue : "", 
                                      })(<Select placeholder="Veuillez choisir le commercial ">
                                      <Option key={"segment_" + 0} value={""}>{"Choisir le commercial"}</Option>
                                      {this.state.users.map(( m, index) => (
                                          <Option key={"users_" +m.id} value={m.id}>{m.name}</Option>
                                      ))}
                                    </Select>)}                                 
                                      </Form.Item>
                                  </div>
    
                                  <div className="col-lg-3 col-sm-6 col-xs-12">
                                    <Form.Item label="Choisir la date : ">
                                      {getFieldDecorator('date', {
                                        rules: [{ required: true, message: 'Veuillez choisir une date' }],
                                        initialValue : moment(new Date(), dateFormat), 
                                      })(<DatePicker style={{width:"100%"}}  placeholder="Choisir une date"  />)}
                                    </Form.Item>
                                  </div>  
  
                                   <div className="col-lg-2 col-sm-12 col-xs-12" >
                                      <Button loading={this.state.btnLoading} className="mt-4 pull-right" htmlType="submit" size="large" type="primary" > Valider </Button>
                                   </div> 
                                     
                               </div>   
                             
                               <hr></hr>
                               <br/>
                               {this.state.btnLoading ? <LoadingIndicator /> :  (<Row>
                                 <Col span={24}>{this.state.sellings.length > 0 && <div className="pull-right" style={{backgroundColor:"red", color:"white", padding:20, marginBottom:20}} >CA :  {sMillier(this.state.sellings.map(function(a){ return a.montant}).reduce(function(a, b){return a + b}, 0))} FCFA</div>}</Col>
                                   <Col span={24}> 
                                       <DataTable jsonInit={{"lengthMenu": [[1, 2, 3, -1], [1, 2, 3, "All"]]}} >
                                          <thead>
                                              <tr>
                                                  <th style={{width:40}} >#</th>
                                                  <th style={{width:"30%"}}>{"Produit"}</th>
                                                  <th style={{textAlign : "center"}} >Details</th>
                                                </tr>
                                          </thead>
                                          <tbody>
                                          {sellingData?.map((item,i)=>

                                            <tr key={i + "_" +  item.id} >
                                               <th scope="row" style={{textAlign : "center", verticalAlign : "middle", width:30}}> {i + 1} </th>
                                               <td style={{textAlign : "center", verticalAlign : "middle"}} >
                                                 <b>{this.getProductName(item.fieldValue)}</b><br/>
                                                 <i>{item.items.length > 0 ? sMillier(item.items.map(function(a){ return a.montant}).reduce(function(a, b){return a + b}, 0)) + " FCFA" : "0" }</i>
                                               </td>
                                               <td>
                                                 {item.items?.map((sitem,j)=>
                                                    <div  key={j + "_sitem_" +  sitem.id}  className="row" style={{padding : 7}}>
                                                      <div className="col-lg-7 col-sm-12 col-xs-12" style={{borderBottom : "1px solid"}}><i><b>{(this.getCategoriesName(sitem.clientCategorieId)) + " " +  (sitem.client && sitem.client)} </b> {"(" + (sitem.clientInterlocuteur && sitem.clientInterlocuteur) + " / " + (sitem.clientIndication && sitem.clientIndication) + ")"}</i></div>
                                                      <div className="col-lg-2 col-sm-12 col-xs-12" style={{borderBottom : "1px solid"}} >
                                                      <center>
                                                      <Popconfirm
                                                          icon={null}
                                                          title={(<InputNumber id={"input_patch_selling_" + sitem.id} defaultValue={sitem.nbreProduit}  step={1}  style={{width: "100%"}} min={0}  />)}
                                                          onConfirm={()=> this.onConfirm(sitem)}
                                                          okText="OUI"
                                                          cancelText="NON" >
                                                          <a href="#">{sitem.nbreProduit ? sMillier(sitem.nbreProduit) : "0" }</a>
                                                         </Popconfirm>
                                                         </center>
                                                      </div>
                                                      <div className="col-lg-3 col-sm-12 col-xs-12" style={{borderBottom : "1px solid"}}><center>{sitem.montant ? sMillier(sitem.montant) + " FCFA" : "0" }</center></div>
                                                    </div>
                                                  )
                                                }
                                             </td>
                                            </tr>                                                                               
                                          )
                                        }
                                      </tbody>
                                    </DataTable>
                                  </Col>
                                  <Col span={24}> 
                                    <Popconfirm
                                          title="Confirmez-vous cette action ?"
                                          onConfirm={()=> this.validatePatch()}
                                          okText="OUI"
                                          cancelText="NON" >
                                      <Button type="primary" style={{marginTop : 20}} loading={this.state.btnLoading} htmlType="button" size="large" icon="check" className="pull-right">Appliquer</Button>
                                     </Popconfirm> 
                                   </Col>
                               </Row>)}
  
                             </Form>
                            </div>
                        </div>
                       </div>
                    </div>               
              </div>                  
          );
      }
  
   
}


const A_PPatchSelling = Form.create()(PPatchSelling);

export default withRouter(A_PPatchSelling);