import React, { Component } from 'react';
import { Drawer, Form, Button, Col, Row, Input, Select, message, Icon, InputNumber,notification,Radio } from 'antd';
import { exeRequest } from '../../../util/APIUtils';
import  FileUpload from  '../../../module/FileUpload';
import { GET_PRODUCTS_FILES_CTR,STATUS_CODE_REST_API, STATUS } from '../../../constants';
/*import {cusStatus} from '../../util/Colors';*/

const { Option } = Select;

class DrawerForm extends Component {
  constructor(props) {

    super(props);

    this.state = {
        visible: false,
        marques:[],
        isLoading:false,
        currentItem : null,
        files : []
    };
    this.handleSubmit=this.handleSubmit.bind(this)
    this.loadMarque=this.loadMarque.bind(this)
  }

 componentWillReceiveProps({visible, currentItem}) {
     this.setState({ visible : visible ? visible  : this.state.visible }); 
     this.setState({ currentItem : currentItem }); 
     //console.log("testeur de maggie ", currentItem)
     //if(cu)
     this.setState({ files : currentItem && this.state.files.length == 0 ? [{url : GET_PRODUCTS_FILES_CTR + currentItem.sku + ".png",uid: currentItem.id, name: 'image.png',status: 'done' }] : this.state.files }); 
   }
  
  componentDidMount() {
    this.setState({ 
      visible : false,
    }); 
  }
  
  componentDidUpdate(prevProps, prevState) {

     if (prevState.visible !== this.state.visible) {
        if(this.state.visible && this.state.marques.length==0){this.loadMarque()}

        if(this.state.visible){this.props.form.resetFields()}

      }

      

    /*  if(this.state.currentItem) {
        this.props.form.setFields({
          libelle : {
            value: this.state.currentItem.libelle
          },
        });
       //this.props.form.setFieldsValue("libelle", this.state.currentItem.libelle);
      }*/

  }
  
  loadMarque() {

    this.setState({
        isLoading: true
      });

      exeRequest("/marques", "GET", null, function(response){
        this.setState({
            marques: (response ? response : []),
            isLoading: false
        });
      }, this) 
  }

 
  
  /*handleChange = ({ fileList }) => {
    console.log("FILE DDDDDDDDDDDDDDDD ", fileList);
    this.setState({ fileList });
  }

  beforeUpload = (file) =>  {
    this.props.beforeUpload(file);
    return false;
  }*/

  handleChangeFile = (fileList) =>{
   console.log("file console ", fileList)
    this.setState({
      files : fileList
    });
}

  beforeUploadFile = (file) => {
  /*let files = this.state.files;
  files.push(file);
  console.log(file);
  return false;
    const isJpgOrPng = file.type === 'image/jpeg' ;
    //|| file.type === 'image/png';
    if (!isJpgOrPng) {
      notification.error({
        message: 'GeoCRM App',
        description: 'You can only upload JPG/PNG file!'
      });  
      //notification.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {

      notification.error({
        message: 'GeoCRM App',
        description: 'Image must smaller than 2MB!'
     });  
      
    }
    return isJpgOrPng && isLt2M;*/
  }

  onClose = () => {
    //console.log("cool", cool);
    //this.onClose();
    this.setState({
      visible: false,
      btnLoading: false,
      currentItem : null,
      files : []
    });
   

  };

  handleSubmit(event) {

    event.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) { 
        //this.setState({ btnLoading: true });
        //if(this.state.files.length > 0){
        let objRequest = Object.assign({}, values);

        if(this.state.files.length > 0){
          const jPhoto = {photo : this.state.files[0].status !== "done" ? this.state.files[0].thumbUrl.split(",")[1] : null};
          objRequest = Object.assign({}, jPhoto, objRequest);
        }
                
        console.log(objRequest);

        //return false;

        this.setState({
          btnLoading: true
        })
        
        
      exeRequest("/produits", (objRequest.id && objRequest.id !== 0 ? "PUT" : "POST") , JSON.stringify(objRequest), function (response) {

            if (response.statusCode == STATUS_CODE_REST_API[1].code) {
              notification.success({
                message: 'GeoCRM App',
                description: 'Produit enregistrée avec succès'
              });

              this.onClose();
              window.location.reload(true);
              //window.location.href = process.env.PUBLIC_URL + "/param/produit/list"

            } else {

            notification.error({
              message: 'GeoCRM App',
              description: "Une erreur est survenue lors de l'enregistrement du produit",
              btnLoading:false
              
            });

            this.setState({
              btnLoading: false
            })
          }

        }, this)
        
    }
    });
  }

  


  render() {

    const { getFieldDecorator } = this.props.form;
    return (
      <div>
       <Drawer 
          title="Ajout d'un produit"
          width={720}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <Form layout="vertical" hideRequiredMark onSubmit={this.handleSubmit}>

          {getFieldDecorator('id', {
            initialValue : this.state.currentItem ? this.state.currentItem.id : null,           
          })(<Input type="hidden"   />)}


          {getFieldDecorator('status', {
            initialValue : this.state.currentItem ? this.state.currentItem.status : STATUS[0].value,          
          })(<Input type="hidden"   />)}

           <Row gutter={16}>
              <Col span={24} style={{marginBottom:20}}>
                 <FileUpload multiple={false} label={"Image Produit"} beforeUploadFile={this.beforeUploadFile}  handleChangeFile={this.handleChangeFile} fileList={this.state.files}  />
              </Col>
            </Row>

          <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Sku : ">
                  {getFieldDecorator('sku', {
                    rules: [{ required: true, message: 'Veuillez saisir le sku' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.sku : "",
                   
                  })(<Input placeholder="Entrez le sku"  />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Libellé : ">
                  {getFieldDecorator('libelle', {
                    rules: [{ required: true, message: 'Veuillez saisir le libéllé ' }],
                    initialValue :  this.state.currentItem ?  this.state.currentItem.libelle : "",
                    
                  })(<Input placeholder="Entrez le libéllé"  />)}
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
                <Col span={24} >
                <Form.Item label="Marque : ">
                {getFieldDecorator('marqueId', {
                    rules: [{ required: true, message: 'Veuillez selectionner la marque' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.marqueId : ""
                  })(
                    <Select placeholder="Veuillez selectionner La marque">
                         {this.state.marques.map(( m, index) => (
                      <Option key={m.id} value={m.id}>{m.libelle}</Option>
                          ))}
                    </Select>
                  )}
                   </Form.Item>
                </Col>
            </Row>
            
           

            <Row gutter={16}>
              <Col span={8}>
                <Form.Item label="Qte / Prix">
                  {getFieldDecorator('quantite', {
                    rules: [{ required: true, message: "Veuillez saisir la quantité" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.quantite : 3
                  })(<InputNumber min={1} max={1000}  />)}
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label="Prix Unitaire">
                  {getFieldDecorator('prixQuantite', {
                    rules: [{ required: true, message: "Veuillez saisir le prix" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.prixQuantite : 5
                  })(<InputNumber   decimalSeparator={" "} width={150} />)}
                </Form.Item>
              </Col>

            

              <Col span={8}>
                <Form.Item label="Qte / Carton">
                  {getFieldDecorator('cartonQuantite', {
                    rules: [{ required: true, message: "Veuillez saisir la quantité dans le carton" }],
                    initialValue : this.state.currentItem ? this.state.currentItem.cartonQuantite : 3
                  })(<InputNumber min={1} max={1000}  />)}
                </Form.Item>
              </Col>

            </Row>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label="Description">
                  {getFieldDecorator('description', {
                    rules: [{ required: true, message: 'Veuillez saisir la description du produit' }],
                    initialValue : this.state.currentItem ? this.state.currentItem.description : ""
                  })(<Input.TextArea size="10" placeholder="Entrez la description du quartier" />)}
                </Form.Item>
              </Col>
            </Row>
          
          <div
            style={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
            }}
          >
            <Button onClick={this.onClose} style={{ marginRight: 8 }}>
              Annuler
            </Button>
            <Button loading={this.state.btnLoading} htmlType="submit" type="primary">
              Enregistrer
            </Button>
          </div>
          </Form>
        </Drawer>
      </div>
    );
  }

}

const ADrawerForm = Form.create()(DrawerForm);

export default ADrawerForm;

